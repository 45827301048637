/* Style HeaderForm */
import styled from "@emotion/styled";
import { colors } from "../../../../styles/colors";
import { SearchButton } from "../../../../styles/App.styled";

export const MobileSearchRoot = styled.div`
  position: absolute;
  top: 4px;
  right: 0;
  justify-content: flex-end;
  border-bottom: 1px solid ${colors.RED};
  transition: left 0.15s ease-in-out;

  ${(props) =>
    props.show
      ? `
  left: 56px;
  `
      : `
  left: 100%;
  `}
  input:not(button),
  input:not([type="submit"]) {
    outline: none;
    border: none;
    border-radius: 0;
    padding-left: 0;
    padding-right: 50px;
    padding-bottom: 9px;
  }
  label {
    position: absolute;
    left: -999999px;
  }
  ${SearchButton} {
    position: absolute;
    right: 5px;
    bottom: 8px;
    background-color: transparent;
    line-height: 0.7em;
    z-index: 2;
    outline: none;
    padding: 3px 7px;
    .icon {
      width: 19px;
      height: 19px;
      ${(props) =>
        props.show
          ? `stroke: ${colors.RED};`
          : `stroke: ${colors.GREY_DARK};
      `}
    }
  }
`;
