import styled from "@emotion/styled";
import { colors } from "styles/colors";

export const ProgressBar = styled.div`
  display: flex;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: ${colors.RED};
  transition: width 0.6s ease;
  width: ${(props) => props.progress}%;
  @media (max-width: 769px) {
    background-color: ${colors.WHITE};
  }
`;
export const ProgressWrap = styled.div`
  display: flex;
  height: 2px;
  overflow: hidden;
  line-height: 0;
  border-radius: 0 0 3px 3px;
  @media (min-width: 769px) {
    margin: -2px;
  }
`;
