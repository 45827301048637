import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "styles/App.styled";
import Modal from "shared/Modal";
import Login from "components/Login";
import { SearchInput } from "../SearchInput/SearchInput";
import Alert from "components/Alert";
import { isModalShows, isUserLogin } from "../../../../store/loginReducer";
import { Logo } from "../Logo/Logo";
import { HeaderContent, MainDesktopHeader } from "./Header.styled";
import { HeaderControls } from "../HeaderControls/HeaderControls";
import { UserControls } from "../UserControls/UserControls";
import { setCurrentUser } from "../../../../store/userReducer/userActions";
import { parseCookies } from "nookies";

const Header = ({ isSearchShows, noCta }) => {
  const isLogin = useSelector(isUserLogin);
  const isShowModal = useSelector(isModalShows);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (parseCookies().access_token)
      dispatch(setCurrentUser()).then(() => {
        setIsLoading(false);
      });
    else setIsLoading(false);
  }, []);

  if (isLoading) {
    return <div />;
  }

  if (noCta) {
    return (
      <MainDesktopHeader>
        <Container>
          <HeaderContent>
            <Logo />
          </HeaderContent>
        </Container>
      </MainDesktopHeader>
    );
  }

  return (
    <MainDesktopHeader>
      <Alert />
      {isShowModal && (
        <Modal type="login">
          <Login />
        </Modal>
      )}
      <Container>
        <HeaderContent>
          <Logo />
          {isSearchShows && <SearchInput />}
          {isLogin ? <UserControls /> : <HeaderControls />}
        </HeaderContent>
      </Container>
    </MainDesktopHeader>
  );
};

export default Header;
