import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { resetPassword } from "api/api";
import { validations } from "validations/registration";
import { toggleModal } from "store/loginReducer";
import { Icon } from "components/SvgIcons";
import Button from "shared/Button";
import Dialog from "shared/Dialog";
import Input from "shared/Input";
import { colors } from "styles/colors";
import { Container, FormContainer, FormInnerForgot } from "styles/App.styled";
import { Deliver, Title, IconWrap, TitleWrap, ButtonWrap } from "components/Login/styled";

const ResetPassword = ({ setLogin }) => {
  const { register, handleSubmit, errors } = useForm();
  const [answerType, setAnswerType] = useState("");
  const [serverAnswer, setServerAnswer] = useState({});
  const [isShowDialog, setDialog] = useState(false);
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(toggleModal(false));
  };

  const isErrorExist = (err) => {
    return Object.keys(errors).includes(err);
  };

  const isServerErrorExist = (err) => {
    return Object.keys(serverAnswer).includes(err);
  };

  const onSubmit = (data) => {
    resetPassword({ ...data, email: data.email.trim() })
      .then((res) => {
        setServerAnswer(res.data);
        setAnswerType("success");
        setDialog(true);
      })
      .catch((err) => {
        err.response && setServerAnswer(err.response.data);
        setAnswerType("error");
        setDialog(true);
      });
  };

  return (
    <Container md>
      <TitleWrap>
        <Title>Reset Password Form</Title>
        <IconWrap onClick={closeModal}>
          <Icon icon="close" />
        </IconWrap>
      </TitleWrap>
      <Deliver spacing="true" color={colors.GREY_LIGHT} />
      <FormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInnerForgot>
            {isShowDialog && <Dialog state={serverAnswer} type={answerType} />}
            <Input
              reff={register(validations.email)}
              name="email"
              text="Email"
              errorMessage={isServerErrorExist("email") ? "" : validations.email.message}
              error={isErrorExist("email") || isServerErrorExist("email")}
            />
          </FormInnerForgot>
          <ButtonWrap className="w100">
            <Button stylType="primary" color={colors.RED} text="Reset" />
            <Button iconLeft="arrow_left" stylType="secondary" onClick={() => setLogin(true)} text="Back to login" />
          </ButtonWrap>
        </form>
      </FormContainer>
    </Container>
  );
};

export default ResetPassword;
