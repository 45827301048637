import axios from "axios";

export const instance = axios.create({
  withCredentials: true,
  baseURL: `https://api.stripe.com/v1`,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    "Access-Control-Allow-Headers": "*",
    Authorization: `Bearer ${
      process.env.NEXT_PUBLIC_FOODY_ENV === "production"
        ? process.env.NEXT_PUBLIC_STRIPE_SECRET_KEY_PROD
        : process.env.NEXT_PUBLIC_STRIPE_SECRET_KEY
    }`,
  },
});

export const createAccStripe = (data) => {
  const params = new URLSearchParams();
  params.append("country", data.country);
  params.append("type", data.type);
  params.append("account_token", data.account_token);
  params.append("capabilities[card_payments][requested]", true);
  params.append("capabilities[transfers][requested]", true);
  return instance.post(`/accounts `, params, {});
};

export const updateAccountStripe = (id, token) => {
  const params = new URLSearchParams();
  params.append("account_token", token);
  return instance.post(`/accounts/${id}`, params);
};

export const addCreatorCard = (data, id) => {
  const params = new URLSearchParams();
  params.append("external_account", data);

  return instance.post(`accounts/${id}/external_accounts`, params);
};

export const getBuyerCard = (id) => {
  return instance.get(`payment_methods/${id}`);
};

export const getCreatorCard = (id) => {
  return instance.get(`accounts/${id}`);
};

export const connectOnboardStripe = (acc, url, redirect) => {
  const params = new URLSearchParams();
  params.append("account", acc);
  params.append("type", "account_onboarding");
  params.append("collect", "eventually_due");
  params.append("refresh_url", url);
  params.append("return_url", `${redirect ? redirect : url}?onBord=true`);
  return instance.post(`/account_links`, params);
};

export const getBalance = (acc) => {
  return instance.get(`/balance`, { headers: { "Stripe-Account": acc } });
};

export const payOutInstant = (acc, amount) => {
  const params = new URLSearchParams();
  params.append("amount", amount);
  params.append("currency", "usd");
  params.append("method", "instant");
  return instance.post(`/payouts`, params, { headers: { "Stripe-Account": acc } });
};

export const deleteCreatorAccount = (id) => {
  return instance.delete(`/accounts/${id}`);
};

export const getTransactionsStrype = (acc) => {
  return instance.get(`/balance_transactions`, { headers: { "Stripe-Account": acc } });
};

export const createNewDefaultCard = (cardToken) => {
  const params = new URLSearchParams();
  const acc = sessionStorage.getItem("accStripe");
  params.append("external_account", cardToken);
  params.append("default_for_currency", true);
  return instance.post(`accounts/${acc}/external_accounts `, params);
};
