import styled from "@emotion/styled";
import { colors } from "styles/colors";
import { fonts } from "styles/fonts";

export const InfoTitle = styled.div`
  font-family: ${fonts.latoBold};
  margin-bottom: 6px;
`;

export const InfoDescription = styled.div`
  color: ${colors.GREY_SEMIDARK};
  margin-bottom: 20px;
`;

export const ImageWrapper = styled.div`
  position: relative;
  flex: 0 0 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
  width: 170px;
  border-radius: 10px;
  cursor: pointer;
  background: ${colors.GREY_LIGHT};
  border: 1px dashed ${colors.GREY_BORDER};
  overflow: hidden;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const ImageDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0px 0px 0px 20px;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  > div {
    position: unset !important;
    > div {
      postion: absolute !important;
    }
  }
`;

export const InfoText = styled("div")`
  ${(props) => props.display === "hidden" && "display: none;"};
`;
export const IconOverlay = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  background-color: ${colors.GREY_LIGHT};
  z-index: -1;
`;
export const ImgIcon = styled("img")`
  display: block;
  max-width: 58px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
`;

export const LabelButton = styled("div")`
  padding: 9px 0;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 10px;
  width: 115px;
  margin: 0 auto;
  font-family: ${fonts.latoRegular};
  background-color: ${colors.RED};
  color: ${colors.WHITE};
  border: 2px solid ${colors.RED};

  .icon {
    stroke: ${colors.WHITE};
    fill: ${colors.WHITE};
    margin-left: 6px;
  }
`;

export const LabelStyled = styled.label`
  z-index: 2;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 35px;
  font-family: ${fonts.robotoMedium};
  text-align: center;
  transition: opacity 0.3s ease-in-out;
  display: block;
  padding: 7px 18px 8px;
  border-radius: 10px;
  line-height: 1.5;
  width: 100%;
  font-size: 18px;
  color: ${colors.WHITE};
  ${(props) => props.isPhoto && `align-items: center; opacity: 0;`}
  ${(props) =>
    props.isVideo &&
    `
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${LabelButton} {
    position: static !important;
    margin-right: 0% !important;
    margin-left: 0% !important;
    transform: translate(0, 0) !important;
  }
  ${LabelButton} + ${LabelButton} {
    margin-top: 10px
  }
  `}
`;

export const IcoWrap = styled("div")`
  max-width: ${(props) => (props.type === "primary" ? `none` : `216px`)};
  width: 100%;
  margin: 0 auto;
  ${LabelStyled} {
    display: block !important;
    margin-top: 0;
    padding: 0;
  }

  @media (min-width: 769px) {
    padding: 0 10px;
    &:before {
      content: "";
    }
  }
  .icon {
    width: 58px;
    height: 58px;
    stroke: ${colors.GREY};
    display: block;
    margin: 0 auto 27px;
  }
`;
export const IconText = styled("div")`
  line-height: 1.35;
  text-align: center;
  padding: 0 10px;
  color: ${colors.GREY_SEMIDARK};
  ${(props) => props.display === "hidden" && "display: none;"};
`;
export const InputPhotoStyled = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  border-right: 1px solid ${(props) => (props.error ? colors.RED : colors.GREY_SEMILIGHT)};
`;
/* Style Label Styled */
export const Video = styled("video")`
  width: 100%;
  height: 100%;
  display: block;
  object-position: 50% 50%;
  object-fit: ${(props) => (props.isInput ? "cover" : "contain")};
  position: absolute;
`;

export const Source = styled("source")``;

export const ButtonUpload = styled.button`
  padding: 13px 17px 11px;
  font-size: 18px;
  background-color: ${colors.WHITE};
  color: ${colors.RED};
  border: 2px solid ${colors.RED};
  line-height: 1;
  margin: 23px 0;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  font-family: ${fonts.robotoMedium};
  text-align: center;
  transition: opacity 0.3s ease-in-out;
  @media (min-width: 769px) {
    margin: 40px 0 35px;
  }
`;

export const WrapperBtn = styled.div`
  z-index: 2;
  cursor: pointer;
  border-radius: 5px;
  font-family: ${fonts.robotoMedium};
  text-align: center;
  transition: opacity 0.3s ease-in-out;
  @media (max-width: 768px) {
    padding: 8px 9px 7px;
    ${(props) =>
      props.isPhoto &&
      ` 
      font-size: 18px;
      line-height: 1.5;  
      min-width: 97px;
      margin: 0 auto;
    `}
  }

    display: block;
    padding: 7px 18px 8px;
    border-radius: 10px;
    line-height: 1.5;
    ${LabelButton} {
      opacity: 0;
    }
    ${LabelButton} + ${LabelButton} {
      margin-top: 20px
    }
    ${(props) =>
      props.isPhoto
        ? `      
      width: 100%;     
      font-size: 18px;     
      color: ${colors.WHITE};
      opacity: 0;        
      ${LabelButton} {
        opacity: 1;
      } 
    `
        : `
      font-size: 0; 
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    `};
  }
`;

export const InputPhotoWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
  outline: 0;
  transition: opacity 0.5s ease-in-out;

  ${(props) =>
    props.isPhoto &&
    ` 
    flex-direction: column;
  `}

  &:hover {
    ${LabelStyled} , ${WrapperBtn} {
      opacity: 1;
    }
    ${(props) =>
      props.isPhoto
        ? `   
        &:before {
            opacity: 1;
            z-index: 1;
        }`
        : ` `};
  }

  img:not(${ImgIcon}) {
    position: absolute;
    border-radius: 10px;
    object-position: 50% 50%;
    object-fit: cover;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
  }
`;

export const UploadStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.div`
  margin-top: -70px;
  color: ${colors.GREY_SEMIDARK};
  font-size: 16px;
  font-family: ${fonts.latoRegular};
`;
