import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

import { setFoodyWeek } from "./mealPlanReducer/mealPlanActions";

const initialState = {
  loading: true,
  mealPlan: {},
};

export const mealPlanReducer = createSlice({
  name: "mealPlan",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(HYDRATE, (state, action) => {
        state.mealPlan = action.payload.mealPlanReducer.mealPlan;
      })
      .addCase(setFoodyWeek.pending, (state) => {
        state.loading = true;
      })
      .addCase(setFoodyWeek.fulfilled, (state, action) => {
        state.mealPlan = action.payload;
        state.loading = false;
      });
  },
});

export default mealPlanReducer.reducer;

export const getMealPlan = (state) => state.mealPlanReducer.mealPlan;
export const getLoader = (state) => state.mealPlanReducer.laoding;
