import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import {
  setCollection,
  setRecipe,
  madeItRecipe,
  setCollectionLanding,
  setCollectionRecipesLanding,
} from "./collectionReducer/collectionActions";
import { setPurchaseRecipe, setPurchaseCollection } from "./recipesReducer";

const initialState = {
  loading: true,
  collection: {
    recipe: [],
    tags: [],
    collection_creator: {
      id: null,
    },
  },
  recipe: {
    yields: [],
    tags: [],
    recipe_creator: {
      id: null,
    },
    collections: [],
  },
  collectionLanding: {},
  collectionLoadingLoading: false,
  collectionRecipesLanding: [],
};

export const collectionSlice = createSlice({
  name: "collection",
  initialState,
  reducers: {
    saveRecipeCollectionPage: (state, action) => {
      const copyRecipes = [...state.collection.recipe];
      const index = copyRecipes.findIndex((el) => el.id === action.payload);
      copyRecipes[index].saved = !copyRecipes[index].saved;
      state.collection.recipe = copyRecipes;
    },
    savePrivateCollection: (state, action) => {
      const copyCollecions = [...state.recipe.collections];
      const index = copyCollecions.findIndex((el) => el.id === action.payload);
      copyCollecions[index].saved = !copyCollecions[index].saved;
      state.recipe.collections = copyCollecions;
    },
    saveRecipePage: (state) => {
      state.recipe.saved = !state.recipe.saved;
    },
    saveCollectionPage: (state) => {
      state.collection.saved = !state.collection.saved;
    },
    setRecipeRating: (state, action) => {
      const { review_id, rating, review_count, review_rate, review_comment } = action.payload;
      state.recipe.review_id = review_id;
      state.recipe.rating = rating;
      state.recipe.review_count = review_count;
      state.recipe.review_rate = review_rate;
      state.recipe.review_comment = review_comment;
    },
    setCollectionRating: (state, action) => {
      const { review_id, rating, review_count, review_rate, review_comment } = action.payload;
      state.collection.review_id = review_id;
      state.collection.rating = rating;
      state.collection.review_count = review_count;
      state.collection.review_rate = review_rate;
      state.collection.review_comment = review_comment;
    },
    setInGrocery: (state, action) => {
      if (action?.payload?.isCollection) {
        state.collection.in_grocery = true;
      } else {
        state.recipe.in_grocery = true;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(HYDRATE, (state, action) => {
        state.collection = action.payload.collectionReducer.collection;
        state.recipe = action.payload.collectionReducer.recipe;
        state.collectionLanding = action.payload.collectionReducer.collectionLanding;
        state.collectionRecipesLanding = action.payload.collectionReducer.collectionRecipesLanding;
      })
      .addCase(madeItRecipe.fulfilled, (state, action) => {
        const { made_it, made_it_sum } = action.payload;
        state.recipe = { ...state.recipe, made_it, made_it_sum };
      })
      .addCase(setCollection.pending, (state) => {
        state.loading = true;
      })
      .addCase(setCollection.fulfilled, (state, action) => {
        state.collection = action.payload;
        state.loading = false;
      })
      .addCase(setRecipe.pending, (state) => {
        state.loading = true;
      })
      .addCase(setRecipe.fulfilled, (state, action) => {
        if (action.payload && action.payload.element_direction && action.payload.element_ingredient) {
          action.payload.element_direction.sort((a, b) => a.number - b.number);
          action.payload.element_ingredient.sort((a, b) => a.number - b.number);
          action.payload.element_ingredient.forEach((el) => el.ingredient.sort((a, b) => a.number - b.number));
          action.payload.element_direction.forEach((el) => el.direction.sort((a, b) => a.number - b.number));
        }

        state.recipe = action.payload;
        state.loading = false;
      })
      .addCase(setRecipe.rejected, (state, action) => {
        if (action.payload?.status === 402) {
          state.recipe = {
            yields: [],
            tags: [],
            recipe_creator: {
              id: null,
            },
            collections: [],
            is402Error: true,
          };
        }
      })
      .addCase(setPurchaseRecipe, (state) => {
        state.recipe.purchased = true;
      })
      .addCase(setPurchaseCollection, (state) => {
        state.collection.purchased = true;
      })
      .addCase(setCollectionLanding.pending, (state) => {
        state.collectionLoadingLoading = true;
      })
      .addCase(setCollectionLanding.fulfilled, (state, action) => {
        state.collectionLanding = action.payload;
        state.collectionLoadingLoading = false;
      })
      .addCase(setCollectionRecipesLanding.fulfilled, (state, action) => {
        state.collectionRecipesLanding = action.payload.results;
      });
  },
});

export const {
  saveRecipeCollectionPage,
  savePrivateCollection,
  saveRecipePage,
  saveCollectionPage,
  setRecipeRating,
  setCollectionRating,
  setInGrocery,
} = collectionSlice.actions;

export default collectionSlice.reducer;

export const getCollection = (state) => state.collectionReducer.collection;
export const getRecipe = (state) => state.collectionReducer.recipe;

export const getCollectionLanding = (state) => state.collectionReducer.collectionLanding;
export const getCollectionRecipesLanding = (state) => state.collectionReducer.collectionRecipesLanding;
