const env = process.env.NEXT_PUBLIC_FOODY_ENV;
const sizeMultiplier = 1;
const defaultQuality = 95;

const prefix =
  env === "develop"
    ? "https://cf.dev.joinfoody.com"
    : env === "staging" || env === "qa"
    ? "https://cf.stg.joinfoody.com"
    : env === "production"
    ? "https://cf.joinfoody.com"
    : "https://cf.dev.joinfoody.com";

const sizes = {
  small: 150 * sizeMultiplier,
  medium: 400 * sizeMultiplier,
  xMedium: 600 * sizeMultiplier,
  large: 800 * sizeMultiplier,
  xLarge: 1500 * sizeMultiplier,
};

/**
 * @method
 * @param { string } href link to image on bucket
 * @returns { string } link to image on cloudfront
 */

const cloudfront = ({
  href,
  quality = defaultQuality,
  size /* if size more than 1800 some images can't be handled with next.js Image */,
}) => {
  if (!/amazonaws.com/.test(href)) {
    return href;
  }

  const fitIn = size ? (typeof size === "number" ? `${size}x${size}` : `${sizes[size]}x${sizes[size]}`) : `0x0`;
  return `${prefix}${fitIn ? `/fit-in/${fitIn}` : ""}${quality ? `/filters:quality(${quality})` : ""}${
    href.split("amazonaws.com")[1]
  }`;
};

export const cloudfrontVideo = (s3href) => {
  if (!/amazonaws.com/.test(s3href)) {
    return s3href;
  }
  return `${prefix}${s3href.split("amazonaws.com")[1]}`;
};

export default cloudfront;
