/**
 * @method
 * @param {string} filterName name of recipe type
 * @returns {object} dictionary with boolean values for filters
 */
export const getFilterQueriesByFilterName = (filterName) => {
  const isPaid = filterName === "paid";
  const isFree = filterName === "free";
  const isPrivate = filterName === "private";

  return { isPaid, isFree, isPrivate };
};
