import { createSlice } from "@reduxjs/toolkit";
import { initialFiltersValues } from "../shared/FiltersMenu/filtersValues";

import {
  setOwnRecipes,
  setPurchasedRecipes,
  setOwnCollections,
  setSavedCollections,
  setSavedRecipes,
  setPurchasedCollections,
  setDraftCollections,
  setDraftRecipes,
  setUserCollection,
  setCreatorCollections,
  setUserRecipes,
  setCreatorRecipesFirstPage,
  setCreatorRecipes,
  deleteOwnRecipe,
  deleteOwnCollection,
  saveRecipeOrCollection,
  buyRecipe,
  buyCollection,
} from "./recipesReducer/recipesActions";

const initialPaginationValue = {
  recipes: 1,
  collections: 1,
};

const initialSortingValues = {
  recipes: {
    type: "",
    value: "",
  },
  collections: {
    type: "",
    value: "",
  },
};

const initialState = {
  rerenderAddRecipe: 1,
  openForm: false,
  recipesLoading: true,
  buyResult: false,
  buyMessage: "",
  draftLoading: true,
  draftCollectionLoading: true,
  collectionsLoading: true,
  isPreviewDraft: false,
  iconLoading: {
    status: false,
    card_id: null,
  },
  recipes: {
    results: [],
    next: null,
    previous: null,
    count: 0,
  },
  collections: {
    results: [],
    next: null,
    previous: null,
    count: 0,
  },
  drafts: {
    results: [],
  },
  draftsCollections: {
    results: [],
  },
  congratsPage: {},
  recipeManager: 1,
  filters: initialFiltersValues,
  search: "",
  savedId: "",
  purchasedRecipes: [],
  purchasedCollections: [],
  pagination: initialPaginationValue,
  sorting: initialSortingValues,
};

export const recipesSlice = createSlice({
  name: "recipes",
  initialState,
  reducers: {
    rerenderAddRecipe: (state) => {
      state.rerenderAddRecipe = state.rerenderAddRecipe + 1;
    },
    saveCongrats: (state) => {
      state.congratsPage.saved = !state.congratsPage.saved;
    },
    setCongrats: (state, action) => {
      state.congratsPage = action.payload;
    },
    saveRecipeAct: (state, action) => {
      const copyRecipes = [...state.recipes.results];
      const index = state.recipes.results.findIndex((el) => el.id === action.payload);
      copyRecipes[index].saved = !copyRecipes[index].saved;
      state.recipes.results = copyRecipes;
    },
    saveCollectionAct: (state, action) => {
      const copyCollections = [...state.collections.results];
      const index = state.collections.results.findIndex((el) => el.id === action.payload);
      copyCollections[index].saved = !copyCollections[index].saved;
      state.collections.results = copyCollections;
    },
    clearIconStatus: (state) => {
      state.iconLoading = {
        status: false,
        card_id: null,
      };
    },
    setValue: (state, action) => {
      state.search = action.payload;
    },
    openCardForm: (state, action) => {
      state.openForm = action.payload;
    },
    clearBuyResult: (state) => {
      state.buyResult = false;
      state.buyMessage = "";
    },
    setPurchaseRecipe: () => {},
    setPurchaseCollection: () => {},
    setRecipesPage: (state, action) => {
      state.pagination.recipes = action.payload;
    },
    setCollectionsPage: (state, action) => {
      state.pagination.collections = action.payload;
    },
    resetPagination: (state) => {
      state.pagination = initialPaginationValue;
    },
    clearFilter: (state) => {
      state.filters = initialFiltersValues;
    },
    clearSorting: (state) => {
      state.sorting = initialSortingValues;
    },
    clearUserCollectionsAndRecipes: (state) => {
      return {
        ...state,
        collections: [],
        recipes: [],
        collectionsLoading: false,
      };
    },
    setRecipesFilter: (state, action) => {
      state.filters.recipesFilter = action.payload;
    },
    setCollectionsFilter: (state, action) => {
      state.filters.collectionsFilter = action.payload;
    },
    clearSearch: (state) => {
      state.search = "";
    },
    setRecipesSorting: (state, action) => {
      state.sorting.recipes = action.payload;
    },
    setCollectionsSorting: (state, action) => {
      state.sorting.collections = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setOwnRecipes.pending, (state) => {
        state.recipesLoading = true;
        state.recipes.fetching = true;
      })
      .addCase(setOwnRecipes.fulfilled, (state, action) => {
        state.recipes = action.payload;
        state.recipesLoading = false;
      })
      .addCase(setDraftRecipes.pending, (state) => {
        state.draftLoading = true;
      })
      .addCase(setDraftRecipes.fulfilled, (state, action) => {
        state.drafts = action.payload;
        state.draftLoading = false;
      })
      .addCase(setOwnCollections.pending, (state) => {
        state.collectionsLoading = true;
        state.collections.fetching = true;
      })
      .addCase(setOwnCollections.fulfilled, (state, action) => {
        state.collections = action.payload;
        state.collectionsLoading = false;
      })
      .addCase(deleteOwnRecipe.pending, (state, { meta }) => {
        state.iconLoading = {
          status: true,
          card_id: meta.arg,
        };
      })
      .addCase(deleteOwnRecipe.fulfilled, (state, action) => {
        state.recipes.results = state.recipes.results.filter((item) => item.id !== action.payload);
        state.drafts.results = state.drafts.results.filter((item) => item.id !== action.payload);
        state.iconLoading = {
          status: false,
          card_id: null,
        };
      })
      .addCase(deleteOwnCollection.pending, (state, { meta }) => {
        state.iconLoading = {
          status: true,
          card_id: meta.arg,
        };
      })
      .addCase(deleteOwnCollection.fulfilled, (state, action) => {
        state.collections.results = state.collections.results.filter((item) => item.id !== action.payload);
        state.draftsCollections.results = state.draftsCollections.results.filter((item) => item.id !== action.payload);
        state.iconLoading = {
          status: false,
          card_id: null,
        };
      })
      .addCase(saveRecipeOrCollection.pending, (state, { meta }) => {
        state.iconLoading = {
          status: true,
          card_id: meta.arg.id,
        };
      })
      .addCase(saveRecipeOrCollection.fulfilled, (state, action) => {
        state.savedRicipes = action.payload.recipes;
        state.savedCollections = action.payload.collections;
        state.iconLoading = {
          status: false,
          card_id: null,
        };
      })
      .addCase(saveRecipeOrCollection.rejected, (state) => {
        state.iconLoading = {
          status: false,
          card_id: null,
        };
      })
      .addCase(buyRecipe.fulfilled, (state, action) => {
        state.purchasedRecipes = [...state.purchasedRecipes, action.payload];
        state.openForm = false;
        state.buyResult = true;
        state.buyMessage = "Successful";
      })
      .addCase(buyRecipe.rejected, (state) => {
        state.buyResult = true;
        state.buyMessage = "Payment rejected";
      })
      .addCase(buyCollection.fulfilled, (state, action) => {
        state.purchasedCollections = [...state.purchasedRecipes, action.payload];
        state.openForm = false;
        state.buyResult = true;
        state.buyMessage = "Successful";
      })
      .addCase(buyCollection.rejected, (state) => {
        state.buyResult = true;
        state.buyMessage = "Payment rejected";
      })
      .addCase(setDraftCollections.pending, (state) => {
        state.draftCollectionLoading = true;
      })
      .addCase(setDraftCollections.fulfilled, (state, action) => {
        state.draftsCollections = action.payload;
        state.draftCollectionLoading = false;
      })
      .addCase(setUserRecipes.pending, (state) => {
        state.recipes = {
          results: [],
          next: null,
          previous: null,
          count: 0,
        };
        state.recipesLoading = true;
      })
      .addCase(setUserRecipes.fulfilled, (state, action) => {
        state.recipes = action.payload;
        state.recipesLoading = false;
      })
      .addCase(setCreatorRecipesFirstPage.pending, (state) => {
        state.recipesLoading = true;
      })
      .addCase(setCreatorRecipesFirstPage.fulfilled, (state, action) => {
        state.recipes = action.payload;
        state.recipesLoading = false;
      })
      .addCase(setCreatorRecipes.pending, (state) => {
        state.recipesLoading = true;
      })
      .addCase(setCreatorRecipes.fulfilled, (state, action) => {
        const { count, next, results } = action.payload;
        state.recipes = { count, next, results: [...state.recipes.results, ...results] };
        state.recipesLoading = false;
      })
      .addCase(setUserCollection.pending, (state) => {
        state.collections = {
          results: [],
          next: null,
          previous: null,
          count: 0,
        };
        state.collectionsLoading = true;
      })
      .addCase(setUserCollection.fulfilled, (state, action) => {
        state.collections = action.payload;
        state.collectionsLoading = false;
      })
      .addCase(setUserCollection.rejected, (state) => {
        state.collectionsLoading = false;
      })
      .addCase(setCreatorCollections.pending, (state) => {
        state.collectionsLoading = true;
      })
      .addCase(setCreatorCollections.fulfilled, (state, action) => {
        state.collections = action.payload;
        state.collectionsLoading = false;
      })
      .addCase(setCreatorCollections.rejected, (state) => {
        state.collectionsLoading = false;
      })
      .addCase(setSavedRecipes.pending, (state) => {
        state.recipesLoading = true;
      })
      .addCase(setSavedRecipes.fulfilled, (state, action) => {
        return {
          ...state,
          recipes: action.payload,
          recipesLoading: false,
        };
      })
      .addCase(setSavedCollections.pending, (state) => {
        state.collectionsLoading = true;
      })
      .addCase(setSavedCollections.fulfilled, (state, action) => {
        return {
          ...state,
          collections: action.payload,
          collectionsLoading: false,
        };
      })
      .addCase(setPurchasedRecipes.pending, (state) => {
        state.recipesLoading = true;
      })
      .addCase(setPurchasedRecipes.fulfilled, (state, action) => {
        return {
          ...state,
          recipes: action.payload,
          recipesLoading: false,
        };
      })
      .addCase(setPurchasedCollections.pending, (state) => {
        state.collectionsLoading = true;
      })
      .addCase(setPurchasedCollections.fulfilled, (state, action) => {
        return {
          ...state,
          collections: action.payload,
          collectionsLoading: false,
        };
      });
  },
});

export const {
  rerenderAddRecipe,
  saveCongrats,
  setCongrats,
  saveRecipeAct,
  saveCollectionAct,
  clearIconStatus,
  setValue,
  openCardForm,
  clearBuyResult,
  setPurchaseRecipe,
  setPurchaseCollection,
  setRecipesPage,
  setCollectionsPage,
  resetPagination,
  clearFilter,
  clearSorting,
  clearUserCollectionsAndRecipes,
  setRecipesFilter,
  setCollectionsFilter,
  clearSearch,
  setRecipesSorting,
  setCollectionsSorting,
} = recipesSlice.actions;

export default recipesSlice.reducer;
