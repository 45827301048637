import React from "react";
import Link from "next/link";

export const Logo = (props) => {
  return (
    <Link href="/" passHref>
      <img src="/images/logo-latest.svg" alt="logo" style={props.isMobileMenu ? { marginLeft: 26 } : {}} />
    </Link>
  );
};
