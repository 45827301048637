import { SearchButton } from "../../../../styles/App.styled";
import { colors } from "../../../../styles/colors";
import { Icon } from "../../../SvgIcons";
import { Input } from "../../../../styles/Form.styled";
import { getSearchValue, setSearchValue } from "../../../../store/searchReducer";
import React, { useState } from "react";
import Router from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "./SearchInput.styled";

export const SearchInput = () => {
  const { handleSearchInput, searchValue, handleSearchForm } = useSearchForm();

  const { deactivateInput, activateInput, isInputActive } = useSearchInput();

  return (
    <Form isInputActive={isInputActive || searchValue} onSubmit={handleSearchForm}>
      <SearchButton as="button" type="submit" bdButton={colors.WHITE}>
        <Icon icon="search" />
      </SearchButton>
      <Input
        value={searchValue}
        onChange={handleSearchInput}
        onFocus={activateInput}
        onBlur={deactivateInput}
        id="search"
        name="search"
        text="Search"
        type="search"
        placeholder={"Search"}
      />
    </Form>
  );
};

const useSearchForm = () => {
  const dispatch = useDispatch();
  const searchValue = useSelector(getSearchValue);

  const handleSearchInput = (e) => {
    dispatch(setSearchValue(e.target.value));
  };

  const submitSearchForm = (e) => {
    e.preventDefault();
    if (searchValue) {
      Router.push({
        pathname: "/search",
        query: { text: searchValue },
      });
    }
  };

  return {
    handleSearchInput,
    handleSearchForm: submitSearchForm,
    searchValue,
  };
};

const useSearchInput = () => {
  const [isInputActive, setIsInputActive] = useState(false);

  const activateInput = () => setIsInputActive(true);
  const deactivateInput = () => setIsInputActive(false);

  return {
    isInputActive,
    activateInput,
    deactivateInput,
  };
};
