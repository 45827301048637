import styled from "@emotion/styled";
import { fonts } from "styles/fonts";
import { colors } from "styles/colors";

export const Title = styled.div`
  font-family: ${fonts.robotoBold};
  margin-top: 36px;
  font-size: 20px;
  text-align: center;
  margin-bottom: 18px;
`;

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
`;
export const IconWrap = styled.div`
  cursor: pointer;
  position: absolute;
  right: -4px;
  top: 5px;
  .icon {
    stroke: ${colors.GREY};
    width: 22px;
    height: 22px;
  }
`;
export const InputWrap = styled.div`
  position: relative;
  margin-top: 18px;
  &:first-of-type {
    margin-top: 0;
  }
`;
export const BtnBlock = styled.div`
  margin: 20px auto 0 !important;
  text-align: right;

  &.w100 {
    button {
      width: 100%;
      padding: 10px 20px;
      + button {
        margin-top: 8px;
      }
    }
  }
`;

export const ButtonWrap = styled.div`
  margin: 19px auto 19px;
  text-align: right;

  &.w100 {
    button {
      width: 100%;
      padding: 10px 20px;
      + button {
        margin-top: 10px;
      }
    }
  }
`;

export const Deliver = styled.div`
  margin: ${(props) => (props.spacing ? "20px 0 20px" : "30px 0 30px")};
  border-bottom: 2px solid ${(props) => props.color};
`;
export const DeliverText = styled.div`
  margin-top: 28px;
  margin-bottom: 20px;
  text-align: center;
  color: ${(props) => props.color};
`;

export const ForgotPassword = styled.div`
  cursor: pointer;
  color: ${colors.RED};
  margin-top: 22px;
  text-align: center;
`;

export const TextInfo = styled.div`
  color: ${colors.GREY_SEMIDARK};
  margin-top: 19px;
  text-align: center;
`;

export const SwitchStepBtn = styled.button`
  width: 100%;
  color: #ff6500 !important;
  font-family: ${fonts.rubikRegular};
  font-size: 14px;
  text-align: center !important;
  text-decoration: none !important;
`;
