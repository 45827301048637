import styled from "@emotion/styled";
import { Container } from "styles/App.styled";
import { colors, colorsRGB } from "styles/colors";
import { fonts } from "styles/fonts";
import { Content } from "styles/General.styled";

export const ModalTitle = styled.div`
  font-family: ${fonts.robotoMedium};
`;
export const ModalHeader = styled.div`
  padding: 15px 8px 10px;
  display: flex;
  align-items: center;
`;
export const ModalContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1000;

  ${(props) =>
    props.type === "primary"
      ? `
      top: 45px;      
      @media (min-width: 769px) {  
        top: 0;
      } 
      ${Content}{
        max-width: 100%;
        @media (min-width: 1024px) {         
          padding-bottom: 26px;
          padding-left: 39px;
          padding-right: 40px;
          padding-bottom: 33px;
        } 
      }      
    `
      : `
    top: 0;    
    `}
`;
export const ModalContent = styled.div`
  position: relative;
  max-width: ${(props) => (props.width ? props.width : "500")}px;
  width: 100%;
  padding: 40px;
  background-color: ${colors.WHITE};
  border-radius: 20px;

  @media (max-width: 769px) {
    width: 90%;
  }

  ${(props) => props.type === "email" && "padding: 20px;"}
  ${(props) =>
    props.type === "primary"
      ? `
      padding: 28px 16px;
      max-width: 100%;
      width: 100%;
      @media (min-width: 769px) {
        padding-top: 40px;
        margin: 0 auto;
        max-width: 1128px;      
      }
    `
      : ` 
      margin: 0 auto;
      `}
  ${(props) =>
    props.type === "login" &&
    `   
    margin: 20px ;
    max-width: 500px; 
    width: 100%; 
    padding: 20px;
    @media (min-width: 375px) {
      margin: 20px auto;  
      padding: 32px 40px 39px 40px;  
    }
    ${Container}{
      padding: 0
    }
    `}
    ${(props) => props.type === "joinWaitlist" && `padding: 0;`}
`;
export const ModalWrap = styled.div`
  position: relative;
  min-height: 100%;
  display: flex;
  align-items: center;
  ${(props) =>
    props.type === "primary"
      ? `
      @media (min-width: 769px) {
        background-color: rgba(${colorsRGB.GREY_DARK}, 0.7);
        padding: 20px;
      }
      @media (min-width: 1025px) {
        padding: 50px 20px;
      }
      `
      : `  
      background-color: rgba(${colorsRGB.GREY_DARK}, 0.7);
        `}
`;

export const OverLay = styled.div``;

export const ButtonClose = styled.div`
  margin-left: auto;
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;
  .icon {
    stroke: ${colors.GREY_DARK};
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const BtnItem = styled.div`
  padding: 10px;
  @media (min-width: 480px) {
    width: 50%;
  }
`;
export const ButtonsInfo = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 18px -10px 0;
  border-top: 2px solid ${colors.GREY_LIGHT};
  button {
    width: 100%;
  }
`;
export const IconWrap = styled.div`
  cursor: pointer;
  position: relative;
  top: 5px;
  height: 35px;
  .icon {
    width: 35px;
    line-height: 35px;
    height: 35px;
  }
  @media (min-width: 769px) {
    top: 0;
    height: 50px;
  }
`;

export const Wraper = styled.div``;

export const Title = styled.div``;
export const TitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  font-family: ${fonts.abhayaBold};
  border-bottom: 2px solid ${colors.GREY_LIGHT};
  padding-bottom: 19px;
  margin-bottom: 20px;

  ${Wraper} {
    display: flex;
    align-items: center;
    margin-left: auto;
    ${IconWrap} {
      .icon {
        width: 25px;
        height: 25px;
        stroke: ${colors.GREY};
        @media (min-width: 769px) {
          width: 50px;
          height: 50px;
        }
      }
    }
    button {
      padding: 8px 18px 7px;
    }
    button + ${IconWrap} {
      margin-left: 25px;
    }
  }
`;
