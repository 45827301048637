import styled from "@emotion/styled";
import { fonts } from "styles/fonts";
import { colors } from "styles/colors";
import { LabelButton, LabelStyled, Video } from "shared/InputPhoto/styled";

export const RecipeCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column; 
  &:hover {
    &:after {
      background-color: ${colors.RED};
      right: 61px;
    }
  }
  ${Video}{
    border-radius: 10px;
    object-position: 50% 50%;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  @media (min-width: 560px) {
    padding-right: 20px;
    display: grid;
    grid-template-columns: 216px auto;
    grid-template-areas:
      "cardImg cardHeader"
      "cardImg cardContent";
    grid-column-gap: 30px;
    grid-template-rows: auto 1fr; 
    
`;
export const CardImage = styled.div`
  grid-area: cardImg;
  background-color: ${colors.GREY_LIGHT};
  order: 1;
  position: relative;
  width: 100%;
  height: 224px;
  border-radius: 5px;
  margin: 0;
  cursor: pointer;
  ${LabelStyled} {
    margin-top: 0;
    @media (min-width: 560px) {
      position: absolute;
      height: 100% !important;
      width: 100% !important;
      background-color: transparent;
      border: none;
      ${LabelButton} {
        position: absolute;
        top: 50%;
        left: 50%;
        opacity: 0;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        transition: opacity 500ms ease;
      }
      :hover {
        ${LabelButton} {
          opacity: 1;
        }
      }
    }
  }
  @media (min-width: 361px) {
    padding: 0;
    height: 300px;
  }
  @media (min-width: 560px) {
    width: 216px;
    height: 216px;
  }
`;
export const CardHeader = styled.div`
  grid-area: cardHeader;
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;
  @media (min-width: 560px) {
    margin-bottom: 19px;
  }
`;

export const Title = styled.div`
  font-family: ${fonts.abhayaBold};
  font-size: 18px;
  line-height: 1.2;
  align-self: flex-end;
  word-break: break-word;
  @media (min-width: 769px) {
    font-size: 24px;
  }
`;

export const CardButtons = styled.div`
  display: flex;
  align-self: center;
  button + button {
    margin-left: 22px;
  }
`;
export const Button = styled.button`
  position: relative;
  width: 16px;
  height: 16px;
  border: none;
  padding: 0;
  background-color: transparent;

  .icon {
    display: block;
    fill: ${colors.GREY};
    cursor: pointer;
  }
  .icon-delete {
    fill: ${colors.RED};
  }
  .icon-drag {
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }
`;
export const CardText = styled.div`
  margin-top: 12px;
  grid-area: cardContent;
  color: ${colors.GREY_SEMIDARK};
  word-break: break-word;
  line-height: 1.4;
  @media (min-width: 769px) {
    margin-top: 0;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const IngredientsSection = styled.div`
  margin-top: 20px;
`;

export const IngredientsTitle = styled.h4`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const IngredientInputList = styled.div``;

export const IngredientItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  border-top: 1px ${colors.GREY_SEMILIGHT} solid;
`;

export const IngredientInput = styled.input`
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 10px 20px;
  font-size: 16px;
`;
