export const pagesName = {
  library: "library",
  manager: "recipe_manager",
  users: "users",
};

export const getElementsPerPage = () => (typeof window != "undefined" && window.innerWidth >= 1024 ? 15 : 12);

export const element_ingredient = [
  {
    id: 1,
    name: null,
    number: 1,
    isDeleteId: true,
    ingredient: [
      {
        id: 1,
        number: 1,
        isDeleteId: true,
        qty: "",
        piece: { id: "", name: "" },
        unit: { id: "", name: "" },
        ingredient: { id: "", name: "" },
        notes: null,
      },
    ],
  },
];

export const element_direction = [
  {
    id: 1,
    isDeleteId: true,
    number: 1,
    name: null,
    direction: [
      {
        id: 1,
        isDeleteId: true,
        number: 1,
        description: null,
      },
    ],
  },
];
