import { pdfData } from "variables/collectionVariables";

/**
 * @return {boolean} checking is enviroment is production
 */
export function isProd() {
  return process.env.NODE_ENV === "production";
}

/**
 * @method
 * @param {object} state prev hole session state
 * @param {object} payload new state Add Recipe
 * @returns {object} new session state with changed Add Recipe
 */
export const updateAction = (state, payload) => {
  return {
    ...state,
    addRecipe: {
      ...state.data.addRecipe,
      ...payload,
    },
  };
};

/**
 * @method
 * @param {object} state prev hole session state
 * @param {object} payload new state of cache
 * @returns {object} new session state with changed cache
 */
export const updateCacheAction = (state, payload) => {
  return {
    ...state,
    cache: payload,
  };
};

/**
 * @method
 * @param {object} state prev hole session state
 * @param {object} payload new state Add Collection
 * @returns new session state with changed Add Collections
 */
export const updateCollection = (state, payload) => {
  return {
    ...state,
    addCollection: {
      ...state.addCollection,
      ...payload,
    },
  };
};
/**
 * @method
 * @param {*} state prev hole session state
 * @returns new session state with initial state Add Recipe
 */
export const cleansAction = (state) => {
  return {
    ...state,
    addRecipe: {
      title: "",
      description: "",
      active_time: "",
      main_video: "",
      main_image: "",
      passive_time: "",
      total_time: "",
      yields: [{ number: null, type: { id: null, name: "" } }],
      element: {
        element_ingredient: [
          {
            id: 1,
            name: null,
            number: 1,
            isDeleteId: true,
            ingredient: [
              {
                id: 1,
                number: 1,
                isDeleteId: true,
                qty: "",
                piece: { id: "", name: "" },
                unit: { id: "", name: "" },
                ingredient: { id: "", name: "" },
                notes: null,
              },
            ],
          },
        ],
        element_direction: [
          {
            id: 1,
            isDeleteId: true,
            name: null,
            number: 1,
            direction: [
              {
                id: 1,
                isDeleteId: true,
                number: 1,
                description: null,
              },
            ],
          },
        ],
      },
      tags: [],
      equipment: [],
      paid: false,
    },
  };
};
/**
 * @method
 * @param {*} file downloaded file
 * @returns {string} encoded string
 */
export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

/**
 * @method
 * @param {*} state prev hole session state
 * @returns new session state with initial state Add Collection
 */
export const cleanAddCollection = (state) => {
  return {
    ...state,
    addCollection: {
      id: "",
      main_image: "",
      main_video: "",
      title: "",
      description: null,
      recipe: [],
      recipe_paid: [],
      paid: true,
      private: true,
      tags: [],
      pdf: pdfData,
    },
  };
};

/**
 * @method
 * @returns {string} domain address
 */
export const getBaseURL = () => {
  if (typeof window !== "undefined") {
    let url = location.href; // entire url including querystring - also: window.location.href;
    let baseURL = url.substring(0, url.indexOf("/", 14));

    if (baseURL.indexOf("http://localhost") != -1) {
      // Base Url for localhost
      let url = location.href; // window.location.href;
      let pathname = location.pathname; // window.location.pathname;
      let index1 = url.indexOf(pathname);
      let index2 = url.indexOf("/", index1 - 1);
      let baseLocalUrl = url.substr(0, index2);

      return baseLocalUrl + "/";
    } else {
      // Root Url for domain name
      return baseURL + "/";
    }
  }
};

/**
 * @method
 * @param {number} collectionLength number of recipes in collection
 * @param {boolean} isFree is collection free
 * @returns {string} price of the collection
 */
export const collectionPrice = (collectionLength, isFree) => {
  if (!isFree) return "$0.99";

  const pricesRounded = [0, 0, 1, 2, 3, 4, 4, 5, 6, 6, 7, 8, 9, 10, 11, 12, 12, 13, 13, 14, 15, 14];

  const priceRounded =
    collectionLength > pricesRounded.length - 1 ? 0.8 * collectionLength : pricesRounded[collectionLength];

  return `$${priceRounded}.99`;
};

/**
 * @method
 * @param {function} callback any function which works with id
 * @param {*} id some identificator
 * @returns {*} callback call
 */
export const saveRecipe = (callback, id) => {
  return callback(id);
};

/**
 * Function shows errors
 * @param {*} obj object with errors from server
 * @param {*} newArr array for recursion
 * @returns {Array} resulting array with errors
 */
export const showErr = (obj, newArr) => {
  if (!obj) return;
  const arr = newArr;
  Object.values(obj)
    .flat()
    .forEach((el) => {
      if (typeof el === "string") arr.push(el);
      else showErr(el, arr);
    });
  return arr;
};
/**
 *
 * @param {*} obj object of server errors
 * @param {Array} newArr param for recursion storage name of fields
 * @returns {Array} array of fields wich have error
 */
export const highlightFields = (obj, newArr = []) => {
  let arr = newArr;
  const fields = Object.keys(obj);
  arr = [...arr, ...fields];
  Object.values(obj)
    .flat()
    .forEach((el) => {
      if (typeof el === "object") {
        const field = Object.keys(el);
        arr = [...arr, ...field];
        highlightFields(el, arr);
      } else return;
    });
  return arr;
};

/**
 * @method
 * @param {str} str base64 encoding image
 * @returns {*} form data file format for submitting to server
 */
export const encodeToFile = (str) => {
  if (!str || str.substr(0, 4) === "http") return null;
  let arr = str.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const file = new File([u8arr], `file.${mime.split("/")[1]}`, { type: mime });
  return file;
};

/**
 * @method
 * @param {obj} recipe object with recipe data
 * @returns {*} deep copy of recipe with new yields from server and sorted top ingredient, direction containers and internal
 */
export const sortDirections = (recipe) => {
  const clone = JSON.parse(JSON.stringify(recipe));
  clone.element_direction.sort((a, b) => a.number - b.number);
  clone.element_ingredient.sort((a, b) => a.number - b.number);
  clone.element_ingredient.forEach((el) => el.ingredient.sort((a, b) => a.number - b.number));
  clone.element_direction.forEach((el) => el.direction.sort((a, b) => a.number - b.number));
  const { element_direction, element_ingredient } = clone;
  delete clone.element_direction;
  delete clone.element_ingredient;
  return { ...clone, yields: clone.yields[0], element: { element_direction, element_ingredient } };
};

export const getImageName = (image) => {
  const uuid = Math.floor(1000 + Math.random() * 9000);
  if (!image) return `image${uuid}`;
  const fileName = image.split("/").pop().split("?")[0];
  const lastDotIndex = fileName.lastIndexOf(".");
  return fileName.slice(0, lastDotIndex);
};
