import React, { useEffect } from "react";
import { wrapper } from "store/reducers";
import { createStore, StateMachineProvider } from "little-state-machine";
import { useRouter } from "next/router";
import { GlobalStyled } from "styles/Global.style";
import Layout from "components/Layout";
import { getBuild } from "api/next-api";
import { useInterval } from "helpers/hooks/useIntervalCheck";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import * as gtag from "libs/gtag";
import { storage } from "variables/storage";
import { DefaultSeo } from "next-seo";
import dynamic from "next/dynamic";
import { instance } from "api/axiosInstanse";
import nookies from "nookies";
import { setCurrentUser } from "store/userReducer/userActions";
import {
  setCollection,
  setRecipe,
  setLandingRecipe,
  setCollectionLanding,
  setCollectionRecipesLanding,
} from "store/collectionReducer/collectionActions";
import { setFoodyWeek } from "store/mealPlanReducer/mealPlanActions";
import { setBanner, setSliderCategories } from "store/layoutReducer/layoutActions";
import { hotjar } from "react-hotjar";
import TagManager from "react-gtm-module";
import * as snippet from "@segment/snippet";
import Script from "next/script";
import { cloudfrontVideo } from "../helpers/cloudfront";

const ProgressBar = dynamic(() => import("components/ProgressBar"), { ssr: false });

createStore(storage);

export const stripePromise = loadStripe(
  process.env.NEXT_PUBLIC_FOODY_ENV === "production"
    ? process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY_PROD
    : process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY,
  {
    locale: "eu",
  }
);

export const getMainVideo = ({ main_video }) => {
  return cloudfrontVideo(main_video);
};

const segmentSnippet = () => {
  const opts = {
    apiKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
    // note: the page option only covers SSR tracking.
    // Page.js is used to track other events using `window.analytics.page()`
    page: true,
  };

  if (process.env.NODE_ENV === "development") {
    return snippet.max(opts);
  } else {
    return snippet.min(opts);
  }
};

const App = ({ Component, pageProps, err }) => {
  const isStage = process.env.NEXT_PUBLIC_FOODY_ENV === "staging";
  const isDev = process.env.NEXT_PUBLIC_FOODY_ENV === "develop";

  const router = useRouter();
  const canonicalUrl = (`https://joinfoody.com` + (router.asPath === "/" ? "" : router.asPath)).split("?")[0];

  useInterval(
    async () => {
      try {
        const res = await getBuild();
        const buildId = res.data.buildId;
        if (buildId && process.env.BUILD_ID && buildId !== process.env.BUILD_ID) {
          router.reload();
        }
      } catch (err) {
        console.log(err);
      }
    },
    { interval: 60000 }
  );

  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTID });
    hotjar.initialize(2847631, 6);
  }, []);

  return (
    <>
      <Script id="segment-script" dangerouslySetInnerHTML={{ __html: segmentSnippet() }} />
      <DefaultSeo
        canonical={canonicalUrl}
        openGraph={{
          type: "website",
          url: router.asPath,
        }}
        facebook={{
          appId: process.env.NEXT_PUBLIC_REACT_APP_FACEBOOK_ID,
        }}
        additionalMetaTags={[
          {
            name: "viewport",
            content: "width=device-width, height=device-height,  initial-scale=1.0, user-scalable=no;user-scalable=0;",
          },
        ]}
        additionalLinkTags={[
          {
            rel: "shortcut icon",
            href: `/images/favicon/${isDev ? "fav_dev.ico" : isStage ? "fav_s.ico" : "fav_p.ico"}`,
            type: "image/x-icon",
          },
          {
            rel: "apple-touch-icon",
            href: `/images/touch-icons/apple-touch-icon-iphone-60x60.png`,
          },
          {
            rel: "apple-touch-icon",
            href: `/images/touch-icons/apple-touch-icon-ipad-76x76.png`,
            sizes: "60x60",
          },
          {
            rel: "apple-touch-icon",
            href: `/images/touch-icons/apple-touch-icon-iphone-retina-120x120.png`,
            sizes: "114x114",
          },
          {
            rel: "apple-touch-icon",
            href: `/images/touch-icons/apple-touch-icon-ipad-retina-152x152.png`,
            sizes: "144x144",
          },
        ]}
        twitter={{
          handle: "@handle",
          site: "@site",
          cardType: "summary_large_image",
        }}
      />
      <StateMachineProvider>
        <Elements
          fonts={[
            {
              src: "url(/fonts/Roboto-Medium.woff) format(woff2)",
              family: "Roboto-Medium",
            },
          ]}
          stripe={stripePromise}
        >
          {GlobalStyled}
          <ProgressBar />
          <Layout>
            <Component {...pageProps} err={err} />
          </Layout>
        </Elements>
      </StateMachineProvider>
    </>
  );
};

App.getInitialProps = wrapper.getInitialAppProps((store) => async ({ ctx, Component }) => {
  const { pathname, query } = ctx;
  let isServer = typeof window == "undefined";

  if (isServer) {
    const { access_token } = nookies.get(ctx);
    instance.defaults.headers.ctx = ctx; // forward ctx to axios instance, to get cookie on the server
    instance.defaults.headers.store = store; // forward dispatch to axios instance, to make logout after refresh token is invalid
    if (access_token) await store.dispatch(setCurrentUser());
  }

  if (pathname == "/recipe/[id]") {
    await store.dispatch(setRecipe(query.id));
  }

  if (pathname == "/mobile/recipes/[id]") {
    await store.dispatch(setLandingRecipe(query.id));
  }

  if (pathname == "/mobile/collection/[id]") {
    await store.dispatch(setCollectionLanding(query.id));
    await store.dispatch(setCollectionRecipesLanding(query.id));
  }

  if (pathname == "/collection/[id]") {
    await store.dispatch(setCollection(query.id));
  }

  if (pathname == "/mobile/foody-weeks/[id]") {
    await store.dispatch(setCollection(query.id));
    await store.dispatch(setFoodyWeek(query.id));
  }

  if (pathname == "/") {
    const sliders = store.getState().layoutReducer.sliders;
    if (sliders && sliders.length == 0) {
      await Promise.allSettled([store.dispatch(setBanner()), store.dispatch(setSliderCategories())]);
    }
  }

  return {
    pageProps: {
      ...(Component.getInitialProps ? Component.getInitialProps({ ...ctx, store }) : {}),
    },
  };
});

export default wrapper.withRedux(App);
