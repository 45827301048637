import React, { useState } from "react";
import LoginForm from "components/Login/LoginForm";
import ResetPassword from "components/Login/ResetPassword";

const Login = () => {
  const [isLogin, setLogin] = useState(true);

  return (
    <>
      {isLogin ? (
        <LoginForm setLogin={setLogin} />
      ) : (
        <ResetPassword setLogin={setLogin} />
      )}
    </>
  );
};

export default Login;
