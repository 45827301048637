import styled from "@emotion/styled";
import { colors } from "styles/colors";

export const DialogWrap = styled.div`
  ${(props) => props.isErrorMessages && "display: none;"};

  color: ${(props) => (props.type === "error" && colors.RED) || (props.type === "success" && colors.BLUE)};
  background-color: ${(props) =>
    (props.type === "error" && colors.MAIN_RED) || (props.type === "success" && colors.BLUE_LIGHT)};
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
