import { pdfData } from "./collectionVariables";

export const storage = {
  data: {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    birthdate: "",
    password_2: "",
    business_name: "",
    website_link: "",
    location: "",
    work_place: "",
    show_business_name: "",
  },
  addCollection: {
    id: "",
    main_image: "",
    main_video: "",
    title: "",
    description: null,
    recipe: [],
    recipe_paid: [],
    private: true,
    paid: true,
    tags: [],
    pdf: pdfData,
  },
  addRecipe: {
    title: null,
    description: "",
    active_time: "",
    main_video: null,
    main_image: null,
    prepare_ahead: "",
    crop_image: null,
    passive_time: "",
    yields: { number: null, type: { id: null, name: "" } },
    total_time: "",
    element: {
      element_ingredient: [
        {
          id: 1,
          name: null,
          number: 1,
          isDeleteId: true,
          ingredient: [
            {
              id: 1,
              isDeleteId: true,
              qty: "",
              number: 1,
              piece: { id: "", name: "" },
              unit: { id: "", name: "" },
              ingredient: { id: "", name: "" },
              notes: null,
            },
          ],
        },
      ],
      element_direction: [
        {
          id: 1,
          isDeleteId: true,
          name: null,
          number: 1,
          direction: [
            {
              id: 1,
              isDeleteId: true,
              number: 1,
              description: null,
            },
          ],
        },
      ],
    },
    tags: [],
    equipment: [],
    paid: false,
  },
  cache: {
    units: [],
    ingredients: [],
    yields: [],
  },
};
