import { createSlice } from "@reduxjs/toolkit";
import { updateCard, addCard, getCard, setCretorPaymentCard, updatePayoutCard } from "./paymentReducer/paymentActions";

const initialState = {
  stripeAcc: "",
  restritedFields: [],
  error: "",
  loading: false,
  loadingResrict: false,
  isBuying: false,
  cards: [],
  paymentCards: [],
  balance: 0,
  pending: 0,
  cardId: "",
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    clearCards: (state) => {
      state.cards = [];
      state.paymentCards = [];
    },
    clearPayoutCards: (state) => {
      state.cards = [];
    },
    setPaymentError: (state, action) => {
      state.error = action.payload;
    },
    setIsBuying: (state, action) => {
      state.isBuying = action.payload;
    },
    setPaymentLoading: (state, action) => {
      state.loading = action.payload;
    },
    setBalance: (state, action) => {
      state.balance = action.payload.balance / 100;
      state.pending = action.payload.pending / 100;
    },
    setRestrictedFields: (state, action) => {
      state.restritedFields = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCard.fulfilled, (state, action) => {
        if (action.payload.is_buyer) state.paymentCards = [...state.paymentCards, action.payload];
        else state.cards = [...state.cards, action.payload];
        state.error = "";
        state.loading = false;
      })
      .addCase(addCard.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addCard.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCard.pending, (state) => {
        state.loading = true;
      })
      .addCase(setCretorPaymentCard.fulfilled, (state, action) => {
        state.paymentCards = action.payload;
        state.loading = false;
      })
      .addCase(setCretorPaymentCard.rejected, (state) => {
        state.loading = false;
      })
      .addCase(setCretorPaymentCard.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCard.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.is_buyer) {
          state.paymentCards = action.payload.cards;
        } else state.cards = [action.payload];
      })
      .addCase(getCard.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateCard.pending, (state) => {
        state.loadingResrict = true;
      })
      .addCase(updateCard.fulfilled, (state) => {
        state.loadingResrict = false;
        state.cards = [{ ...state.cards[0], isComplete: "Pending" }];
      })
      .addCase(updateCard.rejected, (state) => {
        state.loadingResrict = false;
      })
      .addCase(updatePayoutCard.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePayoutCard.fulfilled, (state, action) => {
        state.loading = false;
        state.cards = [action.payload];
      })
      .addCase(updatePayoutCard.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  clearCards,
  clearPayoutCards,
  setPaymentError,
  setIsBuying,
  setPaymentLoading,
  setBalance,
  setRestrictedFields,
} = paymentSlice.actions;

export default paymentSlice.reducer;

export const paymentBuying = (state) => state.paymentReducer.isBuying;
export const paymentLoading = (state) => state.paymentReducer.loading;
export const paymentError = (state) => state.paymentReducer.error;
export const paymentCards = (state) => state.paymentReducer.paymentCards;
export const paymentPayoutCards = (state) => state.paymentReducer.cards;
export const paymentBalance = (state) => state.paymentReducer.balance;
export const paymentRestrictedFields = (state) => state.paymentReducer.restritedFields;
export const restrictedLoading = (state) => state.paymentReducer.loadingResrict;
