import styled from "@emotion/styled";
import { colors, colorsRGB } from "styles/colors";
import { fonts } from "styles/fonts";

export const OverlayMessage = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(${colorsRGB.WHITE}, 0.5);
  animation: enter-overlay 4s ease-in-out;
  @media (min-width: 769px) {
    top: 70px;
  }
`;
export const IconWrap = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 22px;
  height: 22px;
  cursor: pointer;
  .icon {
    width: inherit;
    height: inherit;
    fill: ${colors.WHITE};
  }
`;
export const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const Heading = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-family: ${fonts.latoBold};
`;
export const Message = styled.div`
  max-width: 470px;
  width: 100%;
  font-size: 18px;
  line-height: 22px;
`;
export const MessageType1 = styled.div`
  text-align: left;
`;
export const Wrapper = styled.div`
  position: fixed;
  top: -130px;
  animation: enter-leave 4s ease-in-out;
  left: 50%;
  transform: translateX(-50%);
  max-width: 560px;
  width: 100%;
  border: 1px solid red;
  border-radius: 10px;
  z-index: 20000;
  padding: 19px 44px 19px 20px;
  display: flex;

  ${Heading} + ${Message} {
    margin-top: 10px;
  }
  &.error {
    border: 1px solid tomato;
    background-color: lightSalmon;
    color: darkred;
  }
  &.success {
    border: 1px solid ${colors.GREEN};
    background-color: ${colors.GREEN};
    color: ${colors.WHITE};
  }
  @keyframes enter-overlay {
    0% {
      display: block;
    }
    50% {
      opacity: 1;
    }
    92.5% {
      opacity: 1;
    }
    100% {
      display: none;
      opacity: 0;
    }
  }
  @keyframes enter-leave {
    0% {
      top: -130px;
    }
    50% {
      top: 130px;
    }
    92.5% {
      top: 130px;
    }
    100% {
      top: -130px;
    }
  }
`;
