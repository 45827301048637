export const sortValues = {
  byTitle: "orderByTitle",
  byTime: "orderByTime",
};

/**
 * @method
 * @param {string} type type for value
 * @param {string} value another name of value
 * @returns {object} title and time names from value if type match sort values
 */
export const getSortingNameByState = ({ type, value }) => {
  const orderByTitle = type === sortValues.byTitle ? value : false;
  const orderByTime = type === sortValues.byTime ? value : false;

  return { orderByTitle, orderByTime };
};
