import styled from "@emotion/styled";
import { colors } from "styles/colors";
import { fonts } from "styles/fonts";

export const TextFieldWrap = styled.div`
  position: relative;
  width: 100%;
  textarea {
    width: 100%;
    padding: 13px;
    resize: vertical;
    overflow: hidden;
    vertical-align: top;
    border: 1px solid ${(props) => (props.error ? colors.RED : colors.GREY_SEMILIGHT)};
    border-radius: 5px;
    outline: none;
    color: ${colors.GREY_DARK};
  }
  textarea:focus {
    border: 1px solid ${(props) => (props.error ? colors.RED : colors.GREY_SEMILIGHT)};
    outline: 0;
  }
`;

export const Label = styled.div`
  font-family: ${fonts.latoRegular};
  margin-bottom: 4px;
  line-height: 1.2;
  color: ${(props) => (props.error ? colors.RED : colors.GREY_DARK)};
  &:empty {
    display: none;
  }
`;

export const WrapMessage = styled.div`
  top: 100%;
  margin-top: 3px;
  font-size: 12px;
  line-height: 14px;
  color: ${colors.RED};
`;
