import { createAsyncThunk } from "@reduxjs/toolkit";
import { getBannerData, getCaruselData, getCategories, getHomePageData } from "api/api";

export const setBanner = createAsyncThunk("layout/banner", async (_, thunkAPI) => {
  try {
    const { data } = await getBannerData();
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const setSliderCategories = createAsyncThunk("layout/categories", async (_, thunkAPI) => {
  return getCategories()
    .then((res) => res.data)
    .catch((error) => thunkAPI.rejectWithValue({ error: error.message }));
});

export const setSliderData = createAsyncThunk("layout/SET_SLIDER_DATA", async (index, thunkAPI) => {
  try {
    const { data } = await getCaruselData(index);
    return { content: data[index] };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const setSlidersData = createAsyncThunk("layout/SET_SLIDERS_DATA", async (thunkAPI) => {
  try {
    const { data } = await getHomePageData();
    return { content: data };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});
