import styled from "@emotion/styled";
import { colors } from "styles/colors";

export const CheckboxWrap = styled.div`
  position: relative;
  margin-right: 13px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 5px;
  width: 24px;
  height: 24px;

  .icon {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    stroke: ${(props) =>
      (props.iconStatus == "complete" && colors.RED) ||
      (props.iconStatus === "process" && colors.WHITE) ||
      (props.iconStatus === "canceled" && colors.RED)};
  }
`;
