import styled from "@emotion/styled";
import { colors } from "styles/colors";
import { fonts } from "styles/fonts";
import { CheckboxWrap } from "shared/Checkbox/styled";

//  Styles Form
export const LabelName = styled.div`
  line-height: 1.2;
  margin: 4px 0 4px;

  @media (min-width: 769px) {
    padding-right: 5px;
    font-size: 14px;
  }
  //margin-top: -1px;
`;
export const InputWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  ${(props) =>
    props.fontsize
      ? `
      margin-top: 15px;
    ${LabelName}{
      font-size: 16px;
      }
    `
      : `
      margin-top: 17px;
    ${LabelName}{
      font-size: 14px;
    }
  `}
  @media (min-width: 769px) {
    ${(props) => (props.gap ? "align-items: start; margin-top: 31px;" : "margin-top: 20px;")}

    ${LabelName} {
      font-size: 14px;
    }
  }
  ${(props) =>
    props.gap &&
    `
    ${CheckboxWrap} {
      margin: 18px 0 10px;
      @media (min-width: 769px) {
        margin: 0 0 21px;
      }
    }
  `}

  ${(props) =>
    props.textarea &&
    `
  textarea{
    padding: 10px 13px !important;
    height: 100%;
    min-height: 100px;
  }
  `}
  ${(props) =>
    props.dropDownStyle &&
    `
    .column{
        width: 33.333%;
      }
      .column + .column{
        padding-left: 10px;
      }
    @media (min-width: 769px) {
      .column{
        width: 85px;
      }
      .column:nth-child(2n){
        width: 96px;
      }
      .column:nth-child(3n){
        width: 166px;
      }     
    }
  `}

  label:empty {
    display: none;
  }
`;
export const InputItem = styled.div`
  margin-bottom: 19px;
  @media (min-width: 769px) {
    margin-bottom: 29px;
  }
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const InputItemName = styled(InputItem)`
  @media (min-width: 769px) {
    margin-bottom: 22px;
  }
`;
export const Input = styled.input`
  &:not(button),
  &:not([type="submit"]) {
    display: block;
    width: 100%;
    padding: 10px 12px;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid ${colors.GREY_SEMILIGHT};
    border-radius: 5px;
    outline: none;
    font-family: ${fonts.latoRegular};
    letter-spacing: 0.3px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  &[type="search"] {
    -webkit-appearance: textfield;
    &::-webkit-search-decoration {
      -webkit-appearance: none;
    }
  }
`;
