import styled from "@emotion/styled";
import { colors } from "styles/colors";
import { CheckboxWrap } from "shared/CheckboxLoading/styled";
import { ProgressBar } from "shared/Progress/styled";
import { ButtonIconWrap } from "shared/ButtonIcon/styled";

export const LoadingName = styled.div`
  @media (min-width: 769px) {
    color: ${colors.GREY_DARK};
  }
  margin-right: 9px;
  word-break: break-word;
`;
export const LoadingFiles = styled.div`
  color: ${colors.RED};
`;
export const LoadingCanceled = styled.div`
  margin-right: 10px;
`;
export const Size = styled.div`
  white-space: nowrap;
  margin-left: auto;
  margin-right: 10px;
`;
export const LoadingColumn = styled.div`
  display: flex;
  align-items: center;
  padding: 11px;
  font-size: 12px;
  line-height: 1.2;
  @media (min-width: 769px) {
    padding: 16px;
    font-size: 16px;
    line-height: 1.2;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.1);
  }
`;
export const LoadingWrap = styled.div`
  color: ${colors.WHITE};
  background-color: ${colors.RED};
  box-sizing: border-box;
  border-radius: 3px;
  letter-spacing: 0.01em;
  @media (min-width: 769px) {
    background-color: ${colors.WHITE};
    color: ${colors.GREY};
  }
  + * {
    margin-top: 8px;
  }
  ${ButtonIconWrap} {
    background-color: ${colors.GREY_DARK};
    .icon {
      stroke: ${colors.WHITE};
    }
    @media (min-width: 769px) {
      background-color: ${colors.GREY_LIGHT};
      .icon {
        stroke: ${colors.GREY_DARK};
      }
    }
  }
  ${CheckboxWrap} {
    flex: 0 0 24px;
    width: 24px;
    border: 2px solid ${colors.RED};
    background-color: ${colors.RED};
    .icon {
      stroke: ${colors.WHITE};
    }
  }

  &.complete {
    background-color: ${colors.RED};
    ${CheckboxWrap} {
      background-color: ${colors.RED_LIGHT};
      border-color: ${colors.RED_LIGHT};
      .icon {
        width: 24px;
        height: 24px;
        stroke: ${colors.RED};
      }
    }
    ${ProgressBar} {
      background-color: ${colors.RED};
    }
    ${ButtonIconWrap} {
      background-color: ${colors.GREY_DARK};
      .icon {
        stroke: ${colors.WHITE};
      }
    }
    @media (min-width: 769px) {
      background-color: ${colors.WHITE};
      ${ButtonIconWrap} {
        background-color: ${colors.GREY_SEMILIGHT};
        .icon {
          stroke: ${colors.GREY_DARK};
        }
      }
    }
  }
  &.canceled {
    ${CheckboxWrap} {
      background-color: ${colors.GREY_LIGHT};
      border: 2px solid ${colors.GREY_LIGHT};
      .icon {
        stroke: ${colors.GREY};
      }
    }
    ${ProgressBar} {
      background-color: ${colors.GREY};
    }
  }
  &.process {
    background-color: ${colors.GREY_DARK};
    color: ${colors.WHITE};
    ${CheckboxWrap} {
      background-color: ${colors.GREY_LIGHT};
      border: 2px solid ${colors.GREY_LIGHT};
      .icon {
        stroke: ${colors.GREY};
      }
    }
    ${ProgressBar} {
      background-color: ${colors.RED};
    }
    @media (min-width: 769px) {
      background-color: ${colors.WHITE};
      color: ${colors.GREY};
      ${ProgressBar} {
        background-color: ${colors.GREY};
      }
      ${CheckboxWrap} {
        background-color: ${colors.RED};
        border: 2px solid ${colors.RED};
        .icon {
          stroke: ${colors.WHITE};
        }
      }
    }
  }
`;
export const LoadingMultiple = styled.div`
  cursor: pointer;
  position: fixed;
  background-color: ${colors.GREY_DARK};
  bottom: 16px;
  left: 16px;
  right: 16px;
  color: ${colors.WHITE};
  border-radius: 3px;
  font-size: 12px;
  ${LoadingFiles} {
    margin-left: auto;
    text-decoration: underline;
    font-size: 14px;
  }
`;
export const LoadingBlock = styled.div`
  padding: 8px 8px 18px;
  max-width: 650px;
  position: fixed;
  width: 100%;
  bottom: 0;
  right: 0;
  z-index: 10;
  @media (min-width: 769px) {
    padding: 0;
    bottom: 46px;
    right: 40px;
  }
`;
