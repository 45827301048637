import React from "react";
import { ButtonSpinner, ButtonWrap } from "shared/Button/styled";
import { Icon } from "components/SvgIcons";

const Button = ({
  text,
  stylType,
  type,
  disabled,
  onClick,
  size,
  color,
  textColor,
  iconRight,
  iconLeft,
  id,
  btnSize,
  btnBlock,
  loading,
  withoutPadding,
  className,
  tooltip,
  border,
  style,
}) => {
  return (
    <>
      <ButtonWrap
        stylType={stylType}
        type={type}
        textColor={textColor}
        color={color}
        disabled={disabled}
        onClick={onClick}
        iconRight={iconRight}
        iconLeft={iconLeft}
        size={size}
        btnSize={btnSize}
        id={id}
        btnBlock={btnBlock}
        withoutPadding={withoutPadding}
        className={className}
        border={border}
        style={style ? style : {}}
      >
        {iconLeft && <Icon icon={iconLeft} />}
        {text}
        {iconRight && <Icon icon={iconRight} />}
        {loading && <ButtonSpinner />}
        {tooltip && <span>{tooltip}</span>}
      </ButtonWrap>
    </>
  );
};

export default Button;
