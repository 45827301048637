export const colors = {
  WHITE: "#fff",
  RED: "#FF6F5C",
  MAIN_RED: "#FFF1F0",
  GREEN: "#00A396",
  ORANGE: "#F09B18",
  MAIN_ORANGE: "#FF6500",
  GREEN_LIGHT: "#EBFAF9",
  RED_LIGHT: "#F1AEA5",
  GREY_DARK: "#222324",
  GREY_SEMIDARK: "#646566",
  GREY: "#A3A4A6",
  GREY_SEMILIGHT: "#DCDEE0",
  GREY_LIGHT: "#F2F3F5",
  GREY_5: "#E0E0E0",
  GREY_100: "#989A9A",
  GREY_BORDER: "#dce0e0",
  BURGUNDY: "#9d585d",
  BLUE: "#3769A4",
  BLUE_LINK: "#4B9EA6",
  BLUE_LIKE: "#346F74",
  BURGUNDY_LIGHT: "#f8d7da",
  BLUE_LIGHT: "#CCE5FF",
  BLUE_100: "#458BF7",
  BLUE_200: "#4AA1EB",
  BLUE_300: "#f5fcfb",
  PINK: "#CD486B",
  PINK_2: "#D42D2F",
  HOME_HEADERS: "#202429",
  EDITED_BACKGROUND: "#EEFAF9",
  STAR_ORANGE: "#F39C65",
};

export const colorsRGB = {
  WHITE: "255, 255, 255",
  RED: "255, 111, 92",
  GREY_DARK: "34, 35, 36",
  HOME_HEADERS: "32, 34, 37",
};
