// Main Header
import styled from "@emotion/styled";
import { colors } from "../../../../styles/colors";
import { ButtonWrap } from "../../../../shared/Button/styled";
import { SearchButton } from "../../../../styles/App.styled";

export const MainHeader = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  z-index: ${(props) => (props.isMenu ? 2000 : 200)};
  transition: z-index 0.5s;
  background-color: ${colors.WHITE};
  overflow: hidden;
`;

export const MobileMainHeader = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  z-index: ${(props) => (props.isMenu ? 2000 : 200)};
  transition: z-index 0.5s;
  background-color: ${colors.WHITE};
  overflow: hidden;
  @media (min-width: 769px) {
    display: none;
  }
`;

export const MobileHeaderDiv = styled.div`
  @media (min-width: 769px) {
    display: none;
  }
`;
//  Header Collapse
export const HeaderCollapse = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 2001;
  opacity: 0.99;
  padding: 12px 16px 20px;
  color: ${colors.WHITE};
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  height: -webkit-fill-available;
  width: 288px;
  transition: left 0.3s ease-in-out;
  background-color: ${colors.GREY_DARK};
  .icon {
    width: 24px;
    height: 24px;
    stroke: ${colors.WHITE};
  }
  ${(props) =>
    props.isMenu
      ? `
      left: 0;      
      `
      : `
      left: -100%;
  `}
`;

/* Style HeaderForm */
export const HeaderForm = styled.div`
  position: absolute;
  top: 4px;
  right: 0;
  bottom: -1px;
  justify-content: flex-end;
  border-bottom: 1px solid ${colors.RED};
  transition: left 0.15s ease-in-out;

  ${(props) =>
    props.show
      ? `
  left: 56px;
  `
      : `
  left: 100%;
  `}
  input:not(button),
  input:not([type="submit"]) {
    outline: none;
    border: none;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    margin-right: 50px;
  }
  label {
    position: absolute;
    left: -999999px;
  }
  ${SearchButton} {
    position: absolute;
    right: 5px;
    bottom: 8px;
    background-color: transparent;
    line-height: 0.7em;
    z-index: 2;
    outline: none;
    padding: 3px 7px;
    .icon {
      width: 19px;
      height: 19px;
      ${(props) =>
        props.show
          ? `
      stroke: ${colors.RED};
    `
          : `
      stroke: ${colors.GREY_DARK};
    `}
    }
  }
`;

export const HeaderNav = styled.nav`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const HeaderButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-flow: column-reverse;
  ${SearchButton}[href] {
    color: ${colors.WHITE};
    background: transparent !important;
  }
  ${ButtonWrap} {
    margin-top: 11px;
    color: ${colors.WHITE};
  }
  ${ButtonWrap} + ${ButtonWrap} {
    margin-top: 20px;
  }
  a {
    text-decoration: none;
    margin-right: 2px;
  }
`;

export const HeaderMenu = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: row-reverse;
  flex-direction: column;
`;

export const HeaderItem = styled.li`
  border-bottom: 1px solid ${colors.GREY_SEMIDARK};
  position: relative;
  &:first-of-type {
    border-top: 1px solid ${colors.GREY_SEMIDARK};
  }
  > a {
    display: block;
    position: relative;
    padding: 10px 0;
    @media (min-width: 769px) {
      padding: 15px 19px 15px 0;
    }
    color: inherit;
    text-decoration: none;
    &:after {
      content: "";
    }
  }
`;

//  Header Burger
export const HeaderBurger = styled.div`
  width: 16px;
  height: 16px;
  margin-top: 5px;
  z-index: 4;
  cursor: pointer;
  position: absolute;
  &:after,
  &:before,
  span {
    height: 1px;
    border-radius: 2px;
    transition: all 0.3s ease 0s;
    ${(props) =>
      props.isMenu
        ? `      
        background-color: ${colors.WHITE};
        top: 50%  !important; 
        left: auto !important;  
    `
        : `   
        background-color: ${colors.GREY_DARK}; 
    `};
  }
  :before,
  :after {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
  }
  :before {
    ${(props) =>
      props.isMenu
        ? `
      top: 0;      
        transform: rotate(45deg);           
    `
        : ` 
        top: 3px; 
        transform: rotate(0);
    `}
  }
  :after {
    ${(props) =>
      props.isMenu
        ? `
        bottom: 0;
        transform: rotate(-45deg);  
    `
        : `
        top: 11px; 
        transform: rotate(0);  
    `}
  }
  span {
    position: absolute;
    width: 100%;
    left: 0;

    ${(props) =>
      props.isMenu
        ? `      
       
        top: 50%;
        transform: scale(0);
    `
        : ` 
        top: 7px; 
    `}
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 12px 16px 12px 16px;
  margin: 0 -16px;
  border-bottom: 1px solid ${colors.GREY_LIGHT};
  @media (min-width: 769px) {
    padding: 7px 0 8px;
    margin: 0;
  }

  &.isLogin {
    ${HeaderForm} {
      display: flex;
    }
  }
`;
