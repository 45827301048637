import styled from "@emotion/styled";
import { colors } from "../../../../styles/colors";
import { Container } from "../../../../styles/General.styled";
import { MainHeader } from "../MobileHeader/MobileHeader.styled";

export const MainDesktopHeader = styled(MainHeader)`
  overflow: initial;
  display: none;
  @media (min-width: 769px) {
    display: block;
  }
  ${Container} {
    padding-left: 10px;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 12px 16px;
  margin: 0 -16px;
  border-bottom: 1px solid ${colors.GREY_LIGHT};
  }
`;
