import { Icon } from "../../../SvgIcons";
import Link from "next/link";
import Button from "shared/Button";
import { colors } from "styles/colors";
import React, { useEffect, useRef, useState } from "react";
import { useStateMachine } from "little-state-machine";
import { cleanAddCollection, cleansAction } from "helpers";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "store/userReducer/userActions";
import { toggleModal } from "store/loginReducer";
import { clearErrors } from "store/alert";
import { rerenderAddRecipe } from "store/recipesReducer";
import {
  CreateWrapper,
  DropDown,
  DropDownToggle,
  HeaderButtons,
  LinkItem,
  Links,
  LinksWrap,
  LinksWrapCreate,
  LinkWrap,
  UserName,
} from "./UserControls.styled";
import { Avatar } from "../MobileUserMenu/MobileUserMenu.styled";
import { useGoogleLogout } from "react-google-login";
import Router from "next/router";
import cloudfront from "helpers/cloudfront";
import { getOwner } from "store/userReducer/userSelectors";

export const UserControls = () => {
  const [isMenu, setMenu] = useState(false);
  const { state, actions } = useStateMachine({
    cleansAction,
    cleanAddCollection,
  });
  const toggleBurger = () => {
    setMenu(!isMenu);
  };
  const [isDropDown, setDropDown] = useState(false);
  const [isDropDownCreate, setDropDownCreate] = useState(false);
  const [isSubMenu, setSubMenu] = useState(false);
  const user = useSelector(getOwner);

  const { signOut } = useGoogleLogout({
    jsSrc: "https://apis.google.com/js/api.js",
    clientId: process.env.NEXT_PUBLIC_REACT_APP_GOOGLE_ID,
    onLogoutSuccess: () => null,
  });

  const submenu = useRef(null);
  const createDrodown = useRef();
  const dispatch = useDispatch();

  const toggleCreateDropDown = async (page) => {
    setDropDownCreate(!isDropDownCreate);
    if (page === "recipe") {
      actions.cleansAction(state);
      dispatch(clearErrors());
      dispatch(rerenderAddRecipe());
      sessionStorage.setItem("isTheFormOfRecipesSubmit", false);
      Router.push("/add-recipe");
    }
    if (page === "collection") {
      actions.cleanAddCollection(state);
      dispatch(rerenderAddRecipe());
      sessionStorage.setItem("isTheFormOfRecipesCollection", false);
      Router.push("/add-collection");
    }
  };

  const toggleDropDown = () => {
    setDropDown(!isDropDown);
  };

  const handleLogOut = async () => {
    if (localStorage.socialLogin === "FB") {
      window.FB.logout();
    } else if (localStorage.socialLogin === "GA") {
      signOut();
    }
    sessionStorage.removeItem("paidInCollections");
    dispatch(logoutUser({}));
  };

  const OnClickOutSide = (e) => {
    if (!e.target.classList.contains("user")) {
      setDropDown(false);
    }
    if (createDrodown.current && !createDrodown.current.contains(e.target)) {
      setDropDownCreate(false);
    }
    if (e.target.id === "overlay") {
      dispatch(toggleModal(false));
    }
    if (isSubMenu && submenu.current && !submenu.current.contains(e.target)) {
      setSubMenu(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", OnClickOutSide);
    return () => {
      window.removeEventListener("click", OnClickOutSide);
    };
  });

  const userURL = user.slug ? `/${user.slug}` : `/users/${user.id}`;

  return (
    <HeaderButtons className="withArrow">
      <DropDown>
        <DropDownToggle id="user" className="user" isProfileDropDown={isDropDown} onClick={toggleDropDown}>
          <>
            {user.main_image ? (
              <Avatar
                src={cloudfront({ href: user.main_image, size: "small" })}
                className="user"
                crossOrigin="anonymous"
              />
            ) : (
              <Icon icon="avatar" className="user" />
            )}
            <UserName className="user">
              {user.first_name && user.last_name && `${user.first_name} ${user.last_name}`}
            </UserName>
          </>
        </DropDownToggle>
        {isDropDown && user.id && (
          <LinksWrap isBuyer={user.is_buyer}>
            <Links>
              <LinkItem onClick={toggleBurger}>
                <Link href={userURL} passHref>
                  <LinkWrap>
                    <Icon icon="user" />
                    My Profile
                  </LinkWrap>
                </Link>
              </LinkItem>

              <>
                <LinkItem onClick={toggleBurger}>
                  <Link href={`/library`} passHref>
                    <LinkWrap>
                      <Icon icon="library" />
                      Library
                    </LinkWrap>
                  </Link>
                </LinkItem>
                <LinkItem onClick={toggleBurger}>
                  <Link href={`/recipe_manager`} passHref>
                    <LinkWrap>
                      <Icon icon="recipe_manager" />
                      Recipe Manager
                    </LinkWrap>
                  </Link>
                </LinkItem>
              </>
              <LinkItem onClick={toggleBurger}>
                <Link href={`/settings`} passHref>
                  <LinkWrap>
                    <Icon icon="settings" />
                    Settings
                  </LinkWrap>
                </Link>
              </LinkItem>
              <LinkItem onClick={toggleBurger}>
                <LinkWrap onClick={handleLogOut}>
                  <Icon icon="logout" />
                  Log Out
                </LinkWrap>
              </LinkItem>
            </Links>
          </LinksWrap>
        )}
      </DropDown>
      <CreateWrapper isDropDown={isDropDownCreate} ref={createDrodown}>
        <Button onClick={toggleCreateDropDown} color={colors.RED} stylType="primary" text="Create" />
        {isDropDownCreate && (
          <LinksWrapCreate>
            <Links>
              <LinkItem onClick={() => toggleCreateDropDown("recipe")}>
                <LinkWrap>Add Recipe</LinkWrap>
              </LinkItem>
              {user.is_creator == true && (
                <LinkItem onClick={() => toggleCreateDropDown("collection")}>
                  <LinkWrap>Add Collection</LinkWrap>
                </LinkItem>
              )}
            </Links>
          </LinksWrapCreate>
        )}
      </CreateWrapper>
    </HeaderButtons>
  );
};
