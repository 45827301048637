import { createSlice } from "@reduxjs/toolkit";

export const librarySlice = createSlice({
  name: "library",
  initialState: { libraryMode: "saved" },
  reducers: {
    setPurchasedLibraryMode: (state) => {
      state.libraryMode = "purchased";
    },
    setSavedLibraryMode: (state) => {
      state.libraryMode = "saved";
    },
  },
});

export const { setPurchasedLibraryMode, setSavedLibraryMode } = librarySlice.actions;

export const getLibraryMode = (state) => state.libraryReducer.libraryMode;
export const isLibraryShowSavedRecipes = (state) => getLibraryMode(state) === "saved";
export const isLibraryShowPurchasedRecipes = (state) => getLibraryMode(state) === "purchased";

export default librarySlice.reducer;
