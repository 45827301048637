import styled from "@emotion/styled";
import { colors } from "../../../../styles/colors";
import { SearchButton } from "../../../../styles/App.styled";
import { defaultTransitionTime } from "../../../../styles/transitionTime";

export const Form = styled.form`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 3px 7px;
  transition: max-width ${defaultTransitionTime} ease-out;

  input:not(button),
  input:not([type="submit"]) {
    outline: none;
    border: none;
    border-bottom: 1px solid transparent;
    border-radius: 0;
    padding: 8px 0;
    margin-right: 0;
    width: 100%;
    ${({ isInputActive }) => isInputActive && `border-color: ${colors.GREY_SEMILIGHT}`};
  }

  ${SearchButton} {
    background-color: transparent;
    line-height: 0.7em;
    z-index: 2;
    outline: none;
    padding: 3px 7px;
    margin-right: 6px;
    border: none;

    .icon {
      width: 19px;
      height: 19px;
      stroke: ${colors.GREY};
    }
  }
`;
