import { createAsyncThunk } from "@reduxjs/toolkit";
import { setIsLogin, toggleModal } from "../loginReducer";
import { clearErrors, createAlert, setServerErrors } from "../alert";
import { clearCards } from "../paymentReducer";
import {
  changePassword as changePasswordApi,
  editUserInfo,
  getCurrentUser,
  getUser,
  logOut,
  setUpNewPassword,
} from "api/api";
import nookies, { destroyCookie } from "nookies";

export const logoutUser = createAsyncThunk("LOGOUT_USER", async ({ jwtLogout, ctx }, { dispatch }) => {
  try {
    if (typeof window !== "undefined") {
      destroyCookie(null, "access_token", { path: "/" });
      destroyCookie(null, "refresh", { path: "/" });
      localStorage.removeItem("isSocialLogin");
      localStorage.removeItem("social_login");
      localStorage.removeItem("creator_stripe");
      sessionStorage.clear();
    } else {
      nookies.destroy(ctx, "access_token", { path: "/" });
      nookies.destroy(ctx, "refresh", { path: "/" });
    }
    dispatch(setIsLogin(false));
    dispatch(setServerErrors());
    dispatch(clearCards());
    if (!jwtLogout) await logOut();
  } catch (e) {
    console.log(e);
  }
});

export const setUser = createAsyncThunk("SET_USER", (id) => getUser(id).then((res) => res.data));

export const changePassword = createAsyncThunk(
  "CHANGE_PASSWORD",
  (
    { oldPassword: old_password, password: new_password1, confirmPassword: new_password2 },
    { rejectWithValue, dispatch }
  ) =>
    changePasswordApi({
      old_password,
      new_password1,
      new_password2,
    })
      .then(() => {
        dispatch(createAlert({ type: "success", message: "Password has been changed" }));
      })
      .catch((err) => {
        const invalid_old_password = err?.response?.data?.old_password?.[0] ? "Invalid old password" : null;
        const invalid_new_password = err?.response?.data?.new_password2?.[0];
        dispatch(createAlert({ type: "error", message: invalid_old_password || invalid_new_password }));
        return rejectWithValue(err);
      })
);

export const setCurrentUser = createAsyncThunk("SET_CURRENT_USER", async (_, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await getCurrentUser();
    await dispatch(setIsLogin(true));
    return data;
  } catch (error) {
    return rejectWithValue({ error: error?.message });
  }
});

export const resetPassword = createAsyncThunk(
  "RESET_PASSWORD",
  ({ password: new_password1, confirmPassword: new_password2, uid, token, routeToPage }, { dispatch }) => {
    setUpNewPassword({
      new_password1,
      new_password2,
      uid,
      token,
    })
      .then(() => {
        dispatch(createAlert({ type: "success", message: "Password has been changed" }));
        dispatch(clearErrors({}));
        routeToPage();
        setTimeout(() => {
          dispatch(toggleModal(true));
        }, 2500);
      })
      .catch((err) => {
        dispatch(setServerErrors(err.response.data));
      });
  }
);

export const patchOwner = createAsyncThunk("PATCH_OWNER", (data, { rejectWithValue }) => {
  return editUserInfo(data.id, data.data)
    .then((res) => res.data)
    .catch((err) => rejectWithValue(err.response.data));
});
