import Link from "next/link";
import Button from "../../../../shared/Button";
import { colors } from "../../../../styles/colors";
import React from "react";
import { useDispatch } from "react-redux";
import Router from "next/router";
import { toggleModal } from "../../../../store/loginReducer";
import { HeaderButtons } from "./HeaderControls.styled";

export const HeaderControls = () => {
  const dispatch = useDispatch();

  const toSignUp = () => {
    Router.push("/registration");
  };

  const showModal = () => {
    dispatch(toggleModal(true));
  };

  return (
    <HeaderButtons>
      <Link href={`/for-creators`} passHref>
        <a>For Creators</a>
      </Link>
      <Button onClick={showModal} stylType="secondary" text="Log In" />
      <Button
        onClick={toSignUp}
        color={colors.RED}
        stylType="primary"
        text="Sign Up"
      />
    </HeaderButtons>
  );
};
