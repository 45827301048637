import styled from "@emotion/styled";
import { ButtonWrap } from "../../../../shared/Button/styled";
import { colors } from "../../../../styles/colors";

export const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  ${ButtonWrap} {
    min-width: 70px;
    padding: 6px 7px 5px;
    margin-left: 16px;
  }

  a {
    color: ${colors.GREY_DARK};
    text-decoration: none;
    white-space: nowrap;
  }
`;
