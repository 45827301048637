import { useState } from "react";

/**
 * @method
 * @returns {object} dropdown state and function for dropdown toggling
 */
export const useDropdown = () => {
  const [isDropDown, setDropDown] = useState(false);

  const toggleDropDown = () => {
    setDropDown(!isDropDown);
  };

  return {
    isDropDown,
    toggleDropDown,
  };
};
