import styled from "@emotion/styled";

import { ButtonWrap } from "shared/Button/styled";
import { colors } from "styles/colors";

export const ItemBtnGroup = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0 8px;
  width: 100%;
  display: flex;

  @media (max-width: 768px) {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  @media (min-width: 769px) {
    width: auto;
  }

  .icon {
    stroke: ${(props) => (props.saved ? colors.RED : colors.GREY_DARK)};
  }
`;

export const BtnsGroupWrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  margin: 0 -8px;
  width: 100%;

  ${ItemBtnGroup} + ${ItemBtnGroup} {
    margin-left: 0;
  }

  @media (min-width: 769px) {
    ${ItemBtnGroup} + ${ItemBtnGroup} {
      margin-left: 10px;
    }
    width: auto;
    flex: auto;
  }

  @media (max-width: 768px) {
    ${ItemBtnGroup} {
      &:not(:last-child) {
        ${({ removeItemRightMargin }) => removeItemRightMargin && `margin-right: 0px;`}
      }
    }
  }

  ${ButtonWrap} {
    padding: 5px 9px 6px;
    font-size: 16px;
    width: 100%;
    @media (min-width: 769px) {
      min-width: 108px;
      padding: 10px;
      width: auto;
    }
    .icon {
      margin: -3px 0 0 6px;
      @media (min-width: 769px) {
        margin-left: 10px;
      }
    }
  }
`;

export const BtnsGroupWrapCentered = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  width: 100%;

  @media (min-width: 769px) {
    margin-left: auto;
    width: auto;
    flex: auto;
  }

  ${ItemBtnGroup} {
    ${ButtonWrap} {
      text-align: center;
      padding: 5px 9px 6px;
      font-size: 16px;
      width: 100%;
      @media (min-width: 769px) {
        min-width: 108px;
        padding: 10px;
        width: auto;
      }
      .icon {
        margin: -3px 0 0 6px;
        @media (min-width: 769px) {
          margin-left: 10px;
        }
      }
    }
  }
`;
