import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ThemeProvider } from "@emotion/react";
import { theme } from "styles/theme";

import { LayoutWrap, Wrapper } from "components/Layout/styled";
import { useRouter } from "next/router";
import { LoadingBlock } from "shared/Loading/styled";
import { cancelLoading, uploadLoading } from "store/uploadReducer";
import MobileHeader from "./components/MobileHeader/MobileHeader";
import Header from "./components/Header/Header";
import { isUserLogin, isModalShows } from "store/loginReducer";
import { modalPayment, modalPaidInCollection, modalVideo, modalJoinWaitlist } from "store/modalReducer";
import dynamic from "next/dynamic";
const Alert = dynamic(() => import("components/Alert"), { ssr: false });
const Footer = dynamic(() => import("components/Footer"), { ssr: false });
const Loading = dynamic(() => import("shared/Loading"), { ssr: false });
const BannerCookies = dynamic(() => import("components/BannerCookies"), { ssr: false });
const JoinWaitlist = dynamic(() => import("components/JoinWaitlist"), { ssr: false });

const Layout = ({ children }) => {
  const isShowModal = useSelector(isModalShows);
  const isShowVideo = useSelector(modalVideo);
  const isPaymentModal = useSelector(modalPayment);
  const isJoinWaitlistModal = useSelector(modalJoinWaitlist);
  const isLogin = useSelector(isUserLogin);
  const isPaidInCollectionModal = useSelector(modalPaidInCollection);
  const loading = useSelector(uploadLoading);

  const router = useRouter();
  const renderLoading =
    router.pathname !== "/for-creators" &&
    router.pathname !== "/password_reset_confirm" &&
    router.pathname !== "/registration";

  const isSearchShows = router.pathname !== "/search";

  useEffect(() => {
    if (isShowModal || isShowVideo || isPaymentModal || isPaidInCollectionModal || isJoinWaitlistModal)
      document.body.style.overflow = `hidden`;
    else document.body.style.overflow = `scroll`;
  }, [isShowModal, isShowVideo, isPaymentModal, isPaidInCollectionModal, isJoinWaitlistModal]);

  if (
    router.pathname == "/mobile/recipes/[id]" ||
    router.pathname == "/mobile/foody-weeks/[id]" ||
    router.pathname == "/mobile/collection/[id]"
  ) {
    return (
      <>
        <Wrapper isShowModal={isShowModal || isShowVideo || isPaymentModal} className="layout">
          <Alert />
          <LayoutWrap>
            <MobileHeader isSearchShows={false} noCta />
            <Header isSearchShows={false} noCta />
            {children}
            {isLogin && renderLoading && (
              <LoadingBlock>
                {loading.map((el) => (
                  <Loading
                    size={el.size}
                    progress={el.progress}
                    namefile={el.name}
                    key={el.id}
                    id={el.id}
                    closeLoading={cancelLoading}
                  />
                ))}
              </LoadingBlock>
            )}
            <Footer />
          </LayoutWrap>
          <BannerCookies />
        </Wrapper>
      </>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Wrapper isShowModal={isShowModal || isShowVideo || isPaymentModal} className="layout">
        <Alert />
        <LayoutWrap>
          <MobileHeader isSearchShows={isSearchShows} />
          <Header isSearchShows={isSearchShows} />
          {children}
          {isLogin && renderLoading && (
            <LoadingBlock>
              {loading.map((el) => (
                <Loading
                  size={el.size}
                  progress={el.progress}
                  namefile={el.name}
                  key={el.id}
                  id={el.id}
                  closeLoading={cancelLoading}
                />
              ))}
            </LoadingBlock>
          )}
          <Footer />
        </LayoutWrap>
        {router.pathname === "/" && <JoinWaitlist />}
        <BannerCookies />
      </Wrapper>
    </ThemeProvider>
  );
};

export default Layout;
