import styled from "@emotion/styled";
import { colors } from "styles/colors";
import { fonts } from "styles/fonts";
export const Input = styled.input`
  font-size: 16px;
  position: absolute;
  left: -2px;
  width: 90%;
  height: 46px;
  border: none !important;
  border-radius: 5px;
  padding: 10px 7px !important;
  z-index: 3;
  background-color: transparent !important;
  &:focus {
    outline: 0;
    box-shadow: none;
    border-color: ${(props) => (props.error ? colors.RED : colors.GREEN)};
  }
`;
export const TimeValue = styled.div`
  opacity: 0;
  margin-right: 3px;
`;
export const Minutes = styled.div``;
export const SwitchWrap = styled.div`
  font-family: ${fonts.robotoMedium};
  display: flex;
  align-items: center;
  padding-left: 7px;
  position: relative;
  height: 46px;
  border: 2px solid ${(props) => (props.error ? colors.RED : colors.GREY_SEMILIGHT)};
  border-radius: 5px;
  input[value=""] + ${Minutes} {
    display: none;
  }
  input + ${Minutes} {
    margin-left: 3px;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const ArrowDown = styled.div`
  position: absolute;
  top: 23px;
  right: 10px;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: ${colors.GREY_DARK} transparent transparent transparent;
`;

export const ArrowTop = styled.div`
  position: absolute;
  top: 11px;
  right: 10px;
  display: block;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-bottom: 5px solid ${colors.GREY_DARK};
`;
