import axios from "axios";
import { logoutUser } from "store/userReducer/userActions";
import { parseCookies, setCookie } from "nookies";
import nookies from "nookies";
import { getBaseUrl } from "../helpers/detectBrowser";

const options = {
  baseURL: getBaseUrl(),
};

export const instance = axios.create({
  ...options,
  headers: {
    "Content-Type": "application/json",
  },
});

export const withNoAuthInst = axios.create({
  ...options,
  headers: {
    Authorization: "",
    "Content-Type": "application/json",
  },
});

export const formDataApi = axios.create({
  ...options,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const formDataAwsApi = axios.create({
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

instance.interceptors.request.use(function (config) {
  const isAuthDelete = config.headers.isAuthDelete;
  const accessAfterRefresh = config.headers.Authorization;
  const tokenClient = parseCookies().access_token;
  const { access_token } = nookies.get(instance.defaults.headers.ctx);
  const mainToken = accessAfterRefresh || access_token || tokenClient;
  config.headers.Authorization = mainToken && !isAuthDelete ? `Bearer ${mainToken}` : "";
  delete instance.defaults.headers.isAuthDelete;
  return config;
});

formDataApi.interceptors.request.use(function (config) {
  const token = parseCookies().access_token;
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    return inteceptorTemplate(error);
  }
);

formDataApi.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    return inteceptorTemplate(error);
  }
);

const inteceptorTemplate = async (error) => {
  const originalRequest = error.config;
  const status = error.response.status;
  const { store, ctx } = instance.defaults.headers;
  if ((status === 401 || status === 400) && originalRequest.url === `/login/refresh/`) {
    instance.defaults.headers.isAuthDelete = true;
    await store.dispatch(logoutUser({ jwtLogout: true, ctx, originalRequest }));
    return Promise.reject(error);
  }
  if (status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    const refreshToken = parseCookies().refresh;
    const { refresh } = nookies.get(ctx);
    const mainRefresh = refresh || refreshToken;
    return instance
      .post("/login/refresh/", null, {
        headers: {
          refresh: mainRefresh || "",
        },
      })
      .then(async (res) => {
        if (typeof window !== "undefined") {
          setCookie(null, "access_token", res.headers.access, { path: "/", maxAge: 24 * 60 * 60 });
          setCookie(null, "refresh", res.headers.refresh, { path: "/", maxAge: 30 * 24 * 60 * 60 });
        } else {
          nookies.set(ctx, "access_token", res.headers.access, {
            path: "/",
            maxAge: 24 * 60 * 60,
          });
          nookies.set(ctx, "refresh", res.headers.refresh, {
            path: "/",
            maxAge: 30 * 24 * 60 * 60,
          });
        }

        originalRequest.headers["Authorization"] = res.headers.access;
        return await instance.request(originalRequest);
      });
  }
  return Promise.reject(error);
};
