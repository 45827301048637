import React from "react";
import { DialogWrap } from "shared/Dialog/styled";
import { showErr } from "helpers/index";
import { Icon } from "components/SvgIcons";
import { ErrorMessage, ErrorWrap, IconClose } from "styles/AddRecipe.styled";

const Dialog = ({ type, state, error, onClose = () => {} }) => {
  const isEmpty = !error && (!state || Object.values(state).length == 0);
  if (isEmpty) {
    return null;
  }
  return (
    <DialogWrap type={type}>
      <ErrorWrap>
        {state ? (
          showErr(state, []).map((err, i) => {
            return (
              <ErrorMessage key={i}>
                <Icon icon="warning" />
                {err}
              </ErrorMessage>
            );
          })
        ) : (
          <ErrorMessage>
            <Icon icon="warning" />
            {error}
          </ErrorMessage>
        )}
      </ErrorWrap>
      <IconClose onClick={onClose}>
        <Icon icon="close_2" />
      </IconClose>
    </DialogWrap>
  );
};

export default Dialog;
