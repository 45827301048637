import Modal from "../../../../shared/Modal";
import Login from "../../../Login";
import { Container } from "../../../../styles/App.styled";
import React, { useState } from "react";
import { Logo } from "../Logo/Logo";
import { useDispatch, useSelector } from "react-redux";
import { isModalShows, isUserLogin, toggleModal } from "../../../../store/loginReducer";
import Link from "next/link";
import Button from "../../../../shared/Button";
import { colors } from "../../../../styles/colors";
import Router from "next/router";
import { MobileUserMenu } from "../MobileUserMenu/MobileUserMenu";
import { MobileSearch } from "../MobileSearch/MobileSearch";
import {
  HeaderBurger,
  HeaderButtons,
  HeaderCollapse,
  HeaderContent,
  HeaderItem,
  HeaderMenu,
  HeaderNav,
  MobileMainHeader,
} from "./MobileHeader.styled";
import { Icon } from "components/SvgIcons";

const MobileHeader = ({ isSearchShows, noCta }) => {
  const [isMenu, setMenu] = useState(false);
  const isLogin = useSelector(isUserLogin);
  const toggleBurger = () => {
    setMenu(!isMenu);
  };
  const isShowModal = useSelector(isModalShows);

  const dispatch = useDispatch();

  const toSignUp = () => {
    toggleBurger();
    Router.push("/registration");
  };

  const showModal = () => {
    toggleBurger();
    dispatch(toggleModal(true));
  };

  if (noCta) {
    return (
      <>
        <MobileMainHeader isMenu={isMenu}>
          <Container>
            <HeaderContent>
              <Logo />
            </HeaderContent>
          </Container>
        </MobileMainHeader>
      </>
    );
  }

  return (
    <>
      <HeaderCollapse isMenu={isMenu}>
        <div onClick={() => setMenu(!isMenu)}>
          <Icon icon="close-menu" />
        </div>
        {isLogin ? (
          <MobileUserMenu toggleBurger={toggleBurger} />
        ) : (
          <>
            <HeaderNav>
              <HeaderMenu>
                <HeaderItem onClick={toggleBurger}>
                  <Link href={`/for-creators`} passHref>
                    <a>For Creators</a>
                  </Link>
                </HeaderItem>
              </HeaderMenu>
            </HeaderNav>
            <HeaderButtons>
              <Button
                onClick={toSignUp}
                color={colors.GREY_DARK}
                textColor={colors.WHITE}
                stylType="outline"
                text="Sign Up"
              />
              <Button color={colors.RED} onClick={showModal} stylType="primary" text="Log In" />
            </HeaderButtons>
          </>
        )}
      </HeaderCollapse>
      <MobileMainHeader isMenu={isMenu}>
        {isShowModal && (
          <Modal type="login">
            <Login />
          </Modal>
        )}
        <Container>
          <HeaderContent>
            <HeaderBurger isMenu={isMenu} onClick={toggleBurger}>
              <span />
            </HeaderBurger>
            <Logo isMobileMenu />

            {isSearchShows && <MobileSearch />}
          </HeaderContent>
        </Container>
      </MobileMainHeader>
    </>
  );
};

export default MobileHeader;
