import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addBankingCardBuyer,
  addBankingCardCreator,
  addPaymentBankingCard,
  getCreatorPaymentCard,
  updateCreatorPayoutCard,
} from "api/api";
import { getBuyerCard, getCreatorCard, getBalance, updateAccountStripe } from "api/stripe-api";
import { createAlert } from "../alert";
import { openCardForm } from "../recipesReducer";
import { addBuyerPaymentConnected, addCreatorPaymentConnected } from "../userReducer";
import { clearPayoutCards, setPaymentLoading, setBalance, setRestrictedFields } from "../paymentReducer";

const getErrorMessage = (err) => {
  const error = err.response.data;
  if (Object.keys(error).includes("Error")) {
    if (error["Error"].includes("ErrorDetail")) {
      let r = /\[ErrorDetail\(string='(?<error_text>.*)',.*/;
      return error["Error"].match(r).groups.error_text;
    }
    return error["Error"];
  }
  return error;
};

export const updateCard = createAsyncThunk("UPDATE_ACC_STRIPE", async (res, thunkAPI) => {
  const stripeAcc = sessionStorage.getItem("accStripe");
  try {
    await updateAccountStripe(stripeAcc, res.token.id);
    thunkAPI.dispatch(createAlert({ type: "success", message: "Verification will take a little bit time" }));
  } catch (err) {
    console.log(err);
  }
});

export const addCard = createAsyncThunk(
  "ADD_BANK_CARD",
  ({ id, number, date, type, name, is_buyer, is_payment, stripe_account_id, callback, default_card }, thunkAPI) => {
    if (is_buyer) {
      return addBankingCardBuyer({ payment_method_id: id, default_card })
        .then((res) => {
          const { id, default_card, payment_method_id } = res.data;
          thunkAPI.dispatch(openCardForm(false));
          thunkAPI.dispatch(addBuyerPaymentConnected());
          thunkAPI.dispatch(createAlert({ type: "success", message: "Your card has been successfully added" }));
          callback && callback();
          return { id: payment_method_id, number, date, type, name, is_buyer, cardId: id, isDefault: default_card };
        })
        .catch((err) => {
          callback && callback();
          thunkAPI.dispatch(createAlert({ type: "error", message: getErrorMessage(err) }));
          thunkAPI.dispatch(setPaymentLoading(false));
          if (err.response.status === 402) {
            return thunkAPI.rejectWithValue(err);
          }
        });
    } else if (!is_buyer && !is_payment) {
      return addBankingCardCreator({
        payout_method_id: id,
        stripe_account_id,
      })
        .then((res) => {
          thunkAPI.dispatch(clearPayoutCards());
          const { payout_method_id, id } = res.data;
          sessionStorage.setItem("accStripe", stripe_account_id);
          thunkAPI.dispatch(openCardForm(false));
          thunkAPI.dispatch(addCreatorPaymentConnected());
          thunkAPI.dispatch(createAlert({ type: "success", message: "Your card has been successfully added" }));
          return getCreatorCard(stripe_account_id).then((responce) => {
            responce = responce.data;
            callback();
            return {
              id: payout_method_id,
              number,
              date,
              type,
              name,
              cardId: id,
              is_buyer: false,
              isComplete: responce.requirements.currently_due.length === 0 ? "" : "Restricted",
            };
          });
        })
        .catch((err) => {
          thunkAPI.dispatch(createAlert({ type: "error", message: err.response.data[0] }));
          callback();
          return thunkAPI.rejectWithValue(err);
        });
    } else if (!is_buyer && is_payment) {
      return addPaymentBankingCard({ payment_method_id: id, default_card })
        .then((res) => {
          const { id, default_card, payment_method_id } = res.data;
          thunkAPI.dispatch(openCardForm(false));
          thunkAPI.dispatch(createAlert({ type: "success", message: "Your card has been successfully added" }));
          return {
            id: payment_method_id,
            number,
            date,
            type,
            name,
            is_buyer: true,
            cardId: id,
            isDefault: default_card,
          };
        })
        .catch((err) => {
          thunkAPI.dispatch(createAlert({ type: "error", message: getErrorMessage(err) }));
          return thunkAPI.rejectWithValue(err);
        });
    }
  }
);

export const getCard = createAsyncThunk("GET_BANK_CARD", ({ request, is_buyer }, thunkAPI) => {
  return request().then((res) => {
    if (is_buyer) {
      return setCards(res, thunkAPI).then((cards) => ({
        cards,
        is_buyer,
      }));
    } else {
      const stripeAcc = res.data.results[0].user.stripe_account_id;
      sessionStorage.setItem("accStripe", stripeAcc);
      return getCreatorCard(stripeAcc).then((responce) => {
        responce = responce.data;
        getBalance(stripeAcc).then((balance) => {
          thunkAPI.dispatch(
            setBalance({ balance: balance.data.available[0].amount, pending: balance.data.pending[0].amount })
          );
        });
        thunkAPI.dispatch(setRestrictedFields(responce.requirements.currently_due));
        return {
          id: responce.external_accounts.data[0].id,
          number: `**** **** **** ${responce.external_accounts.data[0].last4}`,
          date: `${responce.external_accounts.data[0].exp_month}/${responce.external_accounts.data[0].exp_year}`,
          type: responce.external_accounts.data[0].brand,
          name: responce.external_accounts.data[0].name,
          cardId: res.data.results[0].id,
          isComplete: responce.requirements.currently_due.length === 0 ? "" : "Restricted",
          is_buyer,
        };
      });
    }
  });
});

export const setCards = (res, thunkAPI) => {
  const paymentMethods = res.data.results;
  const promices = [];
  const arrDefaults = [];
  paymentMethods.forEach((method) => {
    promices.push(getBuyerCard(method.payment_method_id));
    arrDefaults.push({ isDefault: method.default_card, id: method.id });
  });
  return Promise.allSettled(promices)
    .then((res) => {
      const cards = [];
      res.forEach((prom, index) => {
        const value = prom.value;
        cards.push({
          id: value.data.id,
          number: `**** **** **** ${value.data.card.last4}`,
          date: `${value.data.card.exp_month}/${value.data.card.exp_year}`,
          type: value.data.card.brand,
          name: value.data.billing_details.name,
          isDefault: arrDefaults[index].isDefault,
          cardId: arrDefaults[index].id,
        });
      });
      return cards;
    })
    .catch((err) => thunkAPI.rejectWithValue(err));
};

export const setCretorPaymentCard = createAsyncThunk("SET_PAYMENT_CREATOR", (_, thunkAPI) => {
  return getCreatorPaymentCard().then((res) => {
    return setCards(res, thunkAPI);
  });
});

export const updatePayoutCard = createAsyncThunk("UPDATE_PAYOUT_CARD", (card, thunkAPI) => {
  return updateCreatorPayoutCard(card.cardId, { payout_method_id: card.id })
    .then(() => {
      thunkAPI.dispatch(openCardForm(false));
      thunkAPI.dispatch(createAlert({ type: "success", message: "You successfully changed payout card" }));
      return card;
    })
    .catch((err) => {
      thunkAPI.dispatch(createAlert({ type: "error", message: err.response.data[0] }));
      return thunkAPI.rejectWithValue(err);
    });
});
