import styled from "@emotion/styled";
import { colors } from "styles/colors";

export const Title = styled.div`
  margin: 0;
  padding: 11px 9px 8px 0;
  letter-spacing: 0.15px;
`;

export const LinkWrap = styled.a`
  //width: 80%;
`;

export const Links = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const LinksWrap = styled.div`
  ${(props) =>
    props.isDropDown
      ? `      
        display: block;           
    `
      : `   
        display: none;   
    `}
`;

export const LinkItem = styled.li`
  margin-bottom: 11px;

  ${LinkWrap} {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    padding: 7px 12px 6px 11px;
    text-decoration: none;
    color: inherit;
    border-radius: 5px;
    transition: background-color, color 0.3s ease-in-out;
  }

  &:not(.isActive) {
    a:hover {
      color: ${colors.RED};
      .icon {
        stroke: ${colors.RED};
        fill: ${colors.RED};
      }
    }
  }
  .icon,
  img {
    flex-shrink: 0;
    display: inline-block;
    vertical-align: top;
    margin-right: 14px;
    transition: all 0.3s ease-in-out;
    stroke: ${colors.WHITE};
    fill: ${colors.WHITE};
    margin-left: -11px;
  }

  .red-link {
    text-decoration: none;
    color: ${colors.RED};

    .icon {
      stroke: ${colors.RED};
    }
  }
`;

/* DropDown */

export const DropDownToggle = styled.div`
  color: ${colors.WHITE};
  position: relative;
  cursor: pointer;
  width: auto;
  display: flex;

  &::after {
    ${(props) => (props.isArrow ? `` : `content: "";`)}
    align-self: center;
    position: inherit;
    top: -4px;
    width: 0;
    height: 0;
    border-style: solid;
    ${(props) =>
      props.isDropDown
        ? `
      border-width: 0 5px 5px 5px;
      border-color: transparent transparent ${colors.WHITE} transparent;
    `
        : `
      border-width: 5px 5px 0 5px;
      border-color: ${colors.WHITE} transparent transparent transparent;
    `}
  }
`;
export const DropDown = styled.div``;
/* AddRecipe */
export const AddRecipe = styled.a`
  text-decoration: none;
  cursor: pointer;
  padding: 4px 11px;
  display: inline-block;
  color: ${colors.RED};
`;
/* BtnBlock */
export const BtnBlock = styled.div`
  margin-top: auto;
  > * {
    width: 100%;
  }
  > * + * {
    margin-top: 20px;
  }
`;

export const MenuWraper = styled.div`
  width: auto;
  height: 100%;
`;

export const MenuMain = styled.div`
  height: 100%;
  overflow: auto;
  background-color: ${colors.GREY_DARK};
  color: ${colors.WHITE};
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  border-bottom: 2px solid ${colors.GREY_SEMIDARK};
  padding-bottom: ${(props) => (props.open ? "15px" : "0px")};
  margin-bottom: 15px;
`;
export const HomeWrapper = styled.div`
  border-bottom: 2px solid ${colors.GREY_SEMIDARK};
  padding-bottom: 15px;
  margin-bottom: ${(props) => (props.isBuyer ? "0" : "15px")};
`;

export const ManagerWrapper = styled.div`
  border-bottom: 2px solid ${colors.GREY_SEMIDARK};
  padding-bottom: 10px;
  margin-bottom: 10px;
`;

export const FeedbackWrapper = styled.div`
  border-bottom: 2px solid ${colors.GREY_SEMIDARK};
  padding-bottom: ${(props) => props.paddingBottom}px;
  margin-bottom: ${(props) => props.marginBottom}px;
`;

export const Avatar = styled.img``;
