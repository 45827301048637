import React, { useRef, useEffect, useState } from "react";
import { Label, InputWrap, WrapMessage, Prefix, Asfix } from "shared/Input/styled";

const Input = ({
  text,
  type,
  name,
  id,
  placeholder = "",
  reff,
  errorMessage,
  error,
  onChange,
  value,
  onKeyPress,
  prefix,
  readonly,
  onFocus,
  onBlur,
  disabled = false,
  asfix,
}) => {
  const [width, setWidth] = useState();
  const prefixEl = useRef(null);

  useEffect(() => {
    if (prefixEl.current) {
      setWidth(prefixEl.current.clientWidth);
    }
  }, [prefixEl]);
  return (
    <>
      <InputWrap error={error} prefixWidth={width}>
        <Label error={error}>{text}</Label>
        <input
          className="highLight"
          ref={reff}
          type={type}
          onChange={onChange}
          value={value}
          name={name}
          id={id}
          placeholder={placeholder}
          onKeyPress={onKeyPress}
          readOnly={readonly}
          onFocus={onFocus}
          onBlur={onBlur}
          onWheel={(e) => {
            e.target.type == "number" && e.target.blur();
          }}
          disabled={disabled}
        />
        {prefix && (
          <Prefix error={error} ref={prefixEl}>
            {prefix}
          </Prefix>
        )}
        {asfix && <Asfix>{asfix}</Asfix>}
        {error && <WrapMessage>{errorMessage}</WrapMessage>}
      </InputWrap>
    </>
  );
};

export default Input;
