import styled from "@emotion/styled";
import { colors } from "styles/colors";
import { fonts } from "styles/fonts";

export const Prefix = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  border-right: 1px solid ${(props) => (props.error ? colors.RED : "#dce0e0")};
  border-bottom: 1px solid ${(props) => (props.error ? colors.RED : "#dce0e0")};
  height: 45px;
  padding: 9px 13px 10px 13px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: ${(props) => (props.error ? colors.RED_LIGHT : colors.GREY_LIGHT)};
  color: ${(props) => (props.error ? colors.WHITE : colors.GREY_SEMIDARK)};
`;

export const Asfix = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
`;

export const Slug = styled.div`
  color: ${colors.RED};
  margin-left: 4px;
  cursor: pointer;
  :after {
    content: ",";
    color: ${colors.GREY};
  }
`;
export const SlugsWrapper = styled.div`
  display: flex;
  align-items: center;
  ${Slug}:first-child {
    color: ${colors.GREY};
    cursor: auto;
  }
  ${Slug}:first-child:after {
    content: "";
  }
  ${Slug}:last-child:after {
    content: "";
  }
`;
export const SlugMessage = styled.div`
  margin: 20px 0;
  color: #58585f;
  white-space: nowrap;
  display: flex;
  align-items: center;
  @media (min-width: 769px) {
    margin: 0 0 0 10px;
    ${(props) => props.position && "position: absolute; left: 100%; bottom: 10px;"};
  }
  .icon {
    margin-left: 3px;
    margin-top: 2px;
    stroke: ${colors.RED};
    fill: ${colors.RED};
  }
  .icon.icon-check {
    stroke: ${colors.RED};
  }
`;

export const InputWrap = styled.div`
  position: relative;
  input[type="number"] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input {
    font-family: ${fonts.latoRegular};
    font-size: 16px;
    width: 100%;
    height: 46px;
    border: 1px solid ${(props) => (props.error ? colors.RED : colors.GREY_SEMILIGHT)};
    padding: 0.625em 0.875em;
    border-radius: 5px;
    outline: 0;
    box-shadow: none;
    color: ${colors.GREY_DARK};
  }

  input[type="text"] {
    padding-left: ${(props) => props.prefixWidth + 8}px;
  }

  input:focus {
    outline: 0;
    box-shadow: none;
    border-color: ${(props) => (props.error ? colors.RED : colors.GREY_SEMILIGHT)};
    + ${Prefix} {
      border-right: 1px solid ${(props) => (props.error ? colors.RED : colors.GREY_SEMILIGHT)};
      border-bottom: 1px solid ${(props) => (props.error ? colors.RED : colors.GREY_SEMILIGHT)};
    }
  }
  input:read-only {
    background: ${colors.GREY_LIGHT};
    border-color: ${colors.GREY_SEMILIGHT};
    color: ${colors.GREY};
  }
  input[type="search"] {
    -webkit-appearance: textfield;
    &::-webkit-search-decoration {
      -webkit-appearance: none;
    }
  }
`;

export const Label = styled.label`
  font-family: ${fonts.latoRegular};
  color: ${(props) => (props.error ? "red" : colors.GREY_DARK)};
  font-size: 14px;
  margin-bottom: 2px;
  &:empty {
    display: none;
  }
`;
export const InputGroup = styled.div`
  display: flex;

  flex-direction: column;
  position: relative;
  @media (min-width: 769px) {
    flex-direction: row;
    align-items: center;
  }
  ${InputWrap} {
    flex-grow: 1;
    max-width: 509px;
    width: 100%;
  }
`;
export const WrapMessage = styled.div`
  position: absolute;
  top: 100%;
  margin-top: 3px;
  font-size: 12px;
  line-height: 14px;
  color: ${colors.RED};
  &:empty {
    display: none;
  }

  &.global {
    position: static;
    font-size: 16px;
    margin-bottom: 20px;
  }
`;
export const AsfixWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const InputHint = styled.div`
  font-family: "Lato";
  font-size: ${(props) => props.fontSize}
  line-height: 138.3%;
  align-items: center;
  color: ${(props) => props.color};
`;
export const Hint = styled.div`
  font-family: "Lato-Regular";
  font-size: 14px;
  line-height: 138.3%;
  display: flex;
  align-items: center;
  color: ${colors.GREY_DARK};
`;
