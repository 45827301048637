import styled from "@emotion/styled";
import { SearchButton } from "../../../../styles/App.styled";
import { colors } from "../../../../styles/colors";
import { ButtonWrap } from "../../../../shared/Button/styled";

export const LinkWrap = styled.a``;

export const HeaderButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-flow: column-reverse;
  @media (min-width: 769px) {
    flex-direction: column;
    margin-left: auto;
  }
  ${SearchButton}[href] {
    color: ${colors.WHITE};
    background: transparent !important;
  }
  ${ButtonWrap} {
    @media (max-width: 768px) {
      margin-top: 11px;
      color: ${colors.WHITE};
    }
    @media (min-width: 769px) {
      padding: ${(props) => props.space || "6px 7px 5px"};
    }
  }
  ${ButtonWrap} + ${ButtonWrap} {
    @media (max-width: 768px) {
      margin-top: 20px;
    }
  }
  @media (min-width: 769px) {
    flex-direction: row;
    align-items: center;
    ${SearchButton}[href] {
      color: ${colors.GREY_DARK};
      background: ${colors.WHITE} !important;
    }
    > * + * {
      margin-left: 16px;
    }
  }
  a {
    text-decoration: none;
    margin-right: 2px;
  }
`;

export const LinksWrapCreate = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 16px;
  min-width: 130px;
  background: ${colors.WHITE};
  border: 1px solid ${colors.GREY_SEMILIGHT};
  border-radius: 5px;
  box-shadow: 4px 4px 26px rgba(179, 186, 186, 0.3);
  z-index: 2;
`;

/* Style Links */
export const LinkItem = styled.li`
  position: relative;
  white-space: nowrap;

  ${LinkWrap} {
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 12px 12px 15px;
    line-height: 19px;
    text-decoration: none;
    color: inherit;
    transition: background-color, color 0.3s ease-in-out;

    &:hover {
      color: ${colors.RED};
      .icon {
        stroke: ${colors.RED};
        fill: ${colors.RED};
      }
    }
  }

  .icon {
    margin-right: 14px;
    fill: ${colors.GREY_DARK};
    transition: all 0.3s ease-in-out;
  }

  &.isAtive {
    a {
      background-color: ${colors.GREY_LIGHT};
    }
  }
`;
export const Links = styled.ul`
  padding: 0;
  margin: 0 0 20px;
  list-style-type: none;
  &:last-child {
    margin-bottom: 0;
  }
  ${LinkItem} + ${LinkItem} {
    border-top: 1px solid ${colors.GREY_SEMILIGHT};
  }
`;

/* Style LinksWrap */
export const LinksWrap = styled.div`
  position: absolute;
  top: 100%;
  margin-top: 9px;
  min-width: 232px;
  background: ${colors.WHITE};
  border: 1px solid ${colors.GREY_SEMILIGHT};
  border-radius: 5px;
  box-shadow: 4px 4px 26px rgba(179, 186, 186, 0.3);
  z-index: 2;
  right: 0;
`;

export const UserName = styled.span`
  display: inline-block;
  vertical-align: middle;
  padding: 10px 0;
  line-height: 1;
  min-width: 50px;
`;

/* DropDown */
export const DropDown = styled.div`
  position: relative;
  padding: 0 7px 0 0;
`;
export const DropDownToggle = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 8px 21px 7px 11px;
  white-space: nowrap;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    top: 24px;
    margin-left: 8px;
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    border-style: solid;
    ${(props) =>
      props.isDropDown
        ? `      
        border-width: 0 5px 5px 5px;
        border-color: transparent transparent ${colors.GREY_DARK} transparent;          
    `
        : `   
        border-width: 5px 5px 0 5px;
        border-color: ${colors.GREY_DARK} transparent transparent transparent;
    `}
    ${(props) =>
      props.isProfileDropDown &&
      `border-width: 5px 5px 0 5px;
    border-color: ${colors.GREY_DARK} transparent transparent transparent;`}
  }
  .icon,
  img {
    display: inline-block;
    vertical-align: top;
    height: 36px;
    width: 36px;
    stroke: ${colors.GREY};
    border-radius: 50%;
    background-color: ${colors.GREY_SEMILIGHT};
    transition: all 0.3s ease-in-out;
    max-width: none;
    object-fit: cover;
    object-position: center;
  }
  > * + * {
    margin-left: 10px;
  }
`;

export const CreateWrapper = styled.div`
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 16px;
    right: 12px;
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    border-style: solid;
    ${(props) =>
      props.isDropDown
        ? `
      border-width: 0 5px 5px 5px;
      border-color: transparent transparent ${colors.WHITE} transparent;
    `
        : `
      border-width: 5px 5px 0 5px;
      border-color: ${colors.WHITE} transparent transparent transparent;
    `}
  }
  ${ButtonWrap} {
    font-size: inherit;
    padding: 6px 31px 5px 7px;
  }
`;
