import { encodeToFile } from "helpers/index";
import { getElementsPerPage } from "../variables/recipesVariables";
import { setCookie } from "nookies";
import { instance, withNoAuthInst, formDataApi, formDataAwsApi } from "./axiosInstanse";

export const logOut = () => {
  return instance.post(`/logout/`);
};

export const accountRegistration = (data) => {
  return withNoAuthInst.post("/users/register/", data);
};

export const appLogin = async (data) => {
  return withNoAuthInst.post("/login/", data);
};

export const resetPassword = (data) => {
  return withNoAuthInst.post("/rest_auth/password/reset/", data);
};

export const getTags = (is_custom, tagNames = "") => {
  return instance.get(`/recipes/tags/?page_size=40${tagNames}`);
};

export const getCustomTags = (is_custom, tagNames = "") => {
  return instance.get(`/recipes/tags/custom/?page_size=40${tagNames}`);
};

export function setToken({ access, refresh }) {
  setCookie(null, "access_token", access, { path: "/", maxAge: 24 * 60 * 60 });
  setCookie(null, "refresh", refresh, { path: "/", maxAge: 30 * 24 * 60 * 60 });
}

export const addRecipe = (data) => {
  return instance.post("/recipes/", data);
};

export const resaveRecipeDraft = (data, id) => {
  return instance.patch(`/recipes/${id}/`, data);
};

export const publishCollectionFromDraft = (data, id) => {
  return instance.patch(`/recipes/collections/${id}/`, data);
};

export const resaveCollectionDraft = (data, id) => {
  return instance.patch(`/recipes/collections/${id}/`, data);
};

export const getUserInfo = () => {
  return instance.get("/users/current/");
};

export const postCollection = (data) => {
  return instance.post("/recipes/collections/", data);
};

export const getUnits = () => {
  return instance.get(`/recipes/units/`);
};

export const getIngredients = () => {
  return instance.get(`/ingredients/`);
};

export const searchUnits = (value) => {
  return instance.get(`/recipes/units/?search=${value}`);
};

export const getUnit = (id) => {
  return instance.get(`/recipes/units/${id}/`);
};

export const getCurrentUser = () => {
  return instance.get("/users/current/");
};

export const saveRecipe = (data) => {
  let url = `/recipes/liked/${data.recipes ? "recipes" : "collections"}/`;
  let req = data.recipes ? { recipe: data.recipes } : { collection: data.collections };
  return instance.post(url, req);
};

export const unSave = (data) => {
  let url = `/recipes/liked/${data.recipes ? "recipes" : "collections"}/unlike/`;
  let req = data.recipes ? { recipe: data.recipes } : { collection: data.collections };
  return instance.patch(url, req);
};

export const getUser = (id) => {
  return instance.get(`/users/${id}/`);
};

export const getRecipe = (id) => {
  const cleanedId = cleanId(id);
  return instance.get(`/recipes/${cleanedId}/`);
};

export const getCollectionRecipesLanding = (id) => {
  const cleanedId = cleanId(id);
  return instance.get(`/collections/${cleanedId}/web-recipes/?page_size=30&page=1&sort_field=date&sort_order=desc`);
};

export const getCollectionLanding = (id) => {
  const cleanedId = cleanId(id);
  return instance.get(`/collections/${cleanedId}/`);
};

export const getLandingRecipe = (id) => {
  const cleanedId = cleanId(id);
  return instance.get(`/web/recipes/${cleanedId}/`);
};

const cleanId = (id) => {
  // Remove all non-numeric characters
  return id.replace(/\D+/g, "");
};

export const getCollection = (id) => {
  return instance.get(`recipes/collections/${id}/`);
};

export const getMealPlan = (id) => {
  return instance.get(`meal-plans/${id}/`);
};

export const editUserInfo = (id, data) => {
  return instance.patch(`users/${id}/`, data);
};

export const getSavedRecipes = ({
  page,
  titleOrder,
  savedOrder,
  isPaid,
  isPrivate,
  isFree,
  orderByTitle,
  orderByTime,
  pageSize = getElementsPerPage(),
}) => {
  let url = `recipes/liked/recipes/?page=${page}&page_size=${pageSize}`;

  if (titleOrder) url += `&search=${titleOrder}`;
  if (savedOrder) url += `&saved_at=${savedOrder}`;
  if (isPaid) url += `&paid=${isPaid}&private=false`;
  if (isPrivate) url += `&private=${isPrivate}`;
  if (isFree) url += `&paid=${!isFree}`;
  if (orderByTitle) url += `&ordering=${orderByTitle === "asc" ? "" : "-"}recipe__title`;
  if (orderByTime) url += `&ordering=${orderByTime === "asc" ? "" : "-"}created_at`;

  return instance.get(url);
};

export const getSavedCollections = ({
  page,
  isPrivate,
  isFree,
  orderByTitle,
  orderByTime,
  pageSize = getElementsPerPage(),
}) => {
  let url = `recipes/liked/collections/?page=${page}&page_size=${pageSize}`;

  // Paid collections are not shown in this iteration
  if (isFree) url += `&paid=${!isFree}`;
  if (isPrivate) url += `&paid=${isPrivate}`;
  if (orderByTitle) url += `&ordering=${orderByTitle === "asc" ? "" : "-"}collection__title`;
  if (orderByTime) url += `&ordering=${orderByTime === "asc" ? "" : "-"}created_at`;

  return instance.get(url);
};

export const getOwnRecipes = ({
  page = 1,
  status = "published",
  isPaid,
  isPrivate,
  isFree,
  searchQuery,
  orderByTitle,
  orderByTime,
  pageSize = getElementsPerPage(),
  searchInTitle,
}) => {
  let url = `recipes/own_recipes/?page=${page}&status=${status}&page_size=${pageSize}`;

  if (searchQuery) url += `&search=${searchQuery}`;
  if (isPaid) url += `&paid=${isPaid}&private=false`;
  if (isFree) url += `&paid=${!isFree}`;
  if (isPrivate) url += `&private=${isPrivate}`;
  if (orderByTitle) url += `&ordering=${orderByTitle === "asc" ? "" : "-"}title`;
  if (orderByTime) url += `&ordering=${orderByTime === "asc" ? "" : "-"}created_at`;
  if (searchInTitle) url += `&title=${searchInTitle}`;

  return instance.get(url);
};

export const getOwnCollections = ({
  page = 1,
  status = "published",
  isPrivate,
  isFree,
  searchQuery,
  orderByTitle,
  orderByTime,
  pageSize = getElementsPerPage(),
  searchInTitle,
}) => {
  let url = `recipes/collections/own_collections/?page=${page}&status=${status}&page_size=${pageSize}`;

  // Paid collections are not shown in this iteration
  if (searchQuery) url += `&search=${searchQuery}`;
  if (isFree) url += `&paid=${!isFree}`;
  if (isPrivate) url += `&paid=${isPrivate}`;
  if (orderByTitle) url += `&ordering=${orderByTitle === "asc" ? "" : "-"}title`;
  if (orderByTime) url += `&ordering=${orderByTime === "asc" ? "" : "-"}created_at`;
  if (searchInTitle) url += `&title=${searchInTitle}`;

  return instance.get(url);
};

export const deleteRecipe = (id) => {
  return instance.delete(`recipes/${id}/`);
};

export const deleteCollection = (id) => {
  return instance.delete(`recipes/collections/${id}/`);
};

export const getPurchasedRecipes = ({ page, searchQuery, pageSize = getElementsPerPage() }) => {
  let url = `payments/purchased_recipes/?page=${page}&page_size=${pageSize}`;

  if (searchQuery) url += `&search=${searchQuery}`;

  return instance.get(url);
};

export const getPurchasedCollections = ({ page, searchQuery, pageSize = getElementsPerPage() }) => {
  let url = `payments/purchased_collections/?page=${page}&page_size=${pageSize}`;

  if (searchQuery) url += `&search=${searchQuery}`;

  return instance.get(url);
};

export const getUserRecipes = ({ id, page = 1 }) => {
  return instance.get(`recipes/?recipe_creator=${id}&page=${page}`);
};

export const getCreatorRecipes = ({ id, page = 1, pageSize = 10 }) => {
  return instance.get(`recipes/?recipe_creator=${id}&page=${page}&page_size=${pageSize}&custom_order=true`);
};

export const getUserCollection = ({ id, page = 1 }) => {
  return instance.get(`recipes/collections/?&collection_creator__id=${id}&page=${page}&status=published`);
};

export const getCreatorCollections = ({ id, full = false }) => {
  return instance.get(`recipes/collections/short/?collection_creator__id=${id}${full ? "&full=1" : ""}`);
};

export const searchOnSite = (title, page = 1, pageSize = getElementsPerPage()) => {
  let url = `/recipes/?search=${title}&page=${page}&page_size=${pageSize}`;
  return instance.get(url);
};

export const getSearchCollections = (title, page = 1, pageSize = getElementsPerPage()) => {
  let url = `/recipes/collections/?search=${title}&page=${page}&page_size=${pageSize}`;
  return instance.get(url);
};

export const addBankingCardBuyer = (data) => {
  if (!data.default_card) {
    data.default_card = false;
  }
  return instance.post(`/payments/buyer_payment_account/`, data);
};

export const purshaseRecipe = (data) => {
  return instance.post(`/payments/purchased_recipes/create_payment_intent/ `, data);
};

export const purshaseRecipeConfirm = (data) => {
  return instance.post(`/payments/purchased_recipes/`, data);
};

export const purshaseCollectionConfirm = (data) => {
  return instance.post(`/payments/purchased_collections/`, data);
};

export const purshaseCollection = (data) => {
  return instance.post(`/payments/purchased_collections/create_payment_intent/`, data);
};

export const addBankingCardCreator = (data) => {
  return instance.post(`/payments/creator_payout_account/`, data);
};

export const updateCreatorPayoutCard = (id, data) => {
  return instance.patch(`/payments/creator_payout_account/${id}/`, data);
};

export const addPaymentBankingCard = (data) => {
  if (!data.default_card) {
    data.default_card = false;
  }
  return instance.post(`/payments/creator_payment_account/`, data);
};

export const getCreatorPaymentCard = () => {
  return instance.get(`/payments/creator_payment_account/`);
};

export const choseDefaultCard = (id, data, is_creator) => {
  return instance.patch(`/payments/${is_creator ? "creator" : "buyer"}_payment_account/${id}/`, data);
};

export const getBuyerCard = () => {
  return instance.get(`/payments/buyer_payment_account/`);
};

export const deleteBuyerCard = (id) => {
  return instance.delete(`/payments/buyer_payment_account/${id}/`);
};

export const getCreatorCard = () => {
  return instance.get(`/payments/creator_payout_account/`);
};

export const deleteCreatorCard = (id) => {
  return instance.delete(`/payments/creator_payout_account/${id}/`);
};

export const getYields = () => {
  return instance.get(`/recipes/yields_types/`);
};

export const searchYields = (value) => {
  return instance.get(`/recipes/yields_types/?search=${value}`);
};

export const getEquipment = () => {
  return instance.get(`/recipes/equipment/`);
};

export const changeSlug = (id, data) => {
  return instance.patch(`/users/${id}/slug/`, data);
};

export const payOutBE = (data) => {
  return instance.post(`/payments/creator_payment_account/payout/`, data);
};

export const changePassword = (data) => {
  return instance.post(`/change_password/`, data);
};

export const getPeaces = () => {
  return instance.get(`/recipes/pieces/`);
};

export const setUpNewPassword = (data) => {
  return instance.post(`/password_reset_confirm/${data.uid}-${data.token}/`, data);
};

export const getSignedAwsUrl = (fileName, object_id, object_name, object_type) => {
  let file_name = `${object_name}_${object_id}_${object_type}.${fileName.split(".").pop()}`;
  return instance.post(`/recipes/generate_url/`, { file_name, object_id, object_name, object_type });
};

export const uploadFileToAws = (presignedFields, file, url) => {
  const formData = new FormData();
  for (const field in presignedFields) {
    formData.append(field, presignedFields[field]);
  }
  formData.append("file", file);

  return formDataAwsApi.post(url, formData);
};

export const uploadMainFiles = (id, data) => {
  const formData = new FormData();
  const image = encodeToFile(data.image);
  formData.append("main_image", image ? image : "");
  formData.append("main_video", data.video ? data.video : "");
  return formDataApi.patch(`/recipes/${id}/upload_files/`, formData);
};

export const uploadDirections = (id, data) => {
  const formData = new FormData();
  const image = encodeToFile(data.image);
  formData.append("main_image", image ? image : "");
  formData.append("main_video", data.video ? data.video : "");
  return formDataApi.patch(`/recipes/directions/${id}/`, formData);
};

export const uploadFilesCollection = (id, data) => {
  const formData = new FormData();
  const image = encodeToFile(data.image);
  formData.append("main_image", image ? image : "");
  formData.append("main_video", data.video ? data.video : "");
  return formDataApi.patch(`/recipes/collections/${id}/upload_files/`, formData);
};

export const googleLogin = (data) => {
  instance.defaults.headers["Authorization"] = "";
  return instance.post("/rest_auth/google/", data);
};

export const faceBookLogin = (data) => {
  instance.defaults.headers["Authorization"] = "";
  return instance.post("/rest_auth/facebook/", data);
};

export const appleLogin = (data) => {
  instance.defaults.headers["Authorization"] = "";
  return instance.post("/rest_auth/apple/", data);
};

export const googleConnect = (data) => {
  return instance.post("/rest_auth/google/connect/", data);
};

export const faceBookConnect = (data) => {
  return instance.post("/rest_auth/facebook/connect/", data);
};

export const getEarnings = () => {
  return instance.get("/payments/creator_earnings/");
};

export const getTransactions = (filters, period) => {
  const start = period.start.toISO().split("T")[0];
  const end = period.end.toISO().split("T")[0];
  let url = `/payments/transactions/creator_earnings/?start_date=${start}&end_date=${end}`;
  if (filters) url += `&type=${filters}`;
  return instance.get(url);
};

export const getMothSumTransaction = () => {
  let url = "/payments/transactions/creator_earnings_sum/?month";
  return instance.get(url);
};

export const uploadProfileImage = (id, img) => {
  const formData = new FormData();
  const image = encodeToFile(img);
  formData.append("main_image", image);
  return formDataApi.patch(`/users/${id}/upload_files/`, formData);
};

export const emailConfirm = (data) => {
  return instance.post("/users/email_confirmation/", data);
};

export const getTotalSum = () => {
  return instance.get("/payments/transactions/creator_earnings_sum/");
};

export const getPrivateRecipeContainIn = (id) => {
  return instance.get(`/recipes/collections/?recipe__id=${id}`);
};

export const addTag = (data) => {
  return instance.post(`/recipes/tags/`, data);
};

export const unloggedRegister = (data) => {
  return withNoAuthInst.post(`/users/unlogged_registration/`, data);
};

export const deleteRecipesFile = (id, content) => {
  return instance.delete(`/recipes/${id}/delete_files/`, { data: content });
};

export const suggestUsedIngredients = (recipe_id) => {
  return instance.post(`recipes/directions/suggest/`, { recipe_id: recipe_id });
};

export const deleteDirectionFile = (id, content) => {
  return instance.delete(`/recipes/directions/${id}/delete_files/`, { data: content });
};

export const deleteCollectionsFile = (id, content) => {
  return instance.delete(`/recipes/collections/${id}/delete_files/`, { data: content });
};

export const deleteUserAvatar = (id) => {
  return instance.delete(`/users/${id}/delete_files/`, { data: { main_image: true } });
};

export const uploadPdf = (data) => {
  return instance.post(`/recipes/collections/pdf_file/`, data);
};

export const patchPdf = (id, data) => {
  return instance.patch(`/recipes/collections/pdf_file/${id}/`, data);
};

export const deletePdf = (id) => {
  return instance.delete(`/recipes/collections/pdf_file/${id}/`);
};

export const getBannerData = () => {
  return instance.get(`/banner/`);
};

export const getCategories = () => {
  return instance.get(`/settings/`);
};

export const getCaruselData = (tab) => {
  return instance.get(`/recipes/categories/?tab=${tab}`);
};

export const getHomePageData = () => {
  return instance.get(`/recipes/all_categories/`);
};

export const getReviews = ({ id, type, page }) => {
  return type == "recipe"
    ? instance.get(`/recipes/rate/?recipe=${id}&page=${page}`)
    : instance.get(`/recipes/rate/collection/?collection=${id}&page=${page}`);
};

export const addReview = (type, data) => {
  return type == "recipe" ? instance.post(`/recipes/rate/`, data) : instance.post(`/recipes/rate/collection/`, data);
};

export const updateReview = (type, { id, rate, comment }) => {
  return type == "recipe"
    ? instance.patch(`/recipes/rate/${id}/`, { rate, comment })
    : instance.patch(`/recipes/rate/collection/${id}/`, { rate, comment });
};

export const deleteReview = (type, id) => {
  return type == "recipe"
    ? instance.delete(`/recipes/rate/${id}/`)
    : instance.delete(`/recipes/rate/collection/${id}/`);
};

export const getAllRecipesIds = () => {
  return withNoAuthInst.get("/recipes/all_recipes");
};

export const getAllCollectionsIds = () => {
  return withNoAuthInst.get("recipes/collections/all_collections");
};

export const getAllUsersIds = () => {
  return withNoAuthInst.get("users/all_users/");
};
export const likeReview = ({ type, id }) => instance.post(`/recipes/${type}_review/${id}/like/`, {});

export const reportReview = ({ type, id }) => instance.post(`/recipes/${type}_review/${id}/dislike/`, {});

export const getGroceryList = () => {
  return instance.get(`/recipes/grocery/`);
};

export const getCustomGrocery = () => {
  return instance.get(`/recipes/custom-grocery/`);
};

export const updateCustomGrocery = (items) => {
  return instance.post(`/recipes/custom-grocery/`, { items });
};

export const addGrocery = ({ type, id }) => {
  return instance.post(`/recipes/grocery/`, { [type]: id });
};

export const deleteGrocery = (id) => instance.delete(`/recipes/grocery/${id}/`);

export const clearAllGroceries = () => instance.delete(`/recipes/grocery/clear-all/`);

export const patchGrocery = (id, data) => instance.patch(`/recipes/grocery/${id}/`, data);

export const getRecipePrice = () => instance.get(`/payments/recipe_price/`);

export const sendGroceryList = (email) => instance.post(`/recipes/grocery/send-email/`, { email });

export const sendSharedEmail = (email) => withNoAuthInst.post(`/users/send-email/`, { email });

export function sendToDataLayer(eventToPush) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(eventToPush);
}

export const updateMadeIt = ({ recipe, made_times }) => instance.post(`/recipes/madeit/`, { recipe, made_times });

export const joinFoodyPlusWaitlist = (email) => {
  return instance.post(`/general/join-fody-plus-waitlist/`, { email });
};
