import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: [],
  promices: [],
};

const uploadReducer = createSlice({
  name: "upload",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = [...state.loading, action.payload];
    },
    cancelLoading: (state, action) => {
      const item = state.promices.find((promise) => promise.id === action.payload.id);
      item && item.abort();

      state.loading = state.loading.filter((file) => file.id !== action.payload.id);
      state.promices = state.promices.filter((promise) => promise.id !== action.payload.id);
    },
  },
});

export default uploadReducer.reducer;

export const { cancelLoading, setLoading } = uploadReducer.actions;

export const uploadLoading = (state) => state.uploadReducer.loading;
