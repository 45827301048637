import { SearchButton } from "../../../../styles/App.styled";
import { colors } from "../../../../styles/colors";
import { Icon } from "../../../SvgIcons";
import { Input } from "../../../../styles/Form.styled";
import { getSearchValue, setSearchValue } from "../../../../store/searchReducer";
import React, { useState } from "react";
import { MobileSearchRoot } from "./MobileSearch.styled";
import Router from "next/router";
import { useDispatch, useSelector } from "react-redux";

export const MobileSearch = () => {
  const dispatch = useDispatch();
  const [show, setOpen] = useState(false);
  const searchValue = useSelector(getSearchValue);

  const handleKeyPress = (e) => {
    if (e.charCode === 13 && searchValue) {
      Router.push({
        pathname: "/search",
        query: { text: searchValue },
      });
    }
  };
  const toggleInput = () => {
    if (searchValue) {
      Router.push({
        pathname: "/search",
        query: { text: searchValue },
      });
    }
    setOpen(!show);
  };

  return (
    <MobileSearchRoot show={show}>
      <SearchButton as="button" type="submit" onClick={toggleInput} show={show} bdButton={colors.WHITE}>
        <Icon icon="search" />
        <label htmlFor="search">Search</label>
      </SearchButton>
      <Input
        value={searchValue}
        onChange={(e) => dispatch(setSearchValue(e.target.value))}
        onKeyPress={handleKeyPress}
        id="search"
        name="search"
        text="Search"
        type="search"
        placeholder={"Search"}
      />
    </MobileSearchRoot>
  );
};
