import { createSlice } from "@reduxjs/toolkit";

const AlertSlice = createSlice({
  name: "alert",
  initialState: {
    requestLoading: false,
    alerts: [],
    serverErrors: {},
    isShowDialog: false,
  },
  reducers: {
    setRequestLoad: (state, action) => {
      state.requestLoading = action.payload;
    },
    setServerErrors: (state, action) => {
      state.isShowDialog = true;
      state.serverErrors = { ...state.serverErrors, ...action.payload };
    },
    clearErrors: (state) => {
      state.isShowDialog = false;
      state.serverErrors = {};
    },
    createAlert: (state, action) => {
      state.alerts.push({
        message: action.payload.message,
        message2: action.payload.message2,
        type: action.payload.type,
      });
    },
    deleteAlert: (state) => {
      state.alerts.pop();
    },
  },
});

export default AlertSlice.reducer;

export const { createAlert, deleteAlert, setServerErrors, clearErrors, setRequestLoad } = AlertSlice.actions;

// alert selectors
export const getAlerts = (state) => state.alertReducer.alerts;
export const getServerErrors = (state) => state.alertReducer.serverErrors;
export const showAlertDialog = (state) => state.alertReducer.isShowDialog;
export const showRequestLoading = (state) => state.alertReducer.requestLoading;
