import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import { setBanner, setSliderCategories, setSliderData, setSlidersData } from "./layoutReducer/layoutActions";

const initialState = {
  banner: {
    desktop_image: "",
    mobile_image: "",
    url: "",
  },

  isSelebrity: false,
  homePageVideo: "",
  sliders: [],
  sliderCategories: {
    categories: {
      category_1: {
        name: "",
        description: "",
        items: [],
      },
    },
  },
  error: null,
};

const saveItem = (state, action) => {
  const { id, index } = action.payload;
  const copyContent = [...state.sliderCategories.categories[`category_${index}`].items];
  const indexItem = copyContent.findIndex((el) => el.id === id);
  copyContent[indexItem].saved = !copyContent[indexItem].saved;
  state.sliderCategories.categories[`category_${action.payload.index}`].content = copyContent;
};

const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    saveRecipe: saveItem,
    saveCollection: saveItem,
  },
  extraReducers: (builder) => {
    builder
      .addCase(HYDRATE, (state, action) => {
        state.sliders = action.payload.layoutReducer.sliders;
        state.sliderCategories = action.payload.layoutReducer.sliderCategories;
        state.banner = action.payload.layoutReducer.banner;
        state.isSelebrity = action.payload.layoutReducer.banner.is_celebrity_active;
        state.homePageVideo = action.payload.layoutReducer.homePageVideo;
      })
      .addCase(setBanner.rejected, (state, action) => {
        state.error = action.error;
      })
      .addCase(setBanner.fulfilled, (state, action) => {
        state.banner = action.payload;
        state.isSelebrity = action.payload.is_celebrity_active;
      })
      .addCase(setSliderCategories.rejected, (state, action) => {
        state.error = action.error;
      })
      .addCase(setSliderCategories.fulfilled, (state, action) => {
        state.sliderCategories = action.payload;
        state.isSelebrity = action.payload.is_celebrity_active;
        state.homePageVideo = action.payload.home_page_video;
        state.sliders = Object.keys(action.payload.categories);
      })
      .addCase(setSliderData.rejected, (state, action) => {
        state.error = action.error;
      })
      .addCase(setSliderData.fulfilled, (state, action) => {
        state.sliderCategories.categories[action.meta.arg].content = action.payload.content;
        state.sliderCategories.categories[action.meta.arg].tabType = action.payload.tabType || null;
      })
      .addCase(setSlidersData.rejected, (state, action) => {
        state.error = action.error;
      })
      .addCase(setSlidersData.fulfilled, (state, action) => {
        state.sliderCategories.categories = action.payload.content;
      });
  },
});

export default layoutSlice.reducer;

export const { saveCollection, saveRecipe } = layoutSlice.actions;

export const getSliders = (state) => state.layoutReducer.sliders;
export const hasSelebrity = (state) => state.layoutReducer.isSelebrity;
export const hasHomeVideo = (state) => state.layoutReducer.homePageVideo;
export const getBanner = (state) => state.layoutReducer.banner;
export const getSliderCategories = (state) => state.layoutReducer.sliderCategories;
