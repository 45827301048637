export const theme = {
  breakpoints: {
    s: 769,
    m: 1024,
    l: 1280,
    xl: 1440,
    xxl: 1920,
  },
  colors: {
    primary: "red",
  },
  spacings: {
    xs: 5,
    xs3: 6,
    xs2: 4,
    xs1: 8,
    s: 10,
    s1: 12,
    m1: 13,
    m: 15,
    l1: 18,
    l: 20,
    l2: 25,
    l3: 23,
    l4: 27,
    l5: 28,
    l6: 26,
    xl: 30,
    xl1: 35,
    xl2: 49,
    xl3: 40,
    xl4: 47,
    xl5: 67,
    xl6: 55,
    xxl: 60,
    xxl1: 53,
    xxl12: 58,
    xxl13: 65,
    xxl2: 97,
    xxl21: 80,
    xxl22: 83,
    xxl3: 110,
    xxl4: 116,
    auto: "auto",
    xxl5: 70,
    xxl6: 108,
  },
  defaults: {
    grid: {
      wrap: {
        maxWidth: 1200,
      },
    },
  },
};
