import React from "react";
import { ModalWrap, ModalContainer, OverLay, ModalContent } from "shared/Modal/styled";

const Modal = ({ text, type, children, width }) => {
  return (
    <ModalContainer type={type}>
      <OverLay />
      <ModalWrap id="overlay" type={type}>
        <ModalContent width={width} type={type}>
          {text || children}
        </ModalContent>
      </ModalWrap>
    </ModalContainer>
  );
};

export default Modal;
