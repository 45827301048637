import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { Wrapper, Content, Message, MessageType1, Heading, IconWrap } from "./styled";
import { deleteAlert, getAlerts } from "store/alert";
import { Icon } from "components/SvgIcons";

const Alert = () => {
  const alerts = useSelector(getAlerts);
  const [alert, setAlert] = useState({ type: "", message: "", message2: "" });
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (alerts.length > 0) {
      setAlert(alerts[alerts.length - 1]);
      setShow(true);
      let promise = new Promise((resolve) => {
        setTimeout(() => resolve(setShow(false)), 3000);
      });
      promise.then(() => dispatch(deleteAlert()));
    }
  }, [alerts]);

  const onClose = () => {
    setShow(false);
  };
  const more_messages = (
    <MessageType1>
      <Heading>{alert.message2 || ""}</Heading>
      <Message>{alert.message || ""}</Message>
    </MessageType1>
  );
  return show
    ? createPortal(
        <Wrapper className={`${alert.type || "error"}`}>
          <Content>{alert.message2 ? more_messages : <Message>{alert.message || ""}</Message>}</Content>
          <IconWrap onClick={onClose}>
            <Icon icon="close_2" />
          </IconWrap>
        </Wrapper>,
        document.body
      )
    : null;
};

export default Alert;
