import styled from "@emotion/styled";
import { colors } from "styles/colors";

export const ButtonIconWrap = styled.div`
  cursor: pointer;
  width: 26px;
  height: 26px;
  background-color: ${colors.GREY_LIGHT};
  border-radius: 50%;
  flex: 0 0 26px;
  position: relative;
  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .icon-delete-2 {
    width: 16px;
    height: 16px;
  }
`;
