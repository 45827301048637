export const validations = {
  password: {
    required: true,
    maxLength: 64,
    minLength: 8,
    pattern: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
    message: ` Password should have at least 8 symbols, at least one number, at least one upper case letter and at least one lower case letter`,
  },
  confirmPassword: {
    message: "Passwords don't match",
    pattern: /\S/,
  },
  email: {
    required: true,
    maxLength: 128,
    minLength: 6,
    pattern: /^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\s*$/i,
    message: "Invalid email",
    validate: {
      withOutSpace: (value) => !!value.trim(),
    },
  },
  firstName: {
    required: true,
    maxLength: 128,
    pattern: /^[a-zA-Z-'_\s]*$/,
    message: "Only letters, apostrophe and underlines",
  },
  lastName: {
    required: true,
    maxLength: 128,
    pattern: /^[a-zA-Z-'_\s]*$/,
    message: "Only letters, apostrophe and underlines",
  },
  gender: {
    required: true,
    message: "Required",
  },
};
