import React from "react";
import { Global, css } from "@emotion/core";

import { colors } from "styles/colors";
import { fonts } from "styles/fonts";

export const GlobalStyled = (
  <Global
    styles={css`
      :root {
        box-sizing: border-box;
        font-size: 1em;
      }

      img {
        max-width: 100%;
        height: auto;
      }
      *,
      *::before,
      *::after {
        box-sizing: inherit;
      }

      body {
        margin: 0;
        font-size: 1rem;
        line-height: 1.5;
        font-family: ${fonts.latoRegular}, "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
          sans-serif;
        font-weight: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: ${colors.GREY_DARK};
        background: ${colors.WHITE};
        overflow-x: hidden;
      }

      * {
        box-sizing: border-box;
      }
      html,
      body {
        margin: 0px;
        padding: 0px;
        height: 100%;
      }
      #root,
      .App {
        height: 100%;
        min-height: 100%;
      }
      h1,
      h2,
      h3,
      h4,
      h5 {
        margin-top: 0;
        margin-bottom: 20px;
        font-weight: normal;
        font-family: ${fonts.abhayaBold};
      }
      h1 {
        font-size: 36px;
      }
      h3 {
        font-size: 18px;
      }
      a {
        color: inherit;
      }
      svg.icon {
        width: 16px;
        height: 16px;
        stroke: ${colors.GREY_DARK};
      }
      #nprogress .bar {
        background: ${colors.RED} !important;
      }

      #nprogress .peg {
        box-shadow: 0 0 10px ${colors.RED}, 0 0 5px ${colors.RED};
      }

      #nprogress .spinner-icon {
        border-top-color: ${colors.RED};
        border-left-color: ${colors.RED};
      }

      .scrollBar {
        max-height: 180px;
        height: inherit;
        overflow: hidden;
        overflow-y: auto !important;
        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background: rgba(0, 0, 0, 0.2);
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background: rgba(0, 0, 0, 0.5);
          border: none;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: rgba(0, 0, 0, 0.8);
        }
      }
      button,
      input[type="submit"],
      input[type="button"],
      input {
        font: 1rem ${fonts.latoRegular};
      }
      input,
      textarea,
      input[type="number"] {
        font: 1rem/1.35 ${fonts.latoRegular};
        &::placeholder,
        &::-webkit-input-placeholder {
          color: ${colors.GREY};
          font: 1rem/1.5 ${fonts.latoRegular};
        }
      }

      @font-face {
        font-family: "Roboto-normal";
        src: url("/fonts/Roboto-Regular.woff2") format("woff2"), url("/fonts/Roboto-Regular.woff") format("woff");
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: "PT Serif";
        src: url("/fonts/PTSerif-Bold.woff2") format("woff2"), url("/fonts/PTSerif-Bold.woff") format("woff");
        font-weight: bold;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: "Roboto-bold";
        src: url("/fonts/Roboto-Bold.woff2") format("woff2"), url("/fonts/Roboto-Bold.woff") format("woff");
        font-weight: bold;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: "Roboto-medium";
        src: url("/fonts/Roboto-Medium.woff2") format("woff2"), url("/fonts/Roboto-Medium.woff") format("woff");
        font-weight: 500;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: "Lato-Medium";
        src: url("/fonts/Lato-Medium.woff2") format("woff2"), url("/fonts/Lato-Medium.woff") format("woff");
        font-weight: 500;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: "Lato-Regular";
        src: url("/fonts/Lato-Regular.woff2") format("woff2"), url("/fonts/Lato-Regular.woff") format("woff");
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: "Lato-Bold";
        src: url("/fonts/Lato-Bold.woff2") format("woff2"), url("/fonts/Lato-Bold.woff") format("woff");
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: "Abhaya-Bold";
        src: url("/fonts/AbhayaLibre-Bold.woff2") format("woff2"), url("/fonts/AbhayaLibre-Bold.woff") format("woff");
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: "Abhaya-Regular";
        src: url("/fonts/AbhayaLibre-Regular.woff2") format("woff2"),
          url("/fonts/AbhayaLibre-Regular.woff") format("woff");
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: "Rubik-Regular";
        src: url("/fonts/Rubik-Regular.woff2") format("woff2"), url("/fonts/Rubik-Regular.woff") format("woff");
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: "Rubik-Medium";
        src: url("/fonts/Rubik-Medium.woff2") format("woff2"), url("/fonts/Rubik-Medium.woff") format("woff");
        font-weight: 500;
        font-style: normal;
        font-display: swap;
      }
      #__next {
        height: 100%;
      }
      .inputName {
        display: flex;
        flex-direction: column;
      }

      .hide-mobile-global {
        @media (max-width: 768px) {
          display: none;
        }
      }

      .hide-desktop-global {
        @media (min-width: 769px) {
          display: none;
        }
      }
    `}
  />
);
