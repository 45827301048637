export const minTitleLength = 0;
export const maxTitleLength = 60;
export const minPrivateRecipes = 3;
export const minFreeRecipes = 2;
export const maxFileSize = 10;
export const maxFileName = 30;

export const pdfData = {
  file: {
    ref_url: null,
    name: "",
  },
  file_type: "pdf",
  collection_id: null,
};
