export const fonts = {
  latoBold: "Lato-Bold",
  latoRegular: "Lato-Regular",
  latoMedium: "Lato-Medium",
  robotoNormal: "Roboto-normal",
  robotoBold: "Roboto-bold",
  robotoMedium: "Roboto-medium",
  ptSerif: "PT Serif",
  abhayaBold: "Abhaya-Bold",
  abhayaRegular: "Abhaya-Regular",
  rubikRegular: "Rubik-Regular",
  rubikMedium: "Rubik-Medium",
};
