import React from "react";
import { useGoogleLogout } from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { useStateMachine } from "little-state-machine";
import { useRouter } from "next/router";
import Link from "next/link";

import { logoutUser } from "store/userReducer/userActions";
import { isUserBuyer, getOwner } from "store/userReducer/userSelectors";
import { clearErrors } from "store/alert";
import { rerenderAddRecipe } from "store/recipesReducer";
import { setPurchasedLibraryMode, setSavedLibraryMode } from "store/libraryReducer";
import { isLibraryShowPurchasedRecipes, isLibraryShowSavedRecipes } from "store/libraryReducer";

import { useDropdown } from "helpers/hooks/useDropdown";
import { cleanAddCollection, cleansAction } from "helpers";

import { Icon } from "../../../SvgIcons";
import Button from "shared/Button";
import { colors } from "styles/colors";
import {
  Avatar,
  BtnBlock,
  DropDown,
  DropDownToggle,
  HomeWrapper,
  LinkItem,
  Links,
  LinksWrap,
  LinkWrap,
  ManagerWrapper,
  MenuMain,
  MenuWraper,
  Wrapper,
  FeedbackWrapper,
} from "./MobileUserMenu.styled";
import cloudfront from "helpers/cloudfront";

export const MobileFeedback = ({ toggleBurger }) => (
  <FeedbackWrapper>
    <Links>
      <LinkItem onClick={toggleBurger}>
        <LinkWrap className="red-link" href="https://forms.clickup.com/f/6b1v5-2441/TABFPXLLVXZIM4WQEH" target="_blank">
          <Icon icon="chat" />
          Leave Feedback
        </LinkWrap>
      </LinkItem>
    </Links>
  </FeedbackWrapper>
);

export const MobileUserMenu = ({ toggleBurger }) => {
  const router = useRouter();
  const { isDropDown, toggleDropDown } = useDropdown();
  const { state, actions } = useStateMachine({
    cleansAction,
    cleanAddCollection,
  });

  const { signOut } = useGoogleLogout({
    jsSrc: "https://apis.google.com/js/api.js",
    clientId: process.env.NEXT_PUBLIC_REACT_APP_GOOGLE_ID,
    onLogoutSuccess: () => null,
  });

  const user = useSelector(getOwner);
  const _isLibraryShowPurchasedRecipes = useSelector(isLibraryShowPurchasedRecipes);
  const _isLibraryShowSavedRecipes = useSelector(isLibraryShowSavedRecipes);
  const isLibraryPageActive = router.pathname === "/library";
  const isBuyer = useSelector(isUserBuyer);

  const userURL = user.slug ? `/${user.slug}` : `/users/${user.id}`;

  const dispatch = useDispatch();

  const toAddRecipe = () => {
    actions.cleansAction(state);
    dispatch(clearErrors());
    dispatch(rerenderAddRecipe());
    toggleBurger();
    sessionStorage.setItem("isTheFormOfRecipesSubmit", false);
    router.push("/add-recipe");
  };

  const toAddCollection = () => {
    actions.cleanAddCollection(state);
    toggleBurger();
    sessionStorage.setItem("isTheFormOfRecipesCollection", false);
    router.push("/add-collection");
  };

  const handleLogOut = async () => {
    try {
      if (localStorage.getItem("social_login") === "FB") {
        window.FB.logout();
      } else if (localStorage.getItem("social_login") === "GA") {
        signOut();
      }
      dispatch(logoutUser({}));
    } catch (err) {
      console.log(err);
    }
    sessionStorage.removeItem("paidInCollections");
    dispatch(logoutUser({}));
  };

  const toSavedLibrary = () => {
    dispatch(setSavedLibraryMode());
  };

  const toPurchasedLibrary = () => {
    dispatch(setPurchasedLibraryMode());
  };

  return (
    <MenuWraper>
      <MenuMain>
        {" "}
        <Wrapper open={isDropDown}>
          <DropDown>
            <DropDownToggle isDropDown={isDropDown} onClick={toggleDropDown}>
              <Links>
                <LinkItem>
                  <LinkWrap>
                    {user.main_image ? (
                      <Avatar
                        src={cloudfront({ href: user.main_image, size: "small" })}
                        className="user"
                        crossOrigin="anonymous"
                      />
                    ) : (
                      <Icon icon="avatar" className="user" />
                    )}
                    {user.first_name} {user.last_name}
                  </LinkWrap>
                </LinkItem>
              </Links>
            </DropDownToggle>
            <LinksWrap isDropDown={isDropDown}>
              <Links>
                <LinkItem onClick={toggleBurger}>
                  <Link href={userURL} passHref>
                    <LinkWrap>
                      <Icon icon="avatar" className="user" />
                      My Page
                    </LinkWrap>
                  </Link>
                </LinkItem>
                <LinkItem onClick={toggleBurger}>
                  <Link href={`/settings`} passHref>
                    <LinkWrap>
                      <Icon icon="settings" className="user" />
                      Settings
                    </LinkWrap>
                  </Link>
                </LinkItem>
                <LinkItem
                  onClick={() => {
                    handleLogOut();
                    toggleBurger();
                  }}
                >
                  <LinkWrap>
                    <Icon icon="logout" className="user" />
                    Log Out
                  </LinkWrap>
                </LinkItem>
              </Links>
            </LinksWrap>
          </DropDown>
        </Wrapper>
        <HomeWrapper isBuyer={isBuyer}>
          <Links>
            <LinkItem onClick={toggleBurger} className={router.pathname === "/" ? "isActive" : ""}>
              <Link href="/" passHref>
                <LinkWrap>
                  <Icon icon="home" />
                  Home
                </LinkWrap>
              </Link>
            </LinkItem>

            <LinkItem onClick={toggleBurger} className={router.pathname === "/grocery-list" ? "isActive" : ""}>
              <Link href="/grocery-list" passHref>
                <LinkWrap>
                  <Icon icon="add-to-grocery" />
                  Grocery List
                </LinkWrap>
              </Link>
            </LinkItem>

            <LinkItem
              onClick={toggleBurger}
              className={_isLibraryShowSavedRecipes && isLibraryPageActive ? "isActive" : ""}
            >
              <Link href="/library" passHref>
                <LinkWrap onClick={toSavedLibrary}>
                  <Icon icon="saveslide" />
                  Liked
                </LinkWrap>
              </Link>
            </LinkItem>

            <LinkItem
              onClick={toggleBurger}
              className={_isLibraryShowPurchasedRecipes && isLibraryPageActive ? "isActive" : ""}
            >
              <Link href="/library" passHref>
                <LinkWrap onClick={toPurchasedLibrary}>
                  <Icon icon="newpurchase" />
                  Purchased
                </LinkWrap>
              </Link>
            </LinkItem>
          </Links>
        </HomeWrapper>
        <ManagerWrapper>
          <Links>
            {" "}
            {user.is_creator && (
              <LinkItem onClick={toggleBurger} className={router.pathname === "dashboard" ? "isActive" : ""}>
                <Link href="/dashboard" passHref>
                  <LinkWrap>
                    <Icon icon="dashboard" />
                    Dashboard
                  </LinkWrap>
                </Link>
              </LinkItem>
            )}
            <LinkItem onClick={toggleBurger}>
              <Link href={`/recipe_manager`} passHref>
                <LinkWrap>
                  <Icon icon="recipe_manager" />
                  Recipe Manager
                </LinkWrap>
              </Link>
            </LinkItem>
          </Links>
        </ManagerWrapper>
        <BtnBlock>
          <Button color={colors.RED} stylType="primary" text="Create Recipe" onClick={toAddRecipe} />
          {user.is_creator === true && (
            <Button color={colors.RED} stylType="primary" text="Create Collection" onClick={toAddCollection} />
          )}
        </BtnBlock>
      </MenuMain>
    </MenuWraper>
  );
};
