import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  ${(props) => props.isPageError === "error" && "background-color:red;"};
`;

export const LayoutWrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  ${(props) => props.isPageError === "error" && "background-color:red;"};
`;
