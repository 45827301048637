import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modalId: null,
  videoModal: false,
  paymentModal: false,
  paidInCollectionModal: false,
  joinWaitlistModal: false,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    showVideoModal: (state) => {
      state.videoModal = true;
    },
    hideVideoModal: (state) => {
      state.videoModal = false;
    },
    togglePamentModal: (state, action) => {
      state.paymentModal = action.payload;
    },
    setModalId: (state, action) => {
      state.modalId = action.payload;
    },
    togglePaidInCollectionModal: (state, action) => {
      state.paidInCollectionModal = action.payload;
    },
    toggleJoinWaitlistModal: (state, action) => {
      state.joinWaitlistModal = action.payload;
    },
  },
});

export const {
  showVideoModal,
  setModalId,
  hideVideoModal,
  togglePamentModal,
  togglePaidInCollectionModal,
  toggleJoinWaitlistModal,
} = modalSlice.actions;

export default modalSlice.reducer;

export const modalVideo = (state) => state.modalReducer.videoModal;
export const modalPayment = (state) => state.modalReducer.paymentModal;
export const modalPaidInCollection = (state) => state.modalReducer.paidInCollectionModal;
export const modalJoinWaitlist = (state) => state.modalReducer.joinWaitlistModal;
export const getModalId = (state) => state.modalReducer.modalId;
