import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import {
  logoutUser,
  setUser,
  changePassword,
  setCurrentUser,
  resetPassword,
  patchOwner,
} from "./userReducer/userActions";

const initialState = {
  owner: {
    birthdate: "",
    business_name: "",
    email: "",
    first_name: "",
    gender: "",
    id: "",
    image: "",
    is_active: "",
    is_creator: "",
    is_superuser: "",
    last_name: "",
    location: "",
    show_business_name: "",
    date_joined: "",
  },
  user: {},
  userLoading: true,
  submitting: false,
  isChangePasswordLoading: false,
  errors: {},
  user_mode: "buyer",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setOwner: (state, action) => {
      state.owner = { ...state.owner, ...action.payload };
    },
    setNewOwner: (state, action) => {
      state.owner = action.payload;
    },
    clearError: (state) => {
      state.errors = {};
    },
    addBuyerPaymentConnected: (state) => {
      state.owner.buyer_payment_method_connected = true;
    },
    addCreatorPaymentConnected: (state) => {
      state.owner.creator_payment_method_connected = true;
    },
    setBuyerMode: (state) => {
      state.user_mode = "buyer";
    },
    setCreatorMode: (state) => {
      state.user_mode = "creator";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(HYDRATE, (state, action) => {
        state.user = action.payload.userReducer.user;
        state.owner = action.payload.userReducer.owner;
      })
      .addCase(setCurrentUser.fulfilled, (state, action) => {
        state.owner = action.payload;
      })
      .addCase(setUser.pending, (state) => {
        state.errors = {
          data: false,
        };
        state.userLoading = true;
      })
      .addCase(setUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.userLoading = false;
      })
      .addCase(setUser.rejected, (state) => {
        state.errors = {
          data: true,
        };
        state.userLoading = false;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.owner = initialState.owner;
      })
      .addCase(patchOwner.pending, (state) => {
        state.submitting = true;
        state.errors = {};
      })
      .addCase(patchOwner.fulfilled, (state, action) => {
        state.owner = { ...state.owner, ...action.payload };
        state.submitting = false;
      })
      .addCase(patchOwner.rejected, (state, action) => {
        state.submitting = false;
        state.errors = {
          data: action.payload,
        };
      })
      .addCase(changePassword.pending, (state) => {
        state.isChangePasswordLoading = true;
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.isChangePasswordLoading = false;
      })
      .addCase(changePassword.rejected, (state) => {
        state.isChangePasswordLoading = false;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.isChangePasswordLoading = false;
      })
      .addCase(resetPassword.rejected, (state) => {
        state.isChangePasswordLoading = false;
      })
      .addCase(resetPassword.pending, (state) => {
        state.isChangePasswordLoading = true;
      });
  },
});

export const {
  setOwner,
  setNewOwner,
  clearError,
  addBuyerPaymentConnected,
  addCreatorPaymentConnected,
  setBuyerMode,
  setCreatorMode,
} = userSlice.actions;

export default userSlice.reducer;
