import styled from "@emotion/styled";
import { colors } from "styles/colors";
import { fonts } from "styles/fonts";
import { Spinner } from "styles/App.styled";

export const ButtonWrap = styled.button`
  position: relative;
  line-height: 1.5;
  ${(props) =>
    props.btnSize === "larger" ? `padding:10px 10px 10px; line-height: 1.5;` : `padding: 10px 12px 10px; `};
  ${(props) => props.btnSize === "small" && `padding: 7px 12px; line-height: 1.25;`};
  ${(props) => props.withoutPadding && "padding: 0"};
  display: ${(props) => (props.btnBlock === "btnBlock" ? `block` : `inline-block`)};
  vertical-align: middle;
  text-align: center;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid
    ${(props) =>
      (props.stylType === "master" && colors.GREY_LIGHT) ||
      (props.stylType === "primary" && props.color) ||
      (props.stylType === "outline" && props.border) ||
      (props.stylType === "secondary" && colors.GREY_DARK)} !important;

  border-radius: 5px;
  font-size: ${(props) => (props.btnSize === "larger" ? `16px;` : `16px;`)};
  font-family: ${fonts.latoRegular} !important;
  overflow: visible;
  text-decoration: none;
  cursor: pointer;
  background-color: ${(props) =>
    (props.stylType === "default" && "transparent") ||
    (props.stylType === "master" && colors.GREY_LIGHT) ||
    (props.stylType === "outline" && (props.color || "transparent")) ||
    (props.stylType === "primary" && props.color) ||
    (props.stylType === "secondary" && colors.WHITE)} !important;
  color: ${(props) =>
    (props.stylType === "master" && colors.GREY_SEMIDARK) ||
    (props.stylType === "primary" && (props.textColor || colors.WHITE)) ||
    (props.stylType === "outline" && props.textColor) ||
    (props.stylType === "secondary" && colors.GREY_DARK)} !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  &:active,
  &:focus {
    outline: none;
  }

  span {
    display: none;
  }

  &:hover {
    span {
      top: -30px;
      right: 0;
      display: block;
      position: absolute;
      background-color: rgba(34, 36, 36, 0.9);
      color: ${colors.WHITE};
      font-size: 10px;
      padding: 5px;
      border-radius: 4px;
    }
  }

  &:disabled {
    background-color: ${(props) =>
      props.stylType === "secondary" ? `transparent` : `${colors.GREY_LIGHT}`} !important;
    border: 1px solid ${(props) => (props.stylType === "secondary" ? `transparent` : `${colors.GREY_LIGHT}`)} !important;
    color: ${colors.GREY} !important;
    ${(props) =>
      props.stylType === "secondary"
        ? `
      .icon {
        stroke: ${colors.GREY} !important;
      }
    `
        : `
    `}
    cursor: default;
    .icon {
      stroke: ${colors.GREY} !important;
      fill: ${colors.GREY} !important;
    }
  }

  .icon {
    vertical-align: middle;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-bottom: 3px;
    ${(props) => props.iconRight && ` margin-left: 10px;`}
    ${(props) => props.iconLeft && `margin-right : 8px;`}
    stroke: ${(props) =>
      (props.disabled && colors.GREY_SEMIDARK) ||
      (props.stylType === "master" && colors.GREY_DARK) ||
      (props.stylType === "primary" && (props.textColor || colors.WHITE)) ||
      (props.stylType === "outline" && props.textColor) ||
      (props.stylType === "secondary" && colors.GREY_DARK)} !important;
    fill: ${(props) =>
      (props.disabled && colors.GREY_SEMIDARK) ||
      (props.stylType === "master" && colors.GREY_DARK) ||
      (props.stylType === "primary" && (props.textColor || colors.WHITE)) ||
      (props.stylType === "outline" && props.textColor) ||
      (props.stylType === "secondary" && colors.GREY_DARK)} !important;
  }
`;

export const ButtonSpinner = styled(Spinner)`
  vertical-align: middle;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-bottom: 3px;
  margin-left: 5px;
`;
