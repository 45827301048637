import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCollection,
  getPrivateRecipeContainIn,
  getRecipe,
  updateMadeIt,
  getLandingRecipe,
  getCollectionLanding,
  getCollectionRecipesLanding,
} from "api/api";
import { setUserCollection, setUserRecipes } from "../recipesReducer/recipesActions";
import { createAlert } from "../alert";

export const madeItRecipe = createAsyncThunk(
  "MADE_IT_RECIPE",
  ({ recipe, made_times }, { rejectWithValue, dispatch, getState }) =>
    updateMadeIt({ recipe, made_times })
      .then(() => {
        const reduxState = getState();
        let { made_it, made_it_sum } = reduxState.collectionReducer.recipe;

        made_it += made_times;
        made_it_sum += made_times;

        return { made_it, made_it_sum };
      })
      .catch((err) => {
        dispatch(createAlert({ type: "error", message: "Something wrong" }));
        return rejectWithValue(err);
      })
);

export const setCollection = createAsyncThunk("SET_COLLECTION_PAGE", (id, { dispatch }) =>
  getCollection(id).then((res) => {
    dispatch(setUserCollection({ id: res.data.collection_creator.id }));
    return res.data;
  })
);

export const setRecipe = createAsyncThunk("SET_RECIPE_PAGE", (id, { dispatch }) =>
  getRecipe(id).then(async (res) => {
    dispatch(setUserRecipes({ id: res.data.recipe_creator.id }));
    let resPrivate;
    if (res.data.private) {
      try {
        resPrivate = await getPrivateRecipeContainIn(id);
        res.data.collections = resPrivate.data.results;
      } catch (e) {
        console.log("private collections error", e);
      }
    }
    return res.data;
  })
);

export const setLandingRecipe = createAsyncThunk("SET_RECIPE_PAGE", async (id, thunkAPI) => {
  try {
    const res = await getLandingRecipe(id);
    return res.data;
  } catch (err) {
    return thunkAPI.rejectWithValue({ status: err.response.status });
  }
});

export const setCollectionLanding = createAsyncThunk("SET_COLLECTION_LANDING", (id, { rejectWithValue }) => {
  return getCollectionLanding(id)
    .then(({ data }) => {
      return {
        ...data,
      };
    })
    .catch((err) => {
      return rejectWithValue(err);
    });
});

export const setCollectionRecipesLanding = createAsyncThunk(
  "SET_COLLECTION_RECIPES_LANDING",
  (id, { rejectWithValue }) => {
    return getCollectionRecipesLanding(id)
      .then(({ data }) => {
        return {
          ...data,
        };
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);
