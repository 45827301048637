import { configureStore, combineReducers } from "@reduxjs/toolkit";
import alertReducer from "./alert";
import loginReducer from "store/loginReducer";
import recipesReducer from "./recipesReducer";
import collectionReducer from "./collectionReducer";
import userReducer from "./userReducer";
import modalReducer from "./modalReducer";
import uploadReducer from "./uploadReducer";
import searchReducer from "./searchReducer";
import libraryReducer from "./libraryReducer";
import paymentReducer from "./paymentReducer";
import layoutReducer from "./layoutReducer";
import mealPlanReducer from "./mealPlanReducer";
import { createWrapper } from "next-redux-wrapper";

const appReducer = combineReducers({
  alertReducer,
  loginReducer,
  recipesReducer,
  collectionReducer,
  userReducer,
  modalReducer,
  uploadReducer,
  searchReducer,
  libraryReducer,
  paymentReducer,
  layoutReducer,
  mealPlanReducer,
});

const makeStore = () =>
  configureStore({
    reducer: appReducer,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });

export const wrapper = createWrapper(makeStore);
