import styled from "@emotion/styled";

import { colors } from "styles/colors";
import { fonts } from "styles/fonts";
import { ButtonWrap } from "../shared/Button/styled";

export const ColorLight = styled.span`
  color: ${colors.GREY};
`;

export const ColorLink = styled.span`
  color: ${colors.RED};
  line-height: 1.5;
`;

export const InfoText = styled.span`
  @media (min-width: 769px) {
    ${(props) => props.display && " display: block;"};
  }
  margin-top: 5px;
`;
export const Deliver = styled.div`
  margin: ${(props) => (props.spacing ? "20px 0 20px" : "28px 0 30px")};
  margin: ${(props) => (props.spacing2 ? "20px 0 20px" : "19px 0")};
  border-bottom: 1px solid ${(props) => props.color};
`;
export const DeliverType1 = styled(Deliver)`
  margin: 2px 0 15px;
  @media (min-width: 769px) {
    margin: ${(props) => (props.spacing ? "20px 0 20px" : "12px 0 21px")};
  }
`;

export const Hr = styled.div`
  margin: 28px 0;
  border: none;
  height: 2px;
  width: 100%;
  background-color: ${colors.GREY_LIGHT};
`;
export const Title = styled.div`
  font-family: ${fonts.abhayaBold};
  font-size: 24px;
  margin-bottom: 18px;
  @media (min-width: 769px) {
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.75em;
  }
`;

export const TitleDeliver = styled(Title)`
  position: relative;
  ${(props) => (props.width ? "max-width: 400px" : "max-width: 100%")};
  ${(props) => (props.spacing ? " margin: 0 auto 20px" : " margin: 0 auto 27px")};
  ${(props) => props.spacing2 && "margin: 0 0 11px"};
  ${(props) => (props.spacing2 ? "padding-bottom: 0" : "padding-bottom: 6px")};
  width: 100%;
  line-height: 1.2;
  border-bottom: ${(props) => (props.deliverHide ? "none" : "1px solid ${colors.GREY_LIGHT}")};
  @media (max-width: 360px) {
    ${(props) => (props.width2 ? "max-width: 300px" : "max-width: 100%")};
  }
  @media (min-width: 769px) {
    border-bottom: 1px solid ${colors.GREY_LIGHT};
    padding-bottom: 23px;
    ${(props) => (props.spacing2 ? "margin: 0 auto 18px" : "margin: 0 auto 37px")};
    ${(props) => props.spacing3 && " margin: 0 0 27px"};
    &.connect-stripe {
      margin: 0 auto 5px;
    }
  }
`;

export const Account = styled.div`
  cursor: pointer;
  border-radius: 20px;
  width: 352px;
  height: 570px;

  ${(props) =>
    props.isActive
      ? `
      border: 1px solid ${colors.RED};
      background: #FFEFED;
      .icon {
        stroke: ${colors.GREY_DARK};
      }
     `
      : ` 
      border: 1px solid ${colors.GREY_SEMILIGHT};     
      .icon {
        stroke: ${colors.GREY};
      }
  `}

  @media (max-width: 768px) {
    width: 160px;
    height: 208px;
  }
`;

export const AccountTitle = styled.div`
  text-align: center;
  font-size: 24px;
  font-family: ${fonts.abhayaBold};
  line-height: 28.31px;
`;

export const Circle = styled.div`
  height: 198px;
  width: 308px;
  margin: 50px auto 20px auto;

  @media (max-width: 768px) {
    height: 144px;
    width: 160px;
    margin: 0;
    margin-top: 11px;
  }

  .icon {
    height: 100%;
    width: 100%;
  }
`;

export const AccountWrap = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    margin: 23px 0px;
  }
`;

export const AccountItem = styled.div`
  display: flex;
  flex-direction: column;

  &:nth-child(1) {
    margin-right: 30px;
  }

  @media (max-width: 768px) {
    &:nth-child(1) {
      margin-right: 8px;
    }
  }
`;

export const Dash = styled.div`
  margin: 23px auto 34px;
  height: 2px;
  width: 36px;
  background-color: ${colors.RED};
`;

export const Message = styled.div`
  min-height: 130px;
  color: ${colors.GREY_SEMIDARK};
  line-height: 1.2;

  p {
    margin: 0;
    + p {
      margin-top: 11px;
    }
  }

  @media (min-width: 769px) {
    margin: 20px auto 0;
    max-width: 276px;
    width: 100%;
    min-height: inherit;
    text-align: center;
    p + p {
      margin-top: 14px;
    }
  }
`;

export const Hightlight = styled.span`
  color: ${colors.GREY_DARK};
  font-family: ${fonts.latoBold};
`;
export const Required = styled.span`
  color: ${colors.RED};
`;

export const Button = styled.button``;

export const PrivacyPolicy = styled.a`
  color: ${colors.RED};
  &:hover {
    text-decoration: underline;
  }
`;
export const BtnBlock = styled.div`
  margin-top: 28px;
  margin-bottom: 30px;
  ${ButtonWrap} {
    display: block;
    width: 100%;
  }
`;

export const MobileAppImages = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;

  @media (max-width: 350px) {
    margin-left: 15px;
  }
`;

export const MobileAppImageBig = styled.img`
  display: block;
  position: relative;
  bottom: -30px;
  border-radius: 12px;
  z-index: 10;
  max-width: 192px !important;
  max-height: 294px !important;
`;

export const MobileAppImageSmall = styled.img`
  display: block;
  position: relative;
  bottom: -25px;
  left: -10px;
  transform: scale(0.9);
  z-index: 5;
  max-width: 146px !important;
  max-height: 269px !important;
  border-radius: 12px;
`;

export const AppStoreImage = styled.img`
  max-width: 140px !important;
  max-height: 40px !important;
  cursor: pointer;
`;

export const GetAppBannerContainer = styled.div`
  position: relative;
  border-radius: 24px;
  background: #f6ece6;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 46px 54px;
  overflow: hidden;
  min-height: 280px;
  max-height: 280px;
  margin-top: 32px;
  margin-bottom: 32px;

  @media (max-width: 768px) {
    flex-direction: column;
    min-height: 490px;
    padding: 16px 24px;
  }

  h1 {
    color: #7c3200;
    font-family: ${fonts.rubikRegular};
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 100% */
  }

  p {
    color: #933a00;
    font-family: ${fonts.rubikRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    max-width: 294px;
    margin-top: 0;
    margin-bottom: 20px;
  }
`;

export const SignInPopUpNoFreeRecipesLeft = styled.div`
  background-color: ${colors.WHITE};
  height: 100%;
  min-height: 20vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0 16px;
  z-index: 10;

  max-width: 800px;
  margin: 0 auto;

  img {
    width: 100%;
    max-width: 300px;
    margin-top: 60px;
  }

  h1 {
    margin-top: 40px;
    font-family: ${fonts.rubikMedium};
    font-size: 28px;
    color: #111;
    margin-bottom: 20px;
    line-height: 1.2;
  }

  p {
    font-family: ${fonts.rubikRegular};
    color: #686868;
    font-size: 16px;
    max-width: 458px;
    text-align: center;
    margin: 0;
    max-width: 624px;
  }
`;

export const Row = styled.div`
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
`;
export const Content = styled.div`
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
`;
export const Container = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  max-width: 1304px;
  position: relative;
  flex-grow: 1;
  @media (min-width: 769px) {
    padding-left: 20px;
    padding-right: 20px;
    ${Content} {
      flex-grow: 1;
      max-width: 75vw;
    }
  }
  @media (min-width: 1024px) {
    padding-left: 20px;
    padding-right: 20px;
    ${Content} {
      flex-grow: 1;
      max-width: 1088px;
    }
  }
`;
export const ContainerFull = styled(Container)`
  max-width: none;
`;
export const TextBold = styled.span`
  font-family: ${fonts.latoBold};
`;
export const TextBolder = styled.span`
  font-family: ${fonts.robotoBold};
`;
export const TextCenter = styled.div`
  text-align: center;
`;
export const BigText = styled.div`
  font-size: 18px;
  font-family: ${(props) => props.fontFamily && `${fonts.latoBold}`};
`;

/* Style MainTitle */
export const MainTitle = styled.h1`
  width: 100%;
  font-size: 24px;
  font-family: ${fonts.abhayaBold};
  color: ${colors.GREY_DARK};
  margin-bottom: 30px;
  word-break: break-word;
  overflow-wrap: break-word;
`;

/* Style LineDisplay */
export const LineDisplay = styled.div`
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    height: 0;
    border-bottom: 2px dotted ${colors.GREY_LIGHT};
    z-index: 0;
  }
  > * {
    position: relative;
  }
`;
export const BtnBlockStyle = styled.div`
  > * {
    width: 100%;
  }
  @media (max-width: 359px) {
    button {
      font-size: 16px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
`;

export const LightText = styled.span`
  color: ${colors.GREY_SEMIDARK};
  font-family: ${fonts.abhayaRegular};
`;
