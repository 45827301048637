import { videoConversionInProgressStatus } from "../store/recipesReducer/recipesConstants";

/**
 * @method
 * @returns {boolean} true if browser is safari
 */
export const isSafari = () => {
  let userAgentString = navigator.userAgent;
  let chromeAgent = userAgentString.indexOf("Chrome") > -1;
  let safariAgent = userAgentString.indexOf("Safari") > -1;
  if (chromeAgent && safariAgent) return false;
  else return true;
};

export const isChrome = () => {
  if (typeof window !== "undefined") return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
};

export const showVideoConversionStub = (obj) => {
  const mainVideoConversionStatus = obj && obj.conversion_status;
  return isChrome() && mainVideoConversionStatus === videoConversionInProgressStatus;
};

export const getAppropriateBucket = () => {
  const buckets = {
    develop: "django-foodie-dev",
    staging: "django-foodie-stage",
    qa: "django-foodie-stage",
    production: "django-foodie-prod",
  };
  return buckets[process.env.NEXT_PUBLIC_FOODY_ENV];
};

export const getBaseUrl = () => {
  const DEVELOPMENT_URL_MAP = {
    develop: process.env.NEXT_PUBLIC_DEVELOPMENT_URL,
    qa: process.env.NEXT_PUBLIC_QA_URL,
    staging: process.env.NEXT_PUBLIC_STAGING_URL,
    production: process.env.NEXT_PUBLIC_PRODUCTION_URL,
  };
  return DEVELOPMENT_URL_MAP[process.env.NEXT_PUBLIC_FOODY_ENV];
};
