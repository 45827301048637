import styled from "@emotion/styled";
import { colors } from "styles/colors";

export const CheckboxWrap = styled.label`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  cursor: pointer;

  input {
    display: none;
  }
  input:checked ~ .checked {
    position: relative;
    display: block;
    background-color: ${colors.RED};
    border: 1px solid ${colors.RED};
  }
`;

export const CheckboxIcon = styled.div`
  border: 1px solid ${colors.GREY};
  border-radius: 5px;
  background-color: ${colors.WHITE};
  width: 22px;
  height: 22px;
  margin-right: 12px;
  position: relative;
  .icon {
    position: absolute;
    top: -1px;
    left: -1px;
    display: block;
    height: 22px;
    width: 22px;
    stroke: ${colors.WHITE};
  }
`;

export const CheckboxText = styled.div`
  color: ${colors.GREY_SEMIDARK};
`;
