import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchValue: "",
  loading: true,
  searchedRecipes: {
    results: [],
  },
  searchCollections: {
    results: [],
  },
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setSearchedRecipes: (state, action) => {
      state.searchedRecipes = action.payload;
    },
    setSearchedCollections: (state, action) => {
      state.searchCollections = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    saveSearchedRecipes: (state, action) => {
      const copyRecipes = [...state.searchedRecipes.results];
      const index = state.searchedRecipes.results.findIndex((el) => el.id === action.payload);
      copyRecipes[index].saved = !copyRecipes[index].saved;
      state.searchedRecipes.results = copyRecipes;
    },
    saveSearchedCollection: (state, action) => {
      const copyCollections = [...state.searchCollections.results];
      const index = state.searchCollections.results.findIndex((el) => el.id === action.payload);
      copyCollections[index].saved = !copyCollections[index].saved;
      state.searchCollections.results = copyCollections;
    },
  },
});

export const getSearchValue = (state) => state.searchReducer.searchValue;
export const getSearchedRecipes = (state) => state.searchReducer.searchedRecipes;
export const getSearchedCollections = (state) => state.searchReducer.searchCollections;
export const isSearchingLoading = (state) => state.searchReducer.loading;

export const {
  setSearchValue,
  setSearchedRecipes,
  setSearchedCollections,
  saveSearchedRecipes,
  saveSearchedCollection,
  setLoading,
} = searchSlice.actions;

export default searchSlice.reducer;
