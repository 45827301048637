import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFilterQueriesByFilterName } from "../../helpers/getFilterQueriesByFilterName";
import { getSortingNameByState } from "../../helpers/getSortingStateBySortingName";

import {
  getOwnCollections,
  getOwnRecipes,
  getPurchasedCollections,
  getPurchasedRecipes,
  getSavedCollections,
  getSavedRecipes,
  getUserCollection,
  getCreatorCollections,
  getUserRecipes,
  getCreatorRecipes,
  deleteRecipe,
  deleteCollection,
  unSave,
  saveRecipe,
  purshaseRecipeConfirm,
  purshaseCollectionConfirm,
} from "../../api/api";

import { createAlert } from "../alert";

import { isLibraryShowPurchasedRecipes, isLibraryShowSavedRecipes } from "../libraryReducer";

import {
  getCollectionsFilterName,
  getCollectionsSorting,
  getRecipesFilterName,
  getRecipesSorting,
  getSearchValue,
} from "./recipesSelectors";

import {
  setPurchaseRecipe,
  setPurchaseCollection,
  resetPagination,
  clearFilter,
  clearSorting,
  clearUserCollectionsAndRecipes,
} from "../recipesReducer";

export const setDraftCollections = createAsyncThunk("DRAFT_COLLECTIONS", (page = 1, { getState }) => {
  const state = getState();
  const searchQuery = getSearchValue(state);
  return getOwnCollections({ page, status: "draft", searchQuery }).then((res) => res.data);
});

export const setDraftRecipes = createAsyncThunk("DRAFT_RECIPES", (page = 1, { getState }) => {
  const state = getState();
  const searchQuery = getSearchValue(state);
  return getOwnRecipes({ page, status: "draft", searchQuery }).then((res) => res.data);
});

export const setUserCollection = createAsyncThunk("SET_COLLECTIONS", ({ id, page = 1 }) => {
  return getUserCollection({ id, page }).then((res) => res.data);
});

export const setCreatorCollections = createAsyncThunk("SET_CREATOR_COLLECTIONS", ({ id, full = false }, thunkAPI) => {
  return getCreatorCollections({ id, full })
    .then((res) => res.data)
    .catch((err) => thunkAPI.rejectWithValue(err));
});

export const setUserRecipes = createAsyncThunk("SET_RECIPES", ({ id, page = 1 }) => {
  return getUserRecipes({ id, page }).then((res) => res.data);
});

export const setCreatorRecipes = createAsyncThunk("SET_CREATOR_RECIPES", ({ id, page = 1, pageSize = 10 }) => {
  return getCreatorRecipes({ id, page, pageSize }).then((res) => res.data);
});

export const setCreatorRecipesFirstPage = createAsyncThunk(
  "SET_CREATOR_RECIPES_FIRST_PAGE",
  ({ id, page = 1, pageSize = 10 }) => {
    return getCreatorRecipes({ id, page, pageSize }).then((res) => res.data);
  }
);

export const setSavedRecipes = createAsyncThunk("SET_SAVED_RECIPES", (page = 1, { getState }) => {
  const state = getState();
  const filterName = getRecipesFilterName(state);
  const { isPaid, isPrivate, isFree } = getFilterQueriesByFilterName(filterName);

  const sortingState = getRecipesSorting(state);
  const { orderByTime, orderByTitle } = getSortingNameByState(sortingState);

  return getSavedRecipes({ page, isPaid, isPrivate, isFree, orderByTime, orderByTitle })
    .then(({ data }) => {
      return {
        count: data.count,
        next: data.next,
        previous: data.previous_page,
        results: data.results.map(({ recipe }) => recipe),
      };
    })
    .catch((err) => {
      throw new Error(err);
    });
});

export const setSavedCollections = createAsyncThunk("SET_SAVED_COLLECTIONS", (page = 1, { getState }) => {
  const state = getState();
  const filterName = getCollectionsFilterName(state);
  const { isPrivate, isFree } = getFilterQueriesByFilterName(filterName);

  const sortingState = getCollectionsSorting(state);
  const { orderByTime, orderByTitle } = getSortingNameByState(sortingState);

  return getSavedCollections({ page, isPrivate, isFree, orderByTime, orderByTitle })
    .then(({ data }) => {
      return {
        count: data.count,
        next: data.next,
        previous: data.previous_page,
        results: data.results.map(({ collection }) => collection),
      };
    })
    .catch((err) => {
      throw new Error(err);
    });
});

export const setPurchasedRecipes = createAsyncThunk(
  "SET_PURCHASED_RECIPES",
  (page = 1, { rejectWithValue, getState }) => {
    const state = getState();
    const searchQuery = getSearchValue(state);

    return getPurchasedRecipes({ page, searchQuery })
      .then(({ data }) => {
        return {
          ...data,
          results: data.results.map(({ recipe }) => recipe),
        };
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const setPurchasedCollections = createAsyncThunk(
  "SET_PURCHASED_COLLECTIONS",
  (page = 1, { rejectWithValue, getState }) => {
    const state = getState();
    const searchQuery = getSearchValue(state);

    return getPurchasedCollections({
      page,
      searchQuery,
    })
      .then(({ data }) => {
        return {
          ...data,
          results: data.results.map(({ collection }) => collection),
        };
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const setOwnRecipes = createAsyncThunk("SET_OWN_RECIPES", (page = 1, { getState }) => {
  const state = getState();
  const searchQuery = getSearchValue(state);
  const sortingState = getRecipesSorting(state);
  const { orderByTime, orderByTitle } = getSortingNameByState(sortingState);

  const filterName = getRecipesFilterName(state);
  const { isFree, isPaid, isPrivate } = getFilterQueriesByFilterName(filterName);

  return getOwnRecipes({ page, isPaid, isPrivate, isFree, searchQuery, orderByTitle, orderByTime }).then(
    (res) => res.data
  );
});

export const setOwnCollections = createAsyncThunk("SET_OWN_COLLECTIONS", (page = 1, { getState }) => {
  const state = getState();
  const searchQuery = getSearchValue(state);
  const sortingState = getCollectionsSorting(state);
  const { orderByTime, orderByTitle } = getSortingNameByState(sortingState);

  const filterName = getCollectionsFilterName(state);
  const { isFree, isPrivate } = getFilterQueriesByFilterName(filterName);
  return getOwnCollections({ page, isPrivate, isFree, searchQuery, orderByTitle, orderByTime }).then((res) => res.data);
});

export const getRecipesData = createAsyncThunk("GET_RECIPES_DATA", ({ pageName }, { getState, dispatch }) => {
  const state = getState();
  const user = state.userReducer.user;

  const isSavedRecipesShow = isLibraryShowSavedRecipes(state);
  const isPurchasedRecipes = isLibraryShowPurchasedRecipes(state);
  const isPurchasedRecipesShow = isPurchasedRecipes;

  dispatch(clearFilter());
  dispatch(resetPagination());
  dispatch(clearSorting());

  if (pageName === "recipe_manager") {
    dispatch(setOwnCollections());
    dispatch(setDraftRecipes());
    dispatch(setDraftCollections());
    dispatch(setOwnRecipes());
  } else if (pageName === "library") {
    if (isSavedRecipesShow) {
      dispatch(setSavedRecipes());
      dispatch(setSavedCollections());
    } else if (isPurchasedRecipesShow) {
      dispatch(setPurchasedRecipes());
      dispatch(setPurchasedCollections());
    }
  } else if (pageName === "users") {
    return Promise.all([dispatch(setUserRecipes({ id: user.id }))]);
  }
});

export const getProductsData = createAsyncThunk("GET_PRODUCTS_DATA", (id, { dispatch }) => {
  dispatch(clearFilter());
  dispatch(resetPagination());
  dispatch(clearSorting());
});

export const getCreatorCollectionsAndRecipeData = createAsyncThunk("GET_PRODUCTS_DATA", (id, { dispatch }) => {
  dispatch(clearUserCollectionsAndRecipes());
  dispatch(setCreatorCollections({ id })).then(() => {
    dispatch(setCreatorRecipesFirstPage({ id }));
  });
});

export const deleteOwnRecipe = createAsyncThunk("DELETE_OWN_RECIPE", (id, thunkAPI) =>
  deleteRecipe(id).then(() => {
    thunkAPI.dispatch(createAlert({ type: "success", message: "Recipe was deleted" }));
    return id;
  })
);

export const deleteOwnCollection = createAsyncThunk("DELETE_OWN_COLLECTION", (id, thunkAPI) =>
  deleteCollection(id).then(() => {
    thunkAPI.dispatch(createAlert({ type: "success", message: "Recipe was deleted" }));
    return id;
  })
);

export const saveRecipeOrCollection = createAsyncThunk(
  "SAVE_RECIPE_OR_COLLECTION",
  ({ type, id, setSaved }, { rejectWithValue, dispatch }) =>
    saveRecipe({ recipes: null, [type]: id })
      .then((res) => {
        dispatch(setSaved(id));
        return res.data;
      })
      .catch((err) => {
        dispatch(createAlert({ type: "error", message: "Something wrong" }));
        return rejectWithValue(err);
      })
);

export const unSaveRecipeOrCollection = createAsyncThunk(
  "UNSAVE_RECIPE_OR_COLLECTION",
  ({ type, id, setSaved }, { rejectWithValue, dispatch }) =>
    unSave({ recipes: null, [type]: id })
      .then((res) => {
        dispatch(setSaved(id));
        return res.data;
      })
      .catch((err) => {
        dispatch(createAlert({ type: "error", message: "Something wrong" }));
        return rejectWithValue(err);
      })
);

export const buyRecipe = createAsyncThunk("BUY_RECIPE", ({ id, payment_intent_id }, thunkAPI) => {
  return purshaseRecipeConfirm({ recipe: id, payment_intent_id })
    .then((res) => {
      thunkAPI.dispatch(setPurchaseRecipe());
      return { id: res.data.id };
    })
    .catch((err) => {
      return thunkAPI.rejectWithValue(err);
    });
});

export const buyCollection = createAsyncThunk("BUY_COLLECTION", ({ id, payment_intent_id }, thunkAPI) => {
  return purshaseCollectionConfirm({ collection: id, payment_intent_id }).then((res) => {
    sessionStorage.removeItem("paidInCollections");
    thunkAPI.dispatch(setPurchaseCollection());
    return { id: res.data.id };
  });
});

export const messageForm = createAsyncThunk("MESSAGE_REGISTRATION", (id, thunkAPI) => {
  thunkAPI.dispatch(
    createAlert({
      type: "success",
      message2: `A verification link has been sent to your email account`,
      message: `Please click on the link that has just been sent to your email to verify your account and continue setting up process`,
    })
  );
  return id;
});
