import styled from "@emotion/styled";
import { colors } from "styles/colors";
import { fonts } from "styles/fonts";

export const Container = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  flex-grow: 1;
  @media (min-width: 769px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  max-width: ${(props) => (props.width ? props.width : "1304px")};
`;
export const Row = styled.div`
  @media (min-width: 769px) {
    display: flex;
  }
  margin-left: -15px;
  margin-right: -15px;
`;
export const Column = styled.div`
  padding-left: 15px;
  padding-right: 15px;
`;
export const List = styled.ul`
  list-style-type: none;
`;

export const SearchButton = styled.a`
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 2px solid ${(props) => props.bdButton};
  padding: 3px 10px;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 5px;
  font-weight: bold;
  overflow: visible;
  text-decoration: none;
  cursor: pointer;
  background-color: ${(props) => props.bgButton};
  color: ${(props) => props.color};
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  @media (min-width: 769px) {
    padding: 0.375rem 0.75rem;
  }
`;

export const FormContainer = styled.div`
  padding-top: 1px;
`;

export const FormInnerForgot = styled.div`
  width: 100%;
  margin: 31px auto 0;
  @media (min-width: 769px) {
    margin: 41px auto 0;
  }
  &:first-of-type {
    margin-top: 0;
  }
`;

export const FormInner = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 31px auto 0;
  @media (min-width: 769px) {
    margin: 41px auto 0;
  }
  &:first-of-type {
    margin-top: 0;
  }
`;

export const Deliver = styled.div`
  margin: 20px 0 20px;
  border-bottom: 1px solid ${(props) => props.color};
  @media (min-width: 769px) {
    margin: ${(props) => (props.spacing ? "20px 0 20px" : "30px 0 30px")};
  }
`;

export const DeliverType2 = styled.div`
  margin: ${(props) => (props.py ? "20px auto" : "10px auto")};
  max-width: 400px;
  width: 100%;
  border-bottom: 1px solid ${colors.GREY_LIGHT};
`;

export const BtnBlock = styled.div`
  margin: 20px auto 0;
  text-align: right;
  @media (min-width: 769px) {
    margin: 20px auto;
    text-align: center;
  }

  &.w100 {
    max-width: 400px;
    width: 100%;
    button {
      width: 100%;
      + button {
        margin-top: 20px;
      }
    }
  }
`;

export const Content = styled.div`
  @media (min-width: 1024px) {
    max-width: calc(100% - 217px);
  }
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
`;

export const ContentFull = styled.div`
  overflow: hidden;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
`;

export const Spinner = styled.div`
  display: block;
  margin: auto;
  height: 2em;
  width: 2em;
  border: 6px solid ${colors.RED_LIGHT};
  border-top-color: ${colors.RED};
  border-radius: 50%;
  animation: rotation 0.6s infinite linear;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
`;

export const NoImage = styled.div`
  position: relative;
  max-width: 480px;
  width: 100%;
  background-color: ${colors.GREY_LIGHT};
  color: #222424;
  font-family: ${fonts.latoBold};
  font-size: 24px;
  border-radius: 20px;

  @media (min-width: 450px) {
    margin-left: 0;
  }

  @media (max-width: 768px) {
    margin-right: 20px;
  }

  @media (min-width: 769px) {
    margin-right: 30px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    height: 127px;
    width: 127px;
    display: block;
    background: url("/images/noimage.png") no-repeat 0 0;
    background-size: cover;
  }

  &.buyer {
    max-width: 156px;
    height: 156px;
    font-size: 16px;

    @media (max-width: 768px) {
      max-width: 90px;
      height: 90px;
    }

    &:after {
      height: 57px;
      width: 57px;

      @media (max-width: 768px) {
        width: 40px;
        height: 40px;
      }
    }

    .icon-avatar {
      height: 40px;
      width: 40px;
      stroke: ${colors.GREY};
    }
  }
`;

export const CenterAbsolute = styled.div`
  z-index: 100;
  position: fixed;
  top: 50%;
  left: 50%;
`;
export const ErrorSettingsContainer = styled.div`
  width: 58%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
