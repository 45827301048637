export const recipesFilterOptions = ["all", "free", "paid", "private"];
export const recipesTextTabs = ["All", "Free", "Paid Single", "Paid in Collection"];
export const collectionsFilterOptions = ["all", "free", "private"];
export const collectionTextTabs = ["All", "Free", "Paid"];

const initialRecipesFilterValue = recipesFilterOptions[0];
const initialCollectionsFilterValue = collectionsFilterOptions[0];
export const initialFiltersValues = {
  recipesFilter: initialRecipesFilterValue,
  collectionsFilter: initialCollectionsFilterValue,
};
