export const getRecipesFilterName = (state) => state.recipesReducer.filters.recipesFilter;

export const getCollectionsFilterName = (state) => state.recipesReducer.filters.collectionsFilter;

export const getCollections = (state) => state.recipesReducer.collections;

export const isCollectionsLoading = (state) => state.recipesReducer.collectionsLoading;

export const getRecipes = (state) => state.recipesReducer.recipes;

export const isRecipesLoading = (state) => state.recipesReducer.recipesLoading;

export const getRecipesPage = (state) => state.recipesReducer.pagination.recipes;
export const getCollectionsPage = (state) => state.recipesReducer.pagination.collections;

export const getSearchValue = (state) => state.recipesReducer.search;

export const getRecipesSorting = (state) => state.recipesReducer.sorting.recipes;
export const getCollectionsSorting = (state) => state.recipesReducer.sorting.collections;

export const rerenderAdd = (state) => state.recipesReducer.rerenderAddRecipe;
export const congratsPage = (state) => state.recipesReducer.congratsPage;
export const getCardId = (state) => state.recipesReducer.iconLoading.card_id;
export const getBuyResult = (state) => state.recipesReducer.buyResult;
export const getBuyMessage = (state) => state.recipesReducer.buyMessage;
export const isPreviewDraft = (state) => state.recipesReducer.isPreviewDraft;
export const getDrafts = (state) => state.recipesReducer.drafts;
export const isDraftsLoading = (state) => state.recipesReducer.draftLoading;
export const getDraftsCollections = (state) => state.recipesReducer.draftsCollections;
export const isDraftsCollectionsLoading = (state) => state.recipesReducer.draftCollectionLoading;
export const isOpenForm = (state) => state.recipesReducer.openForm;
