import styled from "@emotion/styled";
import { colors } from "styles/colors";
import { fonts } from "styles/fonts";
import { ButtonWrap } from "shared/Button/styled";
import { InputWrap, WrapMessage as InputErr } from "shared/Input/styled";
import { TextFieldWrap, Label, WrapMessage } from "shared/TextField/styled";
import { IcoWrap, LabelStyled, IconText, InfoText } from "shared/InputPhoto/styled";
import { BtnBlock } from "styles/General.styled";
import { RecipeCard } from "components/RecipeSteps/Card/styled";
import { Minutes } from "shared/SwitchInput/styled";
import { Menu, MenuItem } from "shared/DropdownWithoutArrow/styled";
import { ItemBtnGroup } from "shared/BtnsGroup/styled";

export const AddIngredients = styled.div`
  border-top: 1px ${colors.GREY_SEMILIGHT} solid;
  border-bottom: 1px ${colors.GREY_SEMILIGHT} solid;
  height: 58px;
  display: flex;
  justify-content: center;

  ${ButtonWrap} {
    border: 0px !important;
    width: 100%;
    .icon {
      margin-left: 7px;
    }
  }
  @media (max-width: 769px) {
    height: 44px;
  }
`;

export const DraggbleBlock = styled.div``;

export const Title = styled.h3`
  margin-bottom: 15px;
  @media (min-width: 769px) {
    margin-bottom: 13px;
  }
`;

export const Container = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  max-width: 1304px;
  flex-grow: 1;
  @media (min-width: 769px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
export const Row = styled.div`
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
`;

export const Content = styled.div`
  @media (min-width: 769px) {
    max-width: calc(100% - 217px);
    margin: 0 auto;
  }
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  overflow: hidden;
`;

export const CardButtons = styled.div`
  width: 37px;
`;

export const CategoryTitle = styled.div`
  padding: 0 0 5px;

  margin-bottom: 16px;
  font-family: ${fonts.latoBold};
  border-bottom: 1px solid ${colors.GREY_SEMILIGHT};
  @media (min-width: 769px) {
    margin-bottom: 22px;
  }
  &.with-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .icon {
    stroke: ${colors.GREY_LIGHT};
    margin-right: 15px;
  }

  span {
    font-size: 18px;
  }
`;

export const StepTitleType1 = styled.div`
  padding: 0 0 5px;
  margin-top: 15px;
  margin-bottom: 19px;
  font-family: ${fonts.latoBold};
  border-bottom: 1px solid ${colors.GREY_SEMILIGHT};
  @media (min-width: 769px) {
    margin-top: 28px;
    margin-bottom: 20px;
  }
  &.with-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .icon {
    stroke: ${colors.GREY_LIGHT};
    margin-right: 15px;
  }

  span {
    font-size: 18px;
  }
`;

export const MainTitle = styled.h1`
  width: 100%;
  line-height: 1.3;
  font-size: 36px;
  font-family: ${fonts.abhayaBold};
  color: ${colors.GREY_DARK};
`;

export const StepBlockStyled = styled.div`
  position: relative;
  margin-bottom: 20px;
  white-space: nowrap;
  cursor: pointer;
  &:after {
    content: "";
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(242, 243, 245);
  }

  .slick-slider {
    position: relative;
    z-index: 1;
  }
  @media (min-width: 769px) {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 30px;
  }
  @media (max-width: 800px) {
    &.step2 {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
`;

export const IconClose = styled.div`
  display: flex;

  > .icon {
    width: 22px;
    height: 22px;
    margin-left: 12px;
    fill: ${colors.RED};
    cursor: pointer;
  }
`;

export const ErrorText = styled.div`
  flex: 1;
`;
export const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  line-height: 1;
  font-family: ${fonts.latoBold};
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    stroke: none;
  }
`;
export const ErrorWrap = styled.div`
  flex: 1;
  ${ErrorMessage} + ${ErrorMessage} {
    margin-top: 5px;
  }
`;
export const Step = styled.h2`
  position: relative;
  padding: 14px 18px 15px;
  margin-bottom: 0;
  font-size: 1rem;
  color: ${colors.GREY_DARK};
  font-family: ${fonts.latoRegular};
  outline: none;
  color: ${colors.GREY_SEMIDARK};

  &:after {
    content: "";
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${colors.GREY_LIGHT};
    z-index: 1;
  }
  &.active {
    color: ${colors.RED};
    cursor: pointer;
    &:after {
      background-color: ${colors.RED};
    }
  }
  &.error {
    .icon {
      stroke: none;
      height: 13px;
      width: 13px;
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
    }
  }
  @media (min-width: 769px) {
    padding: 13px 18px 14px;
  }
`;

export const TitleWrap = styled.div`
  margin: 30px 0 11px;
  white-space: nowrap;

  @media (min-width: 769px) {
    ${(props) =>
      props.spacing
        ? `
      margin: 30px 0;
      ${CategoryTitle}{
        margin-bottom: 0;
      }
      `
        : `
      margin: 29px 0 11px;
      `};
  }
`;

export const Time = styled.div`
  color: ${colors.GREY_DARK};
  &.totalTime {
    padding-right: 10px;
  }
  &.text-center {
    text-align: center;

    @media (min-width: 769px) {
      text-align: left;
      font-size: 16px;
    }
  }
  @media (min-width: 769px) {
    padding-right: 10px;
    margin-top: -1px;
  }
`;

export const HelpDescription = styled.div`
  font-size: 16px;
  color: ${colors.GREY_DARK};
  margin-bottom: 5px;
  @media (min-width: 769px) {
    margin-bottom: 29px;
  }
`;
export const HelpVideo = styled.a`
  text-decoration: underline;
  color: ${colors.RED};
`;

export const QTY = styled.div`
  position: relative;
  max-width: ${(props) => (props.width ? "79px;" : "60px")};
  flex: ${(props) => (props.width ? "0 0 79px;" : "0 0 60px")};
  &:after {
    position: absolute;
    top: 8px;
    right: 0;
    height: 50%;
    border-left: 1px solid ${colors.GREY_SEMILIGHT};
    content: "";
  }
  ${InputWrap} {
    input {
      @media (min-width: 769px) {
        padding-left: 0;
      }
    }
  }
`;
export const Unit = styled.div`
  position: relative;
  ${InputErr} {
    position: static;
    margin-top: -10px !important;
    margin-bottom: 5px;
  }
  input {
    padding-left: 0;
  }
  @media (min-width: 1024px) {
    max-width: 126px;
    width: 100%;
    &:after {
      position: absolute;
      top: 8px;
      right: 0;
      height: 50%;
      border-left: 1px solid ${colors.GREY_SEMILIGHT};
      content: "";
    }
    ${Menu} {
      min-height: 95px;
      width: 212px;
      max-height: 200px;
    }
  }
`;

export const Ingredient = styled.div`
  position: relative;

  @media (min-width: 1024px) {
    max-width: 170px;
    flex: 0 0 170px;
    width: 100%;
    &:after {
      position: absolute;
      top: 8px;
      right: 0;
      height: 50%;
      border-left: 1px solid ${colors.GREY_SEMILIGHT};
      content: "";
    }
  }
  ${InputErr} {
    position: static;
    margin-top: -10px !important;
    margin-bottom: 5px;
  }

  @media (max-width: 769px) {
    padding-left: 13px;
  }
`;

export const Notes = styled.div`
  ${Label} {
    display: none;
  }
  ${WrapMessage} {
    position: static;
    margin-top: -5px;
    margin-bottom: 5px;
  }
  textarea {
    padding-top: 12px;
    padding-bottom: 10px;
    height: 46px;
    min-height: 46px;
    padding-right: 0 !important;
    resize: none !important;
    padding-left: 0 !important;
  }
  @media (max-width: 769px) {
    padding-left: 13px;
  }
`;
export const TableWrap = styled.div`
  display: none;
  margin-top: 25px;
  flex-direction: row;
  margin-bottom: 5px;
  padding: 0 2px;
  @media (min-width: 813px) {
    display: flex;
  }
`;
export const IngredientWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;
  height: 100%;

  @media (min-width: 1024px) {
    align-items: flex-start;
  }

  ${BtnBlock} {
    button {
      letter-spacing: 0.05em;
    }
  }
  ${Unit}, ${Ingredient}, ${Notes} {
    @media (min-width: 1024px) {
      margin-left: 12px;
    }
  }
  @media (max-width: 1023px) {
    > div {
      width: 100%;
      input {
        padding: 12px;
      }
      + div {
        margin: 10px 0 0;
      }
    }
    ${Unit} {
      margin-left: 10px;
      flex: 1;
    }
    ${BtnBlock} {
      margin-top: 0;
    }
  }
  @media (min-width: 1024px) {
    flex-wrap: nowrap;
  }
`;

export const RowWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: ${(props) => (props.dir ? "center" : "flex-start")};
  border-top: 1px ${colors.GREY_SEMILIGHT} solid;
  textarea {
    resize: none;
  }

  ${(props) => props.spacing && "padding-left: 12px"};

  @media (min-width: 768px) {
    ${(props) => props.spacing && "padding-left: 0"};
  }

  @media (min-width: 1024px) {
    min-height: 58px;
  }

  ${CardButtons} {
    @media (max-width: 768px) {
      flex-direction: row-reverse;
      button + button {
        margin-right: 30px;
        margin-left: 0;
      }
    }
  }

  ${CardButtons} {
    button + button {
      margin-left: 24px;
    }
  }
`;

export const DirectionTitle = styled.div`
  flex-grow: 1;
  input[value=""] {
    text-shadow: 0 0 0 ${colors.GREY};
    &:focus {
      outline: none;
      border: 1px solid ${colors.GREY_SEMILIGHT};
    }
  }
`;

export const TotalTimeInput = styled.input`
  padding-left: 7px;
  font-family: ${fonts.robotoMedium};
  font-size: 16px;
  width: 100%;
  height: 46px;
  border: ${(props) => (props.error ? `2px solid ${colors.RED}` : "transparent")};
  border-radius: 5px;
`;

export const ButtonIcon = styled.button`
  position: relative;
  width: 37px;
  height: 37px;
  border: none;
  padding: 5px;
  background-color: transparent;
  outline: none;
  .icon {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    fill: ${colors.GREY_SEMIDARK};
    cursor: pointer;
  }
  .icon-remove,
  .icon-delete {
    fill: ${colors.RED};
  }
  .icon-drag {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }
  @media (min-width: 769px) {
    width: 42px;
    height: 42px;
  }
`;

export const InputField = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 58px;

  ${InputWrap} {
    flex-grow: 1;
    ${InputErr} {
      padding-left: 13px;
      margin-top: 0;
      top: 80%;
    }
    input {
      font-size: 14px;
      font-family: ${fonts.latoBold};
    }
    @media (min-width: 769px) {
      ${InputErr} {
        padding-left: 0;
      }
      input {
        font-size: 20px;
        padding-left: 0;
        font-family: ${fonts.latoBold};
      }
    }
  }
`;

export const DropbleBlock = styled.div``;

/* Styling Step Content */
export const StepContent = styled.div`
  margin: 0 0 10px;
  line-height: 1.4;
  @media (min-width: 769px) {
    margin: 0 0 14px;
  }

  ${(props) =>
    props.isColum === "column3" &&
    `
    flex-wrap: wrap;
    @media (min-width: 769px) {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;     
    }
  `};
`;

/* Styling Tabs */
export const Tabs = styled.div``;
export const TabLinks = styled.div`
  margin-bottom: 20px;
  display: flex;
  border-bottom: 1px solid ${colors.GREY_LIGHT};
  button + button {
    margin-left: 30px;
  }
  @media (min-width: 769px) {
    margin-bottom: 29px;
    button {
      margin: 0;
    }
  }
`;

export const TabLink = styled.button`
  padding: 10px 19px 16px;
  margin-bottom: -1px;
  border: none;
  border-bottom: 1px solid ${colors.GREY_LIGHT};
  background-color: transparent;
  transition: color, border-color 0.3s ease-in-out;
  ${(props) =>
    props.isActive
      ? ` 
      pointer-events: none;
      cursor: default;
      color: ${colors.RED};
      border-color: ${colors.RED}; 
    `
      : `   
      cursor: pointer;      
    `};
  .icon {
    margin-bottom: -3px;
    margin-right: 12px;
    transition: stroke fill 0.3s ease-in-out;
    ${(props) =>
      props.isActive
        ? ` 
        fill: ${colors.RED};
        stroke:${colors.RED};
    `
        : `   
      fill: ${colors.GREY_DARK};
      stroke:${colors.GREY_DARK};
    `};
  }
  @media (min-width: 769px) {
    padding: 16px 17px 16px;
  }
  outline: none;
`;

export const Times = styled.div`
  margin-top: 29px;

  @media (min-width: 769px) {
    margin-top: 0;
  }
  ${CategoryTitle} {
    margin-bottom: 20px;
    @media (min-width: 769px) {
      padding-bottom: 8px;
      margin-bottom: 24px;
    }
  }
`;
export const YieldNumber = styled.div`
  flex: 98px;
  max-width: 98px;
  @media (min-width: 769px) {
    flex: 121px;
    max-width: 121px;
  }
`;
export const YieldCategory = styled.div`
  flex-grow: 1;
  position: relative;
  input {
    z-index: 1;
    position: relative;
  }
  &:after {
    content: "";
    position: absolute;
    top: 21px;
    right: 17px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: ${colors.GREY_DARK} transparent transparent transparent;
    z-index: 0;

    @media (min-width: 769px) {
      display: none;
    }
  }

  ${MenuItem} {
    text-transform: lowercase;
  }

  [name*="yield_category"] {
    text-transform: lowercase;
  }
`;
export const Yield = styled.div`
  margin-top: 9px;
  @media (min-width: 769px) {
    margin-top: 0;
  }
  ${CategoryTitle} {
    margin-bottom: 21px;
    @media (min-width: 769px) {
      padding-bottom: 6px;
      margin-bottom: 24px;
    }
  }
`;

export const TotalTimeValue = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 46px;
  border-radius: 5px;
  border: 1px solid ${(props) => (props.error ? colors.RED : "transparent")};
  input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
  }
  input + ${Minutes} {
    display: none;
  }
  input + div {
    padding-left: 14px;
  }
  input + div + div {
    padding-left: 3px;
  }
  input + div + div + div {
    padding-left: 3px;
  }
  * {
    font-family: ${fonts.latoBold};
  }
`;

export const TotalTime = styled.div``;

export const StepWrap = styled.div`
  width: 100%;

  ${InputWrap} {
    position: relative;
    + * {
      margin-top: 0;
    }
  }

  input {
    padding: 10px 13px;
  }
  ${TotalTimeInput} {
    padding-left: 0;
  }

  ${TextFieldWrap} {
    textarea {
      font-family: ${fonts.latoRegular};
      &.direction {
        height: 46px;
        min-height: 46px;
      }
    }
    textarea[name="description"],
    textarea[name="non_field_errors"] {
      height: 46px;
      @media (min-width: 769px) {
        height: 328px;
      }
      @media (min-width: 813px) {
        height: 400px;
      }
    }
  }

  @media (min-width: 769px) {
    max-width: 832px;
  }
`;
export const TabContent = styled.div`
  ${RecipeCard} + ${RecipeCard} {
    margin-top: 20px;
  }
  ${DropbleBlock} {
    + ${DropbleBlock} {
      margin-top: 20px;
      border-top: 1px solid ${colors.GREY_LIGHT};
    }

    ${DraggbleBlock} + ${DraggbleBlock} {
      padding-top: 15px;
      margin-top: 20px;
      border-top: 1px solid ${colors.GREY_LIGHT};
      @media (min-width: 560px) {
        margin-top: 20px;
        padding-top: 20px;
      }
    }
  }
  @media (min-width: 769px) {
    ${RecipeCard} + ${RecipeCard} {
      margin-top: 9px;
    }
    ${RecipeCard} + ${CategoryTitle} {
      margin-top: 0;
    }
  }
`;

export const StepWrap2 = styled.div`
  width: 100%;
  margin-top: 16px;
  ${InputWrap} {
    margin-bottom: 19px;
  }
  @media (min-width: 769px) {
    margin-top: 0;
    max-width: 832px;
    ${InputWrap} {
      margin-bottom: 29px;
    }
  }
`;
export const Text = styled.div`
  font-size: 16px;
  @media (min-width: 769px) {
    font-size: ${(props) => props.fontSize}px;
  }
  font-family: ${(props) => (props.fontFamily ? props.fontFamily : fonts.latoRegular)};
`;

export const TextType1 = styled(Text)``;

export const HeadItem = styled.div`
  margin-right: auto;
  width: 100%;
  @media (min-width: 769px) {
    margin-top: 5px;
    width: auto;
  }
`;

export const HeadBlockType1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3px;
  padding-bottom: 15px;
  border-bottom: 1px solid ${colors.GREY_SEMILIGHT};
  ${ItemBtnGroup} {
    padding: 0;
  }
  ${ItemBtnGroup} + ${ItemBtnGroup} {
    margin-left: 10px;
  }
  ${MainTitle} {
    margin-bottom: 0;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    > div + div {
      padding-top: 13px;
    }
  }
  @media (min-width: 769px) {
    padding-bottom: 7px;
    border-bottom: none;
    > div + div {
      padding-left: 10px;
    }
  }
`;
/* Style BtnsBottom */
export const ItemBtn = styled.div`
  padding: 0 8px;
  ${(props) => (props.buttonDraft ? "width: 100%; order: 3;" : props.fullWidth ? "width: 100%" : "width: 50%")};

  @media (min-width: 769px) {
    width: auto;
    order: 0;

    ${(props) => props.mlAuto && "margin-left: auto !important;"};
  }
  &:only-child {
    width: 100%;
    @media (min-width: 769px) {
      width: auto;
      margin-right: 0;
      margin-left: auto;
    }
  }
`;
/* Style BtnsBottom */
export const BtnsBottom = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  margin: 20px -8px 0;
  position: relative;
  &:before {
    content: "";
    height: 1px;
    position: absolute;
    top: 0;
    left: 8px;
    right: 8px;
    background-color: ${colors.GREY_LIGHT};
  }

  ${ItemBtn} {
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    @media (min-width: 769px) {
      width: auto;
      flex-direction: row;
    }
  }

  ${ItemBtn} + ${ItemBtn} {
  }

  ${ButtonWrap} {
    width: 100%;
    font-size: 16px;
    padding: 10px 12px 9px;

    @media (min-width: 769px) {
      min-width: 112px;
      width: auto;
      padding: 11px 12px;
    }
  }
  + div {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
`;

export const BtnsBottomType1 = styled(BtnsBottom)`
  @media (min-width: 769px) {
    margin-top: 30px;
    ${ItemBtn} {
      &:only-child {
        margin-right: auto;
        margin-left: auto;
      }
    }
  }
`;

export const ImageWrap = styled.div`
  position: relative;
  border: ${(props) => (props.error ? `1px solid ${colors.RED}` : `1px dashed ${colors.GREY_SEMILIGHT}`)};
  width: 100%;
  height: 328px;
  border-radius: 10px;
  margin: 0 auto;
  cursor: pointer;
  @media (min-width: 813px) {
    height: 400px;
    margin: 0;
  }
  ${IcoWrap} {
    margin-top: 0;
    margin-bottom: 0;
    @media (min-width: 769px) {
      margin-top: 0;
    }
  }
  ${LabelStyled} {
    @media (max-width: 768px) {
      padding: 8px 9px 7px;
      position: absolute;
      height: 100%;
      width: 100%;
      font-size: 0;
      background-color: transparent;
      border: none;
    }
  }
`;

export const ServingWrap = styled.div`
  width: 100%;
  display: flex;

  > div + div {
    margin-left: 12px;
  }
`;
/* Style Added Ingridient */
export const ItemWrap = styled.div``;
export const BtnsIcon = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  margin-top: -5px;
  ${ButtonIcon} + ${ButtonIcon} {
    margin-top: 10px;
  }
`;
export const ItemIngredient = styled.div``;
export const IngredientData = styled.div`
  color: ${colors.GREY};
  font-family: ${fonts.robotoMedium};
`;
export const AddedIngredient = styled.div`
  margin-top: 32px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 23px 14px 17px 25px;
  border: 2px solid ${colors.GREY_SEMILIGHT};
  border-radius: 5px;
`;
export const IngredientBlock = styled.div`
  margin: 0 -15px 20px;
  display: flex;

  flex-direction: column;
  input,
  input:focus,
  textarea,
  textarea:focus {
    border: 0px !important;
  }
  ${AddIngredients} {
    @media (max-width: 768px) {
      ${ButtonWrap} {
        max-width: 273px;
      }
    }
  }
  ${AddedIngredient} + ${RowWrap} {
    margin-top: 10px;
  }

  ${InputWrap} {
    position: relative;

    + * {
      margin-top: 0;
    }
  }
  ${QTY} + ${QTY} {
    margin-left: 10px;
    @media (min-width: 1024px) {
      margin-left: 12px;
    }
  }
  ${QTY}, ${Unit}, ${Ingredient}, ${Notes} {
    font-size: 14px;
  }
  ${Notes} {
    textarea,
    input {
      @media (max-width: 812px) {
        border-radius: 5px !important;
      }
    }
    @media (min-width: 1024px) {
      margin-top: 0;
      max-width: 267px;
      flex: 0 0 267px;
    }
  }
  ${InputField} + ${DropbleBlock} {
  }
  @media (min-width: 769px) {
    margin: 0 0 11px;
    padding: 0;
  }
`;
export const DirectionsStyled = styled.div`
  ${IngredientBlock} {
    border-radius: 10px;
    margin-bottom: 10px;
    border: 1px solid ${colors.GREY_SEMILIGHT};
    @media (min-width: 769px) {
      margin-bottom: 30px;
    }
  }
  ${CardButtons} {
    margin-top: -2px;
  }
`;
export const ButtonsWrap = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
`;

export const StepTags = styled.div`
  ${Title} {
    margin-top: 28px;
    margin-bottom: 11px;
    @media (min-width: 769px) {
      margin-top: 22px;
      margin-bottom: 9px;
    }
  }
`;
export const PaidRecipe = styled.div`
  padding-top: 8px;
  padding-bottom: 14px;
  margin-bottom: 18px;
  border-top: 1px solid ${colors.GREY_LIGHT};
  border-bottom: 1px solid ${colors.GREY_LIGHT};

  @media (min-width: 769px) {
    margin-top: 31px;
    margin-bottom: 27px;
    padding-top: 0;
    padding-bottom: 29px;
    border-top: 0;
  }
  ${Text} {
    margin: 12px 0;
    @media (min-width: 769px) {
      margin: 0;
    }
  }
  ${TextType1} {
    + div {
      margin-right: 20px !important;
      @media (min-width: 769px) {
        margin: 0;
      }
    }
  }
`;
export const VideoWrap = styled.div`
  position: relative;
  margin-top: 29px;
  height: 328px;
  border: 2px dotted ${colors.GREY_SEMILIGHT};
  border-radius: 5px;
  text-align: center;
  letter-spacing: 0.01em;

  input[name="video"] + {
    span {
      padding: 0;
    }
  }
  @media (min-width: 769px) {
    margin: 0;
    height: 438px;
  }
  ${IcoWrap} {
    max-width: 100%;
    padding: 0 10px;
    margin-top: 23px;
    top: 50%;
    .icon {
      margin-bottom: 22px;
    }
    @media (min-width: 769px) {
      top: 208px;
      margin-top: 0;
      .icon {
        margin-bottom: 24px;
      }
    }
  }
  ${IconText},
  ${InfoText} {
    max-width: 470px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  ${IconText} + ${InfoText} {
    margin-top: 20px;
    color: ${colors.GREY};
  }
  ${IconText} {
    padding: 0;
    letter-spacing: 0.009em;
  }
  ${InfoText} {
    color: ${colors.GREY};
  }
  :hover {
    ${LabelStyled} {
      opacity: 1;
    }
  }
`;

export const ErrorIngredient = styled.input`
  border: none;
  color: ${colors.RED};
  width: 100%;
  outline: none;
  padding: 0px !important;
  font-size: 12px;
  input:focus {
    border: none;
    outline: none;
  }
`;

export const DescriptionCount = styled.div`
  margin-top: 5px;
  text-align: right;
  color: ${(props) => (props.err > 2200 ? colors.RED : colors.GREY_SEMIDARK)};
  font-size: 14px;
`;

export const MinutesWraper = styled.div`
  display: flex;
  div + div {
    padding-left: 3px;
  }
`;

export const DirectionsContainer = styled.div`
  ${TitleWrap} {
    margin-top: 19px;
    @media (min-width: 769px) {
      margin-top: 30px;
    }
  }
  ${TitleWrap},
`;

export const IconContainer = styled.div`
  width: 42px;
`;

export const IngredientWrapTitle = styled.div`
  margin: 20px -15px 0px;
  white-space: nowrap;
  ${CategoryTitle} {
    margin-bottom: 0;
    @media (max-width: 769px) {
      padding-left: 12px;
    }
  }
  @media (min-width: 769px) {
    margin: 30px 0 0;
  }
`;

export const DirNumber = styled.div`
  font-size: 16px;
  font-family: ${fonts.latoRegular};
  color: ${colors.GREY};
  padding-right: 10px;
  @media (max-width: 769px) {
    padding-left: 12px;
  }
`;
