import { HYDRATE } from "next-redux-wrapper";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isShowModal: false,
  isLogin: false,
};

const loginReducer = createSlice({
  name: "loginReducer",
  initialState,
  reducers: {
    setIsLogin: (state, action) => {
      state.isLogin = action.payload;
    },
    toggleModal: (state, action) => {
      state.isShowModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action) => {
      state.isShowModal = action.payload.loginReducer.isShowModal;
      state.isLogin = action.payload.loginReducer.isLogin;
    });
  },
});

export default loginReducer.reducer;
export const { setIsLogin, toggleModal } = loginReducer.actions;

export const isUserLogin = (state) => state.loginReducer.isLogin;
export const isModalShows = (state) => state.loginReducer.isShowModal;
