import React, { useState } from "react";
import Router from "next/router";
import { useForm } from "react-hook-form";
import { toggleModal, setIsLogin } from "store/loginReducer";
import { validations } from "validations/registration";
import { Icon } from "components/SvgIcons";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "react-google-login";
import Button from "shared/Button";
import Dialog from "shared/Dialog";
import Input from "shared/Input";
import { colors } from "styles/colors";
import { Container, FormContainer, Spinner } from "styles/App.styled";
import { BtnBlock, ForgotPassword, ButtonWrap, IconWrap, TitleWrap, InputWrap, TextInfo } from "./styled";
import { useDispatch } from "react-redux";
import { appLogin, googleLogin, setToken, faceBookLogin, getCurrentUser, sendToDataLayer } from "api/api";
import { TitleDeliver } from "styles/General.styled";

import { isSafari } from "../../helpers/detectBrowser";
import { setNewOwner } from "store/userReducer";
import { useSelector } from "react-redux";
import { clearErrors, setServerErrors, getServerErrors } from "store/alert";

const LoginForm = ({ setLogin }) => {
  const { register, handleSubmit, errors } = useForm();
  const serverErrors = useSelector(getServerErrors);
  const [isShowDialog, setDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(toggleModal(false));
  };

  const socialResponce = (res) => {
    setToken(res.headers);
    getCurrentUser()
      .then((resp) => {
        dispatch(setNewOwner(resp.data));
        dispatch(setIsLogin(true));
        dispatch(toggleModal(false));
        dispatch(clearErrors());
        setDialog(false);
        setLoading(false);
        if (res.headers.has_role === "True") Router.push("/");
        else {
          localStorage.setItem("isSocialLogin", true);
          Router.push({
            pathname: "/registration",
            query: { socials: false },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const isErrorExist = (err) => {
    return Object.keys(errors).includes(err);
  };

  const isServerErrorExist = (err) => {
    return Object.keys(serverErrors).includes(err);
  };

  const onSubmit = async (data) => {
    appLogin({ ...data, email: data.email?.trim() })
      .then((res) => {
        dispatch(clearErrors());
        setToken(res.headers);
        sendToDataLayer({ event: "formSubmitSuccess" });
        getCurrentUser()
          .then(async (resp) => {
            dispatch(setNewOwner(resp.data));
            dispatch(setIsLogin(true));
            dispatch(toggleModal(false));

            if (res.headers.first_login === "True") {
              if (resp.data.is_creator) Router.push("/registration/set-up-account");
              else Router.push("/");
            }
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        dispatch(setServerErrors(err.response.data));
        setDialog(true);
      });
  };
  const toSignUp = () => {
    Router.push({
      pathname: "/registration",
      query: { socials: false },
    });
    dispatch(toggleModal(false));
  };

  const responseFacebook = async (response) => {
    try {
      const res = await faceBookLogin({ access_token: response.accessToken });
      localStorage.setItem("social_login", "FB");
      socialResponce(res);
      sendToDataLayer({
        loginSuccess: "SuccessFacebookAuth",
        event: "SuccessFacebookAuth",
      });
    } catch (err) {
      err.response && dispatch(setServerErrors(err.response.data));
      setDialog(true);
    }
  };

  const responseGoogle = async (response) => {
    try {
      setLoading(true);
      const res = await googleLogin({ access_token: response.accessToken });
      localStorage.setItem("social_login", "GA");
      socialResponce(res);
      sendToDataLayer({
        loginSuccess: "SuccessGoogleAuth",
        event: "SuccessGoogleAuth",
      });
    } catch (err) {
      err.response && dispatch(setServerErrors(err.response.data));
      setLoading(false);
      setDialog(true);
    }
  };

  const onFailure = (err) => {
    if (err.error === "idpiframe_initialization_failed") {
      setDialog(true);
      dispatch(setServerErrors({ err: [err.details] }));
      return;
    }
    setDialog(true);
    if (err.status === "unknown") dispatch(setServerErrors({ err: ["Something went wrong"] }));
    else if (err.error === "popup_closed_by_user" && isSafari())
      dispatch(setServerErrors({ err: ["Enable popus in your Safari browser"] }));
    else dispatch(setServerErrors({ err: [err.error] }));
  };

  return (
    <Container md>
      <TitleWrap>
        <TitleDeliver spacing3>Log In</TitleDeliver>
        <IconWrap onClick={closeModal}>
          <Icon icon="close_2" />
        </IconWrap>
      </TitleWrap>

      {isLoading ? (
        <Spinner />
      ) : (
        <FormContainer>
          <form onSubmit={handleSubmit(onSubmit)}>
            {isShowDialog && (
              <Dialog
                state={serverErrors}
                type="error"
                onClose={() => {
                  setDialog(false);
                }}
              />
            )}
            <InputWrap>
              <Input
                reff={register(validations.email)}
                name="email"
                text="Email"
                errorMessage={isServerErrorExist("email") ? "" : validations.firstName.message}
                error={isErrorExist("email") || isServerErrorExist("email")}
              />
            </InputWrap>
            <InputWrap>
              <Input
                reff={register(validations.password)}
                name="password"
                text="Password"
                type="password"
                error={isErrorExist("password") || isServerErrorExist("password")}
              />
            </InputWrap>
            <BtnBlock className="w100">
              <Button stylType="primary" color={colors.RED} text="Log In" />
            </BtnBlock>
            <ForgotPassword onClick={() => setLogin(false)}>Forgot Password?</ForgotPassword>
          </form>
          <ButtonWrap className="w100">
            <GoogleLogin
              clientId={process.env.NEXT_PUBLIC_REACT_APP_GOOGLE_ID}
              render={(renderProps) => (
                <Button onClick={renderProps.onClick} stylType="secondary" text="Continue with Google" />
              )}
              onSuccess={responseGoogle}
              onFailure={onFailure}
              isSignedIn={false}
              cookiePolicy={"single_host_origin"}
            />
            <FacebookLogin
              appId={process.env.NEXT_PUBLIC_REACT_APP_FACEBOOK_ID}
              autoLoad={false}
              onFailure={onFailure}
              fields="name,email,picture"
              disableMobileRedirect={true}
              callback={responseFacebook}
              render={(renderProps) => (
                <Button
                  stylType="secondary"
                  text="Continue with Facebook"
                  onClick={(e) => {
                    e.preventDefault();
                    renderProps.onClick();
                  }}
                />
              )}
            />
          </ButtonWrap>
          <TextInfo>Don’t have account yet?</TextInfo>
          <BtnBlock className="w100">
            <Button stylType="primary" color={colors.RED} text="Sign Up" onClick={toSignUp} />
          </BtnBlock>
        </FormContainer>
      )}
    </Container>
  );
};

export default LoginForm;
