import styled from "@emotion/styled";
import { colors } from "styles/colors";

export const Menu = styled.div`
  position: absolute;
  top: 100%;
  width: 100%;
  margin-top: 3px;
  max-height: 238px;
  min-width: 112px;
  @media (min-width: 769px) {
    max-height: 400px;
  }
  border-radius: 5px;
  overflow: auto;
  background-color: ${colors.WHITE};
  border: 1px solid ${colors.GREY_SEMILIGHT};
  z-index: 10;
  height: inherit;
  overflow: hidden;
  overflow-y: auto !important;
  box-shadow: 4px 4px 26px rgba(179, 186, 186, 0.3);
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.8);
  }
`;

export const MenuItem = styled.div`
  padding: 11px 12px;
  color: ${colors.GREY_DARK};
  &:hover {
    color: ${colors.RED};
    background-color: ${colors.BLUE_300};
    cursor: pointer;
  }
`;

export const Input = styled.input`
  border: 1px solid ${(props) => (props.error ? colors.RED : colors.GREY_SEMILIGHT)};
  border-radius: 5px;
  &:focus {
    outline: 0;
    border: 1px solid ${(props) => (props.error ? colors.RED : colors.GREY_SEMILIGHT)};
  }
`;

export const IconWrap = styled.label`
  position: absolute;
  right: 0;
  top: 0;
  width: 36px;
  height: 46px;
  .icon {
    margin-top: 15px;
    margin-left: 6px;
  }
`;

export const DropdownWrap = styled.div`
  position: relative;
  > input {
    color: ${colors.GREY_DARK};
    position: relative;
    width: 100%;
    height: 46px;
    background-color: transparent;
    outline: none;
    box-shadow: none;
  }
  ${MenuItem} + ${MenuItem} {
    border-top: 1px solid ${colors.GREY_SEMILIGHT};
  }
  ${(props) =>
    props.isIcon
      ? `
  input{
    padding-right: 36px;
  }  
  `
      : null};
  .icon {
    fill: ${colors.GREY};
    stroke: none;
  }
`;

export const ErrorMessage = styled.div`
  color: ${colors.RED};
`;
